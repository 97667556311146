import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Polyline, Polygon, Tooltip, ImageOverlay, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faStar } from '@fortawesome/free-solid-svg-icons';
import { renderToStaticMarkup } from 'react-dom/server';

// Definiere das Standard-Icon
const markerIcon = new L.Icon({
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});

// Funktion, um ein benutzerdefiniertes Icon zu erstellen
const createCustomIcon = (icon, color) => {
    const iconMarkup = renderToStaticMarkup(
        <div style={{ color }}>
            <FontAwesomeIcon icon={icon} size="2x" />
        </div>
    );
    return L.divIcon({
        html: iconMarkup,
        iconSize: [25, 41],
        className: 'custom-div-icon',
    });
};

// Definiere ein benutzerdefiniertes Icon für initialPosition
const initialMarkerIcon = createCustomIcon(faStar, 'red');

const MapEvents = ({ onClick }) => {
    useMapEvents({
        click(e) {
            onClick([e.latlng.lat, e.latlng.lng]);
        }
    });
    return null;
};

const getImageDimensions = (imageUrl) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = imageUrl;
        img.onload = () => {
            const dimensions = { width: img.naturalWidth, height: img.naturalHeight };
            console.log('Image dimensions:', dimensions);
            resolve(dimensions);
        };
        img.onerror = (err) => {
            console.error('Error loading image:', err);
            reject(err);
        };
    });
};

const MapPicker = ({ initialPosition, zoomLevel, onPositionSelect, mapImage, geoObjects = [] }) => {
    const [position, setPosition] = useState(() => {
        if (Array.isArray(initialPosition)) {
            return initialPosition;
        } else if (initialPosition && typeof initialPosition === 'object' && 'x' in initialPosition && 'y' in initialPosition) {
            return [initialPosition.x, initialPosition.y];
        } else {
            return [0, 0]; // default position in case of invalid initialPosition
        }
    });

    const mapRef = useRef(null);
    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
    const [bounds, setBounds] = useState(null);

    useEffect(() => {
        if (Array.isArray(initialPosition)) {
            setPosition(initialPosition);
        } else if (initialPosition && typeof initialPosition === 'object' && 'x' in initialPosition && 'y' in initialPosition) {
            setPosition([initialPosition.x, initialPosition.y]);
        }
    }, [initialPosition]);

    useEffect(() => {
        if (mapImage) {
            getImageDimensions(mapImage).then(dimensions => {
                setImageDimensions(dimensions);
                const initialBounds = L.latLngBounds([
                    [0, 0],
                    [dimensions.height, dimensions.width]
                ]);
                setBounds(initialBounds);
                console.log('MapPicker Image Dimensions:', dimensions);
            }).catch(err => {
                console.error('Failed to load image dimensions:', err);
            });
        }
    }, [mapImage]);

    useEffect(() => {
        if (imageDimensions.width && imageDimensions.height && mapRef.current) {
            const map = mapRef.current;
            const fitZoomLevel = () => {
                const container = map._container;
                const { width: containerWidth, height: containerHeight } = container.getBoundingClientRect();
                const widthZoom = containerWidth / imageDimensions.width;
                const heightZoom = containerHeight / imageDimensions.height;
                return Math.min(widthZoom, heightZoom);
            };

            const zoomLevel = fitZoomLevel();
            map.setView([imageDimensions.height / 2, imageDimensions.width / 2], zoomLevel * 0.5);
        }
    }, [imageDimensions]);

    const handleMapClick = (newPosition) => {
        setPosition(newPosition);
        onPositionSelect(newPosition);
    };

    const renderGeoObjects = () => {
        return geoObjects.map((obj, index) => {
            if (obj.type === 'polygon' || obj.type === 'polyline') {
                const positions = obj.coords.map(coord => [coord.x, coord.y]);
                if (obj.type === 'polygon') {
                    return <Polygon key={index} positions={positions} color={obj.color || 'blue'} opacity={0.5} />;
                }
                return <Polyline key={index} positions={positions} color={obj.color || 'blue'} />;
            }
            if (obj.type === 'marker') {
                const position = [obj.coords.x, obj.coords.y];
                const icon = obj.icon ? createCustomIcon(obj.icon, 'blue') : markerIcon;
                const zIndexOffset = obj.icon ? 1000 : 0; // Nur benutzerdefinierte Icons haben zIndexOffset von 1000
                return (
                    <Marker key={index} position={position} icon={icon} zIndexOffset={zIndexOffset}>
                        {obj.name && <Tooltip>{obj.name}</Tooltip>}
                    </Marker>
                );
            }
            return null;
        });
    };

    const updateBounds = (map) => {
        let currentZoom = map.getZoom();
        const container = map._container;
        if (currentZoom < 0.5) {
            currentZoom = 0.5;
        }
        const { width: containerWidth, height: containerHeight } = container.getBoundingClientRect();
        const widthZoom = containerWidth / imageDimensions.width;
        const heightZoom = containerHeight / imageDimensions.height;
        const adjustedX = (containerWidth - imageDimensions.width * currentZoom) / 2;
        const adjustedY = (containerHeight - imageDimensions.height * currentZoom) / 2;
        const newBounds = L.latLngBounds([
            [0 - adjustedY, 0 - adjustedX],
            [imageDimensions.height + adjustedY, imageDimensions.width + adjustedX]
        ]);
        map.setMaxBounds(newBounds);
    };

    const MapBoundsUpdater = () => {
        const map = useMapEvents({
            zoomend: () => {
                updateBounds(map);
            },
            moveend: () => {
                updateBounds(map);
            }
        });
        return null;
    };

    return (
        
            <MapContainer
                center={mapImage ? [imageDimensions.height / 2, imageDimensions.width / 2] : position}
                zoom={zoomLevel}
                //minZoom={0.01}  // Allow more zoom out
                //maxZoom={6}  // Increase maxZoom for more dynamic zooming
                crs={mapImage ? L.CRS.Simple : L.CRS.EPSG3857}
                ref={mapRef}
                style={{ width: "100%", height: "400px" }}
                //maxBounds={bounds}
                //maxBoundsViscosity={0.1}
            >
                {mapImage && <MapBoundsUpdater />}
                {mapImage ? (
                    bounds && (
                        <ImageOverlay
                            url={mapImage}
                            bounds={bounds}
                        />
                    )
                ) : (
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                )}
                {renderGeoObjects()}
                {position && <Marker position={position} icon={initialMarkerIcon} zIndexOffset={1000} />}
                <MapEvents onClick={handleMapClick} />
            </MapContainer>

    );
};

export default MapPicker;
