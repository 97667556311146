import DOMPurify from 'dompurify';

const getTrackMetadataValue = (currentTrack, metas, language, key, altKey, fallback, sanitizeHtml = false) => {
  let value = '';

  if (currentTrack && currentTrack.metadata[key] && currentTrack.metadata[key][language]) {
    value = currentTrack.metadata[key][language];
  } else if (metas && metas[altKey] && metas[altKey][language]) {
    value = metas[altKey][language];
  } else {
    value = fallback;
  }

  if (sanitizeHtml) {
    value = { __html: DOMPurify.sanitize(value) };
  }

  return value;
};

const getStoryMetadataValue = (metas, language, key, fallback, sanitizeHtml = false) => {
  let value = '';

  if (metas && metas[key] && metas[key][language]) {
    value = metas[key][language];
  } else {
    value = fallback;
  }

  if (sanitizeHtml) {
    value = { __html: DOMPurify.sanitize(value) };
  }

  return value;
};

export { getTrackMetadataValue, getStoryMetadataValue };