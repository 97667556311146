import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function Imprint({ show, handleClose }) {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Impressum</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          SBAE GmbH<br />
          Randstr. 1<br />
          22525 Hamburg
        </p>
        <p>info@storydive.de</p>
        <p>
          Amtsgericht Hamburg HRB 171275<br />
          Geschäftsführerin: Sophie Burger
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Schließen
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Imprint;
