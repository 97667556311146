import { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointRight} from '@fortawesome/free-solid-svg-icons';

export const useActivePuzzleNavigation = (
    contentState,
    expandedPuzzle,
    quests,
    setExpandedPuzzle,
    setExpandedQuest
) => {


  const findQuestSlugByPuzzleSlug = (data, puzzleSlug) => {
    for (const quest of data) {
        const foundPuzzle = quest.puzzles?.find(puzzle => puzzle.slug === puzzleSlug);
        if (foundPuzzle) {
            return quest.slug;
        }
    }
    return null;
};


    const getNextActivePuzzle = useMemo(() => {
        // Aktive Puzzles finden, deren Quest auch aktiv ist
        const activePuzzles = contentState
            .filter(item => {
                if (item.type !== 'puzzle' || item.state !== 'active') return false;
                const questSlug = findQuestSlugByPuzzleSlug(quests, item.slug);
                return contentState.find(q => 
                    q.type === 'quest' && 
                    q.slug === questSlug && 
                    q.state === 'active'
                );
            })
            .map(item => item.slug);

        if (activePuzzles.length === 0) return null;

        // Aktuellen Index finden
        const currentIndex = activePuzzles.indexOf(expandedPuzzle || '');
        
        // Nächstes Puzzle bestimmen
        const nextPuzzle = currentIndex === -1 ? 
            activePuzzles[0] : 
            activePuzzles[(currentIndex + 1) % activePuzzles.length];

        return nextPuzzle;
    }, [contentState, expandedPuzzle, quests]);

    const hasNextPuzzle = getNextActivePuzzle !== null && 
        getNextActivePuzzle !== expandedPuzzle;

    const navigateToNextPuzzle = () => {
        if (!getNextActivePuzzle) return;
        
        const questSlug = findQuestSlugByPuzzleSlug(quests, getNextActivePuzzle);
        if (questSlug) {
            setExpandedQuest(questSlug);
            setExpandedPuzzle(getNextActivePuzzle);
        }
    };

    return {
        hasNextPuzzle,
        navigateToNextPuzzle
    };
};

// NavigateButton Komponente
export const FindNextPuzzleButton = ({ 
    contentState,
    expandedPuzzle,
    quests,
    setExpandedPuzzle,
    setExpandedQuest
}) => {
    const { hasNextPuzzle, navigateToNextPuzzle } = useActivePuzzleNavigation(
        contentState,
        expandedPuzzle,
        quests,
        setExpandedPuzzle,
        setExpandedQuest
    );

    if (!hasNextPuzzle) return null;

    return (
        <Button 
            onClick={navigateToNextPuzzle}
            variant="outline-primary"
            className="d-flex align-items-center gap-2 hover-icon-button"
        >
            <FontAwesomeIcon icon={faHandPointRight} className="text-primary fs-4" />
            <span>Next puzzle</span>
        </Button>
    );
};
