export const generateCustomStyleTag = (customStyles) => {
    if (!customStyles) return null;

    const parsedStyles = parseCustomStyles(customStyles);
    if (!parsedStyles) return null;

    const generateRule = (selector, styles) => {
      // Entferne den führenden Punkt für Klassennamen
      const className = selector.startsWith('.') ? selector.substring(1) : selector;
      
      // Erzeuge den CSS-Selektor basierend auf dem Typ
      let cssSelector;
      if (selector === 'body') {
        cssSelector = `body`;
      } else if (selector.startsWith('.')) {
        // Erhöhe die Spezifität für Klassenselektoren
        cssSelector = `html body  .${className}.${className}`;
      } else {
        cssSelector = `html body  ${selector}`;
      }

      // Stelle sicher, dass jede Deklaration !important hat
      const enhancedStyles = styles.split(';')
        .filter(Boolean)
        .map(declaration => {
          const trimmed = declaration.trim();
          return trimmed.endsWith('!important;') ? 
            trimmed : 
            trimmed.endsWith(';') ? 
              trimmed.slice(0, -1) + ' !important;' : 
              trimmed + ' !important;';
        })
        .join(' ');

      return `${cssSelector} { ${enhancedStyles} }`;
    };

    const rules = [];
    
    // Verarbeite body zuerst
    if ('body' in parsedStyles) {
      rules.push(generateRule('body', parsedStyles['body']));
    }

    // Dann alle anderen Styles
    Object.entries(parsedStyles).forEach(([selector, styles]) => {
      if (selector !== 'body') {
        rules.push(generateRule(selector, styles));
      }
    });

    // Füge eine Reset-Regel für Bootstrap-Variablen hinzu
    rules.unshift(`
       {
        --bs-bg-opacity: 1 !important;
      }
    `);

    return (
      <style>
        {rules.join('\n\n')}
      </style>
    );
  };


export const parseCustomStyles = (rawStyles) => {
    if (!rawStyles) return null;

    try {
      // Styles in einzelne Definitionen aufteilen
      const result = {};
      
      // Regex für das Matching von Key-Value Paaren, die auch Punkte und Spaces erlaubt
      const styleRegex = /([^:]+):\s*"([^"]+)"/g;
      let match;

      while ((match = styleRegex.exec(rawStyles)) !== null) {
        let [, selector, styles] = match;
        
        // Bereinige Selector und Styles
        selector = selector.trim().replace(/,$/, '');
        styles = styles.trim();
        
        result[selector] = styles;
      }

      //console.log('Parsed Styles:', result);
      return result;
    } catch (error) {
      console.error('Parsing error:', error);
      return null;
    }
  };