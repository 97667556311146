import React, { createContext, useState } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [expiryTime, setExpiryTime] = useState(null);

    const [coockies, setCoockies] = useState(false);

    const login = (userData, expiryTime) => {
        setUser(userData);
        setExpiryTime(expiryTime);
    };

    const logout = () => {
        setUser(null);  // Benutzer ausloggen
        setExpiryTime(null);

        localStorage.removeItem('token');
        localStorage.removeItem('userId');
    };


    const allowCoockies =() => {
        setCoockies(true);
    }

    return (
        <AuthContext.Provider value={{ user, coockies, expiryTime, login, logout, allowCoockies }}>
            {children}
        </AuthContext.Provider>
    );
};