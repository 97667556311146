import React, { useMemo, useContext, useState, useEffect, useRef } from 'react';
import { BsMusicNoteBeamed } from 'react-icons/bs';
import { WalkDataContext } from './hooks/walkDataContext';
import AudioEffectControls from './audioControllers/tonePlayer/AudioEffectControls';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGaugeHigh, faCircleLeft, faUndo} from '@fortawesome/free-solid-svg-icons';

const AudioLab = ({ handleTrack }) => {
  const { tracks, currentTrack, storyMetas, language, updateAudioEffect, audioEffects, setCurrentTrack, contentState } = useContext(WalkDataContext);
  const [currentTrackSlug, setCurrentTrackSlug] = useState(null);
  const playlistRef = useRef(null);

  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);

  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartY(e.touches[0].pageY - containerRef.current.offsetTop);
    setScrollTop(containerRef.current.scrollTop);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const y = e.touches[0].pageY - containerRef.current.offsetTop;
    const walk = (y - startY) * 2;
    containerRef.current.scrollTop = scrollTop - walk;
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    setCurrentTrackSlug(currentTrack.slug);
  }, [currentTrack]);

  const renderedTracks = useMemo(() => {
    const activeTracks = contentState.filter(item => item.type === 'track' && item.state === 'active').map(item => item.slug);
    
    return tracks
      .filter(track => activeTracks.includes(track.slug) && !track.metadata.trackMeta_radio?.[1])
      .map((track) => {
        const isHighlighted = contentState.some(item => 
          item.type === 'track' && 
          item.slug === track.slug && 
          item.highlight === true
        );
  
        return (
          <button
            key={track.slug}
            onClick={() => handleTrack(track.slug)}
            className={`list-group-item list-group-item-action d-flex align-items-center ${track.slug === currentTrackSlug ? 'active' : ''}`}
          >
            <div className="playlist-thumbnail-container" style={{ position: 'relative' }}>
              <div className="playlist-thumbnail">
                {(track.metadata.trackMeta_cover && track.metadata.trackMeta_cover[language]) ||
                (storyMetas.storyMeta_cover && storyMetas.storyMeta_cover[language]) ? (
                  <img 
                    className="audio-image-small" 
                    src={
                      (track.metadata.trackMeta_cover && track.metadata.trackMeta_cover[language]) || 
                      (storyMetas.storyMeta_cover && storyMetas.storyMeta_cover[language]) || 
                      "/assets/illustrations/placeholder-cover.jpg"
                    } 
                    alt="audio avatar" 
                    onError={(e) => {
                      e.target.onerror = null; 
                      e.target.src = "/assets/illustrations/placeholder-cover.jpg";
                    }}
                  />
                ) : (
                  <img 
                    className="audio-image-small" 
                    src="/assets/illustrations/placeholder-cover.jpg" 
                    alt="Cover" 
                  />
                )}
              </div>
              {isHighlighted && (
                <div 
                  style={{
                    position: 'absolute',
                    top: '0px',
                    right: '0px',
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: 'red',
                    zIndex: 1
                  }}
                />
              )}
            </div>
            <div className="flex-grow-1">
              {track.metadata.trackMeta_name ? track.metadata.trackMeta_name[language] : 'Unbenannter Track'}
            </div>
          </button>
        );
      });
  }, [tracks, currentTrackSlug, language, contentState, storyMetas, handleTrack]);

  //<AudioEffectControls effectName="reverbWet" label="reverb wet" type="slider" min={0} max={1} step={0.01} />
  //<AudioEffectControls effectName="reverbDecay" label="reverb decay" type="slider" min={0.001} max={10} step={0.01} />
  //<AudioEffectControls effectName="isPingPongDelayEnabled" label="Ping Pong Delay" type="toggle" />
  const resetAudioEffects = () =>{
    updateAudioEffect('playbackRate', 1);
    updateAudioEffect('isReversed', false);
  }

  return (
    <div
      ref={containerRef}
      className="content-with-fixed-controls"
      style={{
        height: '100vh',
        overflow: 'auto',
        touchAction: 'none'
      }}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className='title-page'><h2>Audio Player</h2></div>
      <div className="playlist-wrapper list-group">
        {renderedTracks}
      </div>
      
  <div className="audio-controls-fixed d-flex align-items-center gap-3 navbar-light bg-light">
  <Button 
    className="mb-0 d-flex align-items-center justify-content-center ha-250" 
    disabled={audioEffects.playbackRate === 1 && !audioEffects.isReversed} 
    onClick={() => resetAudioEffects()}
  >
    <FontAwesomeIcon icon={faUndo} className="fs-4" />
  </Button>
  
  <AudioEffectControls 
    className="mb-0 ha-250" 
    type="button" 
    effectName="isReversed" 
    activeIcon={faCircleLeft}
    inactiveIcon={faCircleLeft}
  />

  <div className="d-flex align-items-center gap-2 flex-grow-1">
    <FontAwesomeIcon icon={faGaugeHigh} className="text-primary fs-4 flip-vertical" />
    <div className="flex-grow-1">
      <AudioEffectControls 
        effectName="playbackRate" 
        label="" 
        type="slider" 
        min={0.1} 
        max={5} 
        step={0.01} 
      />
    </div>
    <FontAwesomeIcon icon={faGaugeHigh} className="text-primary fs-4" />
  </div>
</div>
    </div>
  );
};

export default AudioLab;