import React, { useState, useRef, useEffect, useMemo } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet';
import L from 'leaflet';
import { faMapMarkerAlt, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { renderToStaticMarkup } from 'react-dom/server';
import { Container, Button, Row, Col } from 'react-bootstrap';
import OutdoorMap from './OutdoorMap';
import IndoorMap from './IndoorMap';

const MapPopup = ({ tracks = [], handleTrack, currentTrackSlug, paths, language, metas }) => {
  const mapImageUrl = metas.storyMeta_mapimage ? metas.storyMeta_mapimage[1] : null; 

  if (mapImageUrl){
    return(
      <IndoorMap
      tracks={tracks}
      handleTrack={handleTrack}
      currentTrackSlug={currentTrackSlug}
      paths={paths}
      language={language}
      metas={metas}
      imageUrl={mapImageUrl}/>
    );
  } else {
    return(
      <OutdoorMap
        tracks={tracks}
        handleTrack={handleTrack}
        currentTrackSlug={currentTrackSlug}
        paths={paths}
        language={language}
        metas={metas}
      />
    );
  }
}
export default MapPopup;
