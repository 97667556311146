import React, { createContext, useState, useContext } from 'react';

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);

    const generateUniqueId = () => {
        return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
    };

    const showNotification = (msg, type = 'info') => {
        const newNotification = { id: generateUniqueId(), msg, type, fadeOut: false };
        setNotifications(prev => [...prev, newNotification]);

        // Starten Sie das Ausblenden nach einer festgelegten Zeit
        setTimeout(() => {
            setNotifications(prev =>
                prev.map(notification => 
                    notification.id === newNotification.id ? { ...notification, fadeOut: true } : notification
                )
            );
        }, 2500); // Beginnen Sie mit dem Ausblenden vor dem Entfernen

        // Entfernen Sie die Benachrichtigung nach dem Ausblenden
        setTimeout(() => {
            setNotifications(prev => prev.filter(notification => notification.id !== newNotification.id));
        }, 3000);
    };

    return (
        <NotificationContext.Provider value={{ showNotification }}>
            {children}
            <div className="notification-container">
                {notifications.map(notification => (
                    <div 
                        key={notification.id} 
                        className={`alert alert-${notification.type} rounded shadow-sm ${notification.fadeOut ? 'fade-out' : ''}`} 
                        role="alert"
                    >
                        {notification.msg}
                    </div>
                ))}
            </div>
        </NotificationContext.Provider>
    );
};
