import { handleNewEntry, handleDeleteEntry } from '../../../utility/dataHandling';

export const getEntityNames = (storyEntities, entities, entityIdKey) => {
    const entityIds = storyEntities.map(se => se[entityIdKey]);
    return entities.filter(entity => entityIds.includes(entity.id)).map(entity => ({
        id: entity.id,
        name: entity.name
    }));
};

export const getUnassignedEntities = (storyEntities, entities, entityIdKey) => {
    const assignedEntityIds = storyEntities.map(se => se[entityIdKey]);
    return entities.filter(entity => !assignedEntityIds.includes(entity.id));
};

export const handleAddEntity = async (event, entityName, storyEntities, setStoryEntities, notificationMessage, entityIdKey, storyId, showNotification) => {
    const entityId = event.target.value;
    if (!entityId) return;

    try {
        const newEntry = await handleNewEntry(`story_${entityName}`, () => {}, () => {}, {
            'story_id': storyId,
            [entityIdKey]: entityId
        });

        if (newEntry?.id) {
            setStoryEntities([...storyEntities, { id: newEntry.id, story_id: storyId, [entityIdKey]: parseInt(entityId) }]);
            showNotification(`${notificationMessage} erfolgreich hinzugefügt`, 'success');
        }
    } catch (error) {
        console.error(`Fehler beim Hinzufügen der ${notificationMessage}:`, error);
        showNotification(`Fehler beim Hinzufügen der ${notificationMessage}`, 'danger');
    }
};

export const handleRemoveEntity = async (entityId, storyEntities, setStoryEntities, entityName, notificationMessage, entityIdKey, storyId, showNotification) => {
    try {
        const entry = storyEntities.find(se => se[entityIdKey] === entityId && parseInt(se.story_id) === parseInt(storyId));
        if (entry) {
            await handleDeleteEntry(`story_${entityName}`, entry.id, () => {}, showNotification);
            setStoryEntities(storyEntities.filter(se => se[entityIdKey] !== entityId));
            showNotification(`${notificationMessage} erfolgreich entfernt`, 'success');
        }
    } catch (error) {
        console.error(`Fehler beim Entfernen der ${notificationMessage}:`, error);
        showNotification(`Fehler beim Entfernen der ${notificationMessage}`, 'danger');
    }
};


export function transformData(languages,categoryNames, metaCategories, questsData, questContentData) {
    const languageCodeMap = languages.reduce((acc, lang) => {
        acc[lang.id] = lang.language_code;
        return acc;
    }, {});


    const metaNameMap = categoryNames.reduce((acc, name) => {
        if (!acc[name.content_key]) {
            acc[name.content_key] = {};
        }
        acc[name.content_key][languageCodeMap[name.language_id]] = name.content;
        return acc;
    }, {});

    const categoryTypeMap = metaCategories.reduce((acc, category) => {
        acc[category.id] = { type: category.type, has_translations: category.has_translations };
        return acc;
    }, {});

    const result = [];
    let newId = 1;


    const questContentMap = questContentData.filter(sc => sc.quest_id === questsData.id).reduce((acc, sc) => {
        if (!acc[sc.meta_category_id]) {
            acc[sc.meta_category_id] = {};
        }
        acc[sc.meta_category_id][languageCodeMap[sc.language_id] || 'null'] = { content: sc.content, id: sc.content_id, content_id: sc.id, key:sc.key};
        return acc;
    }, {});


    metaCategories.forEach(metaCategory => {
        const newEntry = {
            id: newId++,
            questId: questsData.id,
            meta_category_id: metaCategory.id,
            name: metaNameMap[metaCategory.title_key]?.['de_DE'] || null,
            type: categoryTypeMap[metaCategory.id].type,
            has_translations: categoryTypeMap[metaCategory.id].has_translations,
        };

        languages.forEach(lang => {
            const langCode = lang.language_code;
            if (langCode !== 'null') {
                newEntry[`${langCode}_name`] = questContentMap[metaCategory.id]?.[langCode]?.content;
                newEntry[`${langCode}_id`] = questContentMap[metaCategory.id]?.[langCode]?.id || null;
                newEntry[`${langCode}_content_id`] = questContentMap[metaCategory.id]?.[langCode]?.content_id || null;
                newEntry[`${langCode}_key`] = questContentMap[metaCategory.id]?.[langCode]?.key || null;
            }
        });

        newEntry['null_name'] = questContentMap[metaCategory.id]?.['null']?.content || null;
        newEntry['null_id'] = questContentMap[metaCategory.id]?.['null']?.id || null;
        newEntry['null_content_id'] = questContentMap[metaCategory.id]?.['null']?.content_id || null;
        newEntry['null_content_key'] = questContentMap[metaCategory.id]?.['null']?.key || null;

        result.push(newEntry);
    });

    return result;
}

export const getLanguageIdByCode = (languageCode, languages) => {

    const language = languages.find(lang => lang.language_code === languageCode);
    return language ? language.id : null;
};