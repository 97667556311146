import React, { useState, useCallback, useEffect } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { motion, AnimatePresence } from 'framer-motion';

function PuzzleInputText({ solution, hint1, checkSolution }) {
  const [inputValue, setInputValue] = useState('');
  const [feedback, setFeedback] = useState(null);
  const [showHint, setShowHint] = useState(false);
  const [showHintButton, setShowHintButton] = useState(false);

  // Feedback Timer
  useEffect(() => {
    let timer;
    if (feedback?.type === 'danger') {
      timer = setTimeout(() => {
        setFeedback(null);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [feedback]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    const matchedSolutionIndex = solution.findIndex(
      solutionGroup => solutionGroup.some(
        sol => sol.trim().toLowerCase() === inputValue.trim().toLowerCase()
      )
    );

    if (matchedSolutionIndex !== -1) {
      setFeedback({ 
        type: 'success', 
        message: 'Correct answer!',
        icon: '🎉'
      });
      setShowHintButton(false);
      checkSolution(matchedSolutionIndex);
    } else {
      setFeedback({ 
        type: 'danger', 
        message: 'Wrong answer, try again!',
        icon: '❌'
      });
      // Zeige den Hint-Button nur, wenn der Hint noch nicht angezeigt wird
      if (!showHint) {
        setShowHintButton(true);
      }
    }
  }, [inputValue, solution, checkSolution, showHint]);

  const handleHintClick = () => {
    setShowHint(true);
    setShowHintButton(false);
  };

  const isInputEmpty = inputValue.trim() === '';



  const inputStyle = {
    marginTop:'1.5rem',
    height: '50px',
    borderRadius: '10px',
    border: '2px solid #dee2e6',
    transition: 'all 0.3s ease',
    fontSize: '1.1rem'
  };

  const buttonStyle = {
    borderRadius: '10px',
    transition: 'all 0.3s ease',
    transform: isInputEmpty ? 'scale(0.98)' : 'scale(1)',
    padding: '0.5rem 1.5rem'
  };

  const hintButtonStyle = {
    borderRadius: '10px',
    transition: 'all 0.3s ease',
    position: 'relative',
    overflow: 'hidden',
    transform: 'translateY(0)',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    ':hover': {
      transform: 'translateY(-2px)',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
    }
  };

  const alertStyle = {
    borderRadius: '10px',
    border: 'none',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
  };

  return (
    <div  className="mt-2">
      <Form onSubmit={handleSubmit} style={{ minHeight: '150px' }}>
        <Form.Group controlId="textPuzzleInput">
          <Form.Control
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Enter your answer here..."
            className="mb-3 form-control-lg"
            style={inputStyle}
          />
        </Form.Group>

        <div className="d-flex gap-3 mt-4">
          <Button 
            variant="primary" 
            type="submit" 
            className="flex-grow-1 py-2"
            disabled={isInputEmpty}
            style={buttonStyle}
          >
            send answer
          </Button>

          <AnimatePresence>
            {hint1 && showHintButton && (
              <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.2 }}
              >
                <Button
                  variant="outline-info"
                  onClick={handleHintClick}
                  className="hint-button"
                  style={hintButtonStyle}
                >
                  <i className="fas fa-lightbulb me-2"></i>
                  show hint 
                </Button>
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        {/* Getrennte AnimatePresence für Feedback und Hint */}
        <AnimatePresence key="feedback">
          {feedback && (
            <motion.div
              key="feedback-alert"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <Alert 
                variant={feedback.type} 
                className="mt-4 d-flex align-items-center gap-2"
                style={alertStyle}
              >
                <span className="feedback-icon fs-5">{feedback.icon}</span>
                {feedback.message}
              </Alert>
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence key="hint">
          {showHint && hint1 && (
            <motion.div
              key="hint-alert"
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Alert 
                variant="info" 
                className="mt-4"
                style={alertStyle}
              >
                <i className="fas fa-lightbulb me-2"></i>
                {hint1}
              </Alert>
            </motion.div>
          )}
        </AnimatePresence>
      </Form>
    </div>
);
}

export default PuzzleInputText;