import React, { useState, useEffect, useRef } from 'react';

import { Link } from 'react-router-dom';
import axios from 'axios';
import formatDate from '../../../utility/dateUtils';
import SortIndicator from '../../../utility/SortIndicator';



const UsersTab = () => {
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [sortConfig, setSortConfig] = useState({ key: 'last_visit', direction: 'desc' });
    const { key: sortKey, direction: sortDirection } = sortConfig;
    const token = localStorage.getItem('token');
   



    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };
    
    
    const sortedUsers = [...users].sort((a, b) => {
        // Erkennen, ob der Schlüssel für eine numerische oder Datumssortierung steht
        const isNumericSort = ['visits', 'role', 'id'].includes(sortConfig.key);
        const isDateSort = ['created_at', 'last_visit'].includes(sortConfig.key);
    
        if (isDateSort) { // Datumssortierung
            const dateA = new Date(a[sortConfig.key] ?? "1970-01-01");
            const dateB = new Date(b[sortConfig.key] ?? "1970-01-01");
            return sortConfig.direction === 'asc' ? dateA - dateB : dateB - dateA;
        } else if (isNumericSort) { // Numerische Sortierung
            const numA = parseFloat(a[sortConfig.key] ?? "0");
            const numB = parseFloat(b[sortConfig.key] ?? "0");
            return sortConfig.direction === 'asc' ? numA - numB : numB - numA;
        } else { // Alphabetische Sortierung für andere Felder
            const valueA = a[sortConfig.key] ?? "0";
            const valueB = b[sortConfig.key] ?? "0";
            return sortConfig.direction === 'asc' ? 
                valueA.localeCompare(valueB) : 
                valueB.localeCompare(valueA);
        }
    });



    const deleteUser = async (id) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/userManager/${id}`, {
                headers: {
                    'authorization': `Bearer ${token}`,
                },
            });
            // Überprüfen Sie den Statuscode und handeln Sie entsprechend
            if (response.status === 200) {
                fetchUsers();
            } else {
                console.error('Beim Löschen des Users');
            }
        } catch (error) {
            console.error('Fehler beim Senden der Anfrage', error);
        }
    };

    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/userManager/`,{
                headers: {
                    'authorization': `Bearer ${token}`,
                }
            });
            setUsers(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error('Fehler beim Laden der User:', error);
            setIsLoading(false);
        }
    };
    useEffect(() => {
        fetchUsers();
    }, []);


    if (isLoading) {
        return <div>Laden...</div>;
    }
    
    return (
        <div>
            <h2>User</h2>
            <table className="table">
                <thead>
                    <tr>
                        <th onClick={() => requestSort('username')}>
                            Name
                            <SortIndicator isSorted={sortKey === 'username'} direction={sortDirection} />
                        </th>
                        <th onClick={() => requestSort('role')}>
                            Rolle
                            <SortIndicator isSorted={sortKey === 'role'} direction={sortDirection} />
                        </th>
                        <th onClick={() => requestSort('user_uuid')}>
                            UUID
                            <SortIndicator isSorted={sortKey === 'user_uuid'} direction={sortDirection} />
                        </th>
                        <th onClick={() => requestSort('id')}>
                            ID
                            <SortIndicator isSorted={sortKey === 'id'} direction={sortDirection} />
                        </th>
                        <th onClick={() => requestSort('visits')}>
                            Besuche
                            <SortIndicator isSorted={sortKey === 'visits'} direction={sortDirection} />
                        </th>
                        <th onClick={() => requestSort('last_visit')}>
                            letzter Besuch
                            <SortIndicator isSorted={sortKey === 'last_visit'} direction={sortDirection} />
                        </th>
                        <th onClick={() => requestSort('created_at')}>
                            Erstellt am
                            <SortIndicator isSorted={sortKey === 'created_at'} direction={sortDirection} />
                        </th>
                        <th>bearbeiten</th>
                        <th>löschen</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedUsers.map(user => (
                        <tr key={user.id}>
                            <td>{user.username ? user.username.trim().replace(/(\r\n|\n|\r)/gm, "") : ' -- '}</td>
                            <td>{user.role}</td>
                            <td>{user.user_uuid ? user.user_uuid.trim().replace(/(\r\n|\n|\r)/gm, "") : ' -- '}</td>
                            <td>{user.id ? user.id : ' -- '}</td>
                            <td>{user.visits }</td>
                            
                            <td>{formatDate(user.last_visit.trim())}</td>
                            <td>{formatDate(user.created_at.trim())}</td>
                            <td><Link to={`./${user.id}`}>Details</Link></td>
                            <td><button onClick={() => deleteUser(user.id)}>-</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>

   

    </div>

    );


};

export default UsersTab;