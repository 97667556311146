import React, { useState, useEffect, useCallback } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';

const PuzzleInputMultipleChoice = ({ content, solution, hint1, checkSolution }) => {
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [feedback, setFeedback] = useState(null);

  useEffect(() => {
    try {
      const parsedContent = JSON.parse(content.replace(/'/g, '"'));
      setOptions(parsedContent);
    } catch (error) {
      console.error("Failed to parse content:", error);
    }
  }, [content]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    const matchedSolutionIndex = solution.findIndex(
      solutionGroup => solutionGroup.some(
        sol => sol.trim().toLowerCase() === selectedValue?.trim().toLowerCase()
      )
    );

    if (matchedSolutionIndex !== -1) {
      setFeedback({ type: 'success', message: 'Correct answer!' });
      checkSolution(matchedSolutionIndex);
    } else {
      setFeedback({ type: 'danger', message: hint1 || 'Wrong answer, try again!' });
    }
  }, [selectedValue, solution, hint1, checkSolution]);

  return (
    <Form onSubmit={handleSubmit}>
      {options.map(({ item, value }, index) => (
        <Form.Check
          key={index}
          type="radio"
          id={`multiple-choice-${index}`}
          name="multipleChoice"
          label={item}
          value={value}
          onChange={() => setSelectedValue(value)}
          checked={selectedValue === value}
          className="mb-2"
        />
      ))}

      <Button 
        variant="primary" 
        type="submit" 
        className="mt-3" 
        disabled={!selectedValue}
      >
        Send Answer
      </Button>

      {feedback && (
        <Alert variant={feedback.type} className="mt-3">
          {feedback.message}
        </Alert>
      )}
    </Form>
  );
};

export default PuzzleInputMultipleChoice;