import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { WalkDataContext } from '../../hooks/walkDataContext';
import { createReverb, createPingPongDelay, connectEffects } from './Effects';
import { getTrackMetadataValue } from '../../utility/MetadataFetcher';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Container, Button } from 'react-bootstrap';

const TonePlayer = () => {
  const [isPaused, setIsPaused] = useState(true);
  const [hasEnded, setHasEnded] = useState(true);
  const [player, setPlayer] = useState(null);
  const [Tone, setTone] = useState(null);
  const [currentAudioUrl, setCurrentAudioUrl] = useState(null);
  const [reverb, setReverb] = useState(null);
  const [pingPongDelay, setPingPongDelay] = useState(null);
  const [trackTitle, setTrackTitle] = useState(null);
  const [trackCover, setTrackCover] = useState(null);
  const [trackAction, setTrackAction] = useState(null);
  const pendingActionsRef = useRef([]);

  const [isIOSInitialized, setIsIOSInitialized] = useState(false);
  const iOsAudioRef = useRef(null);

  const { currentTrack, storyMetas, language, updateAudioEffect, audioEffects, contentState, setElementState } = useContext(WalkDataContext);

  const [isBufferLoading, setIsBufferLoading] = useState(false);

  useEffect(() => {
    const updateTrackInfo = () => {
      setCurrentAudioUrl(getTrackMetadataValue(currentTrack, storyMetas, language, 'trackMeta_audiotrack'));
      setTrackTitle(getTrackMetadataValue(currentTrack, storyMetas, language, 'trackMeta_name', 'storyMeta_name'));
      setTrackCover(getTrackMetadataValue(currentTrack, storyMetas, language, 'trackMeta_cover', 'storyMeta_cover', '/assets/illustrations/placeholder-cover.jpg'));
      const trackActionString = getTrackMetadataValue(currentTrack, storyMetas, 1, 'trackMeta_activatescontent', null);
      setTrackAction(parseActivatesContent(trackActionString ?? '[]'));
    };
    updateTrackInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTrack, storyMetas, language]);


  const convertToValidJson = (str) => {
    // Ersetze einfache Anführungszeichen durch doppelte
    return str.replace(/'/g, '"');
  };

  const parseActivatesContent = (contentString) => {
    try {
      const validJsonString = convertToValidJson(contentString);
      return JSON.parse(validJsonString);
    } catch (error) {
      console.error("Error parsing activates content:", error);
      return [];
    }
  };


  const initializeTone = useCallback(async () => {
    if (!Tone) {
      if (!isIOSInitialized) {
        // Spiele die leere Audio-Datei ab
        if (iOsAudioRef.current) {
          try {
            await iOsAudioRef.current.play();
          } catch (error) {
            console.error("Fehler beim Abspielen der leeren Audio-Datei:", error);
          }
        }
        setIsIOSInitialized(true);
      }
      const ToneModule = await import('tone');
      await ToneModule.start();
      setTone(ToneModule);
      return ToneModule;
    }
    return Tone;
  }, [Tone, isIOSInitialized]);

  const initializePlayer = useCallback(async (url) => {
    let ToneInstance;
    if (!Tone){
      ToneInstance = await initializeTone();
    }
  
    if (player) {
      player.dispose();
      reverb.dispose();
      pingPongDelay.dispose();
    }
  
    const newReverb = createReverb(ToneInstance.Reverb, audioEffects.reverbDecay, audioEffects.reverbWet, 0.01);  //(decay, wet, preDelay)
    const newPingPongDelay = createPingPongDelay(ToneInstance.PingPongDelay);
  
    // Erstelle den neuen Player
    const newPlayer = new ToneInstance.Player({
      url,
      onload: () => {
        newPlayer.playbackRate = audioEffects.playbackRate;
        setIsPaused(false);
        ToneInstance.Transport.start();
        newPlayer.sync().start();
      },
    });
  
    newPlayer.onstop = () => {
      handleTrackEnd();
    };
  
    connectEffects(newPlayer, newPingPongDelay, newReverb, false);
  
    setReverb(newReverb);
    setPingPongDelay(newPingPongDelay);
    setPlayer(newPlayer);
    handlePlay();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initializeTone, player]);
  
  
  const handlePlay = useCallback(() => {
    if (player && Tone) {
      // diese Zeile führt oft zu einem Bug der zur Folge hat dass der Player zwei mal hinntereinander gestartet wird... (https://github.com/Tonejs/Tone.js/issues/1175)
      // manchmal habe ich einen Transportfehler bekommen dass die Position nicht negativ sein darf...
      // erste tests: das funktioniert so....tbc
      //Tone.Transport.position = 0.1;  // nicht ganz 0 wegen rundungsfehlern (darf nie negativ sein)
      //
      player.sync().start();
      // reset Effect to default
      updateAudioEffect('playbackRate', 1);
      updateAudioEffect('reverbWet', 0);
      updateAudioEffect('reverbDecay', 0.1);
      updateAudioEffect('isReversed', false);
      updateAudioEffect('isPingPongDelayEnabled', false);

      Tone.Transport.start();

      setIsPaused(false);
      setHasEnded(true);
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player, Tone]);

  const togglePause = useCallback(() => {

    if (!player) {
      initializePlayer();
      return;
    } 


    if (player && Tone) {
      setIsPaused(prevIsPaused => {
        const newIsPaused = !prevIsPaused;
        if (newIsPaused) {
          Tone.Transport.pause();
        } else {
          Tone.Transport.start();
        }
        return newIsPaused;
      });
    }
  }, [player, Tone, initializePlayer]);

// handleTrackEnd ist leider etwas kompliziert. Das Problem ist dass die States hasEnded und isPaused zum exakten Zeitpunkt von player.onstop evaluiert werden müssen um eine Pause von einem Track ended zu unterscheiden.
// da React keine Komponenten updated während States geänder werden Muss ein Umweg via setTimeout genommen werden

  const handleTrackEnd = useCallback(() => {
    setIsPaused((currentIsPaused) => {
      setHasEnded((currentHasEnded) => {
        if (currentHasEnded && !currentIsPaused) {
          Tone.Transport.stop();
          Tone.Transport.position = 0.1;  // ebenfalls (wie oben) unter Beobachtung
          currentIsPaused = true;
          pendingActionsRef.current = trackAction || [];
          //console.log(Date.now() + ': Track ended.' );
        }
        return currentHasEnded;
      });
      
      // Hier wird das Callback nur ausgeführt, wenn die State-Änderungen abgeschlossen sind
      setTimeout(() => {
        if (hasEnded && pendingActionsRef.current.length > 0) {
          activateContent();
        }
      }, 10);
      return currentIsPaused;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Tone, pendingActionsRef]);
  
  const activateContent = () => {
    pendingActionsRef.current = trackAction || [];
    //setIsPaused(false);
    
      // Führe die markierten Aktionen aus
      pendingActionsRef.current.forEach(item => {
        const contentStateItem = contentState.find(
          stateItem => stateItem.type === item.type && stateItem.slug === item.slug
        );
        // Bestimme den aktuellen Zustand und Highlight-Status
        const currentState = contentStateItem ? contentStateItem.state : 'inactive';
        const currentHighlight = contentStateItem ? contentStateItem.highlight : false;
        const newHighlightState = currentState === 'inactive' ? true : currentHighlight;
        if (currentState!=='finished'){       //damit erneut abgespielte Audiotracks bereits beendete Puzzle nicht reaktivieren
          setElementState(item.type, item.slug, 'active', newHighlightState);
        }
        //setElementState(item.type, item.slug, 'active', item.state==='inactive' ? true: item.highlight);
      });
      // Leere die pending actions
      pendingActionsRef.current = [];
    
  };


// im folgenden wird derBuffer des Players ausgetauscht - manchmal funktioniert das laden nicht - mit wacklicger Internetverbindung testen!
const stopAndUnloadCurrentAudio = useCallback(() => {
  return new Promise((resolve) => {
    if (player) {
      setHasEnded(false);
      player.stop();
      player.unsync(); // Sicherstellen, dass der Player unsynced ist
      if (player.buffer) {
        player.buffer.dispose();
        player.buffer = null;
      }
      // Kurze Verzögerung, um sicherzustellen, dass alle Operationen abgeschlossen sind
      setTimeout(resolve, 500); // 500ms sind eigentlich gut viel - evtl weniger
    } else {
      resolve();
    }
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [player, handleTrackEnd]);

const loadAndPlayAudio = useCallback(async () => {
  if (!player || !currentAudioUrl) return;

  setIsBufferLoading(true);
  try {
    await stopAndUnloadCurrentAudio(); // Sicherstellen, dass der alte Track gestoppt und entladen wird

    const newBuffer = await Tone.Buffer.load(currentAudioUrl);
    player.buffer = newBuffer;

    await Tone.loaded(); // Sicherstellen, dass alles geladen ist
    player.onstop = () => {
      handleTrackEnd(() => true);
    };
    //player.sync().start(); // Den neuen Track synchronisiert starten - nun in handlePlay
    handlePlay();
  } catch (error) {
    console.error("Fehler beim Laden oder Abspielen des Audios:", error);
  } finally {
    setIsBufferLoading(false);
    setElementState('track', currentTrack.slug, 'active', false);
    
    // Promise-basierte Verzögerung nach den synchronen State-Updates
    await new Promise(resolve => setTimeout(resolve, 3000));
    activateContent();
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [player, currentAudioUrl]);

useEffect(() => {
  loadAndPlayAudio();
  return () => {
    stopAndUnloadCurrentAudio();
  };
}, [currentAudioUrl, loadAndPlayAudio, stopAndUnloadCurrentAudio]);



  useEffect(() => {
      if (player){
        player.playbackRate = audioEffects.playbackRate;
        player.reverse = audioEffects.isReversed;
      }
      if (reverb){
        reverb.wet.value = audioEffects.reverbWet;
        reverb.decay = audioEffects.reverbDecay;
      }
      if (player && pingPongDelay && reverb) {
        connectEffects(player, pingPongDelay, reverb, audioEffects.isPingPongDelayEnabled);
      }
      //reverbRef.current.decay = audioEffects.reverbDecay;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioEffects, player]);


  const truncateTitle = (title, maxLength) =>
    title && title.length > maxLength ? `${title.substring(0, maxLength)}...` : title;

  return (
    <div>
      <audio ref={iOsAudioRef} style={{ display: 'none' }}>
        <source src="/assets/audio/empty.mp3" type="audio/mp3" />
      </audio>
      <Container className="ml-150">
      <div className="audio-player-container-small">
        <div className="player-header">
          <div className="track-thumbnail">
            <img className="audio-image-small" src={trackCover} alt="Cover" />
          </div>
          <div className="controls w-3">
          <Button
            onClick={togglePause}
            disabled={isBufferLoading}
            variant="link"
            className="p-0"
        >
          {isBufferLoading ? (
            <FontAwesomeIcon icon={faSpinner} size="2x" spin />
          ) : isPaused ? (
            <FontAwesomeIcon icon={faPlay} size="2x" />
          ) : (
            <FontAwesomeIcon icon={faPause} size="2x" />
          )}
        </Button>
          </div>
          <div className="track-info">
            <div className="track-title-small">{truncateTitle(trackTitle, 30)}</div>
          </div>
        </div>
      </div>
      </Container>
    </div>
  );
};

export default TonePlayer;
