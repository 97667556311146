import { format } from 'date-fns';

const formatDate = (dateString, display='date') => {
    const date = new Date(dateString);
    if (display === 'date'){
        return format(date, 'dd.MM.yyyy');
    } else if (display === 'date-time'){
        return format(date, 'dd.MM.yyyy HH:mm');
    }
};


export default formatDate;