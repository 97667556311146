import React, { useEffect, useState, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DraggableCard from '../../../utility/DraggableCard';
import update from 'immutability-helper';
import { handleNewEntry, updateElementPositionsInDB, handleDeleteEntry } from '../../../utility/dataHandling';
import { createUniqueSlug } from '../../../utility/Slug';
import { getLanguageIdByCode } from './StoryDetailHelpers';



const StoryQuests = ({ questOrder, setQuestOrder, quests, setQuests, questContents, setQuestContents, languages, storyId, showNotification,handleSaveChanges,selectedLanguage}) => {
    const [isAddingQuest, setIsAddingQuest] = useState(false);
    const [newQuestName, setNewQuestName] = useState('');


    useEffect(() => {
        if (quests && quests.length > 0) {
            const initialOrder = quests
                .sort((a, b) => a.position - b.position)
                .map((quest, index) => {
                    const questData = questContents.filter(tc => tc.quest_id === quest.quest_id);
                    //console.log(questContents);
                    const name = questData.reduce((acc, tc) => {
                        const langCode = languages.find(lang => lang.id === tc.language_id)?.language_code;
                        if (langCode) {
                            if (tc.meta_category_id === 1) {
                                acc[`${langCode}_name`] = tc.content;
                                acc[`${langCode}_name_id`] = tc.content_id;
                            }
                        }
                        return acc;
                    }, {});
                    return {
                        ...quest,
                        ...name,
                        position: index + 1 // Reassign positions here
                    };
                });
            setQuestOrder(initialOrder);
            setQuests(quests.map((quest, index) => ({
                ...quest,
                position: index + 1
            })));
        } else {
            setQuestOrder([]); // Ensures that the quest order is reset if there are no quests
        }
    }, [questContents, languages]);

    const moveQuest = useCallback((dragIndex, hoverIndex, finalize = false) => {
        const draggedQuest = questOrder[dragIndex];
        const newQuestOrder = update(questOrder, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, draggedQuest],
            ],
        });
        const updatedQuestOrder = newQuestOrder.map((quest, index) => ({
            ...quest,
            position: index + 1,
        }));
    
        setQuestOrder(updatedQuestOrder);
        setQuests(updatedQuestOrder.map(quest => ({
            id: quest.id,
            story_id: quest.story_id,
            quest_id: quest.quest_id,
            position: quest.position
        })));
    
        if (finalize) {
            handleSavePositionsToDb(updatedQuestOrder);
        }
    }, [questOrder]);

    const handleSavePositionsToDb = async (quests) => {
        await updateElementPositionsInDB(quests, 'quests');
    };

    const handleAddNewEntry = () => {
        setIsAddingQuest(true);
    };
    
    const handleCancelAdd = () => {
        setIsAddingQuest(false);
        setNewQuestName('');
    };

    const handleSaveNewQuest = async () => {
        const existingSlugs = quests.map(data => data.slug);
        const slug = createUniqueSlug(newQuestName, existingSlugs);

        const languageId = languages.find(lang => lang.language_code === 'de_DE')?.id;

        if (languageId) {
            const newQuest = await handleNewEntry('quests', () => {}, showNotification, {
                'state': 'published',
            });

            const newQuestInStory = await handleNewEntry('story_quests', () => {}, showNotification, {
                'story_id': storyId,
                'quest_id': newQuest.id,
                'position': quests.length + 1,
                'slug': slug
            });

            const newQuestTitleContent = await handleNewEntry('text_contents', () => {}, showNotification, {
                'content': newQuestName,
            });

            const newQuestMetaContent = await handleNewEntry('quest_contents', () => {}, showNotification, {
                'quest_id': newQuest.id,
                'meta_category_id': 1,
                'content_id': newQuestTitleContent.id,
                'language_id': languageId
            });

            setQuests(questsTracks => {
                const updatedQuests = [
                    ...questsTracks,
                    { id: newQuestInStory.id, story_id: parseInt(storyId), quest_id: newQuest.id, position: questsTracks.length + 1 }
                ];
                return updatedQuests.map((quest, index) => ({
                    ...quest,
                    position: index + 1
                }));
            });

            setQuestContents(prevquestContents => [
                ...prevquestContents,
                { id: newQuestMetaContent.id, quest_id: newQuest.id, meta_category_id: 1, content_id: newQuestTitleContent.id, language_id: languageId, content: newQuestName }
            ]);
        } else {
            showNotification('Sprache de_DE existiert nicht.', 'danger');
        }

        setIsAddingQuest(false);
        setNewQuestName('');
    };

    const handleDeleteQuest = async (storyQuestId, questId) => {
        console.log(storyQuestId);
        const deleteQuest = await handleDeleteEntry('story_quests', storyQuestId, () => {}, showNotification);
        if (deleteQuest) {
            setQuests(questsTracks => {
                const updatedQuests = questsTracks.filter(quest => parseInt(quest.id) !== parseInt(storyQuestId));
                return updatedQuests.map((quest, index) => ({
                    ...quest,
                    position: index + 1
                }));
            });
            setQuestContents(prevquestContents => prevquestContents.filter(quest => parseInt(quest.quest_id) !== parseInt(questId)));
        }
    };

    // Funktion zur Aktualisierung eines bestehenden Quest-Inhalts
const updateExistingQuestContent = (questId, newValue) => {
    const updatedquestContents = questContents.map((content) => {
        if (content.quest_id === questId) { 
            return {
                ...content,
                content: newValue,
            };
        }
        return content;
    });
    setQuestContents(updatedquestContents);
    handleSaveChanges(
        newValue, 
        `${selectedLanguage}_name`, 
        questOrder.findIndex(quest => quest.id === questId) + 1, 
        'text_contents', 
        'content', 
        questOrder.find(quest => quest.id === questId)[`${selectedLanguage}_name_id`]
    );
};

// Funktion zur Erstellung eines neuen Quest-Inhalts
const createNewQuestContent = async (questId, newValue) => {
    try {
        const newQuestTitleContent = await handleNewEntry('text_contents', () => {}, showNotification, {
            'content': newValue,
        });

        const newQuestContent = await handleNewEntry('quest_contents', () => {}, showNotification, {
            'quest_id': questId,
            'meta_category_id': 1,
            'content_id': newQuestTitleContent.id,
            'language_id': getLanguageIdByCode(selectedLanguage, languages),
        });

        setQuestContents(prevquestContents => [
            ...prevquestContents,
            { id: newQuestContent.id, quest_id: questId, meta_category_id: 1, content_id: newQuestTitleContent.id, language_id: getLanguageIdByCode(selectedLanguage, languages), content: newValue }
        ]);

    } catch (error) {
        console.error('Fehler beim Erstellen eines neuen Quest-Inhalts:', error);
    }
};


    return (
        <div>
            <h3>Verfügbare Quests</h3>
            {questOrder.length > 0 ? (
                <DndProvider backend={HTML5Backend}>
                    {questOrder.map((quest, index) => (
                        <DraggableCard
                            usage={'quests'}
                            key={quest.id}
                            index={index}
                            dragItem={quest}
                            elementOrder={questOrder}
                            setElementOrder={setQuestOrder}
                            moveElement={moveQuest}
                            selectedLanguage={selectedLanguage}
                            deleteElement={handleDeleteQuest}
                            onSave={async (newValue) => {
                                if (quest[`${selectedLanguage}_name`]) {
                                    // Quest existiert bereits
                                    updateExistingQuestContent(quest.id, newValue);
                                } else {
                                    await createNewQuestContent(quest.id, newValue);
                                }
                            }}
                        />
                    ))}
                </DndProvider>
            ) : (
                <p>Keine Quests verfügbar</p>
            )}

            {!isAddingQuest && (
                <Button onClick={handleAddNewEntry}>Neues Quest hinzufügen</Button>
            )}
            {isAddingQuest && (
                <div>
                    <p>Name
                        <input className='ml-1'
                            type="text"
                            value={newQuestName}
                            onChange={(e) => setNewQuestName(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && handleSaveNewQuest()}
                            placeholder={`Questname auf Deutsch`}
                        />
                    </p>
                    <p>
                        <Button onClick={handleSaveNewQuest}>Speichern</Button>
                        <Button className='ml-1' onClick={handleCancelAdd}>Abbrechen</Button>
                    </p>
                </div>
            )}
        </div>
    );
};

export default StoryQuests;
