import React, { useMemo } from 'react';
import { BsMusicNoteBeamed } from 'react-icons/bs';

const Playlist = ({
  tracks,
  handleTrack,
  currentTrackSlug, // Use currentTrackSlug instead of currentTrackId
  language,
  metas
}) => {
  // Memoize the rendered tracks to avoid unnecessary re-renders
  const renderedTracks = useMemo(() => {
    return tracks.map((track, index) => {

      return (
        <button
          key={track.slug} // Use track.slug as key
          onClick={() => handleTrack(track.slug)}  // Use track.slug instead of index
          className={`list-group-item list-group-item-action d-flex align-items-center ${track.slug === currentTrackSlug ? 'active' : ''}`}
        >
        <div className="playlist-thumbnail-container">
        <div className="playlist-thumbnail">
          {(track.metadata.trackMeta_cover && track.metadata.trackMeta_cover[language]) ||
          (metas.storyMeta_cover && metas.storyMeta_cover[language]) ? (
            <img 
              className="audio-image-small" 
              src={
                (track.metadata.trackMeta_cover && track.metadata.trackMeta_cover[language]) || 
                (metas.storyMeta_cover && metas.storyMeta_cover[language]) || 
                "/assets/illustrations/placeholder-cover.jpg"
              } 
              alt="audio avatar" 
              onError={(e) => {
                e.target.onerror = null; 
                e.target.src = "/assets/illustrations/placeholder-cover.jpg";
              }}
            />
          ) : (
            <img 
              className="audio-image-small" 
              src="/assets/illustrations/placeholder-cover.jpg" 
              alt="Cover" 
            />
          )}
        </div>
        </div>
          <div className="flex-grow-1">
            {track.metadata.trackMeta_name ? track.metadata.trackMeta_name[language] : 'Unbenannter Track'}
          </div>
        </button>
      );
    });
  }, [tracks, currentTrackSlug, language]); // Recompute only when tracks, currentTrackSlug or language changes

  return (
    <>
      <div className='title-page'><h2>Stationen</h2></div>
      <div className="playlist-wrapper list-group">
        {renderedTracks}
      </div>
    </>
  );
};

export default Playlist;
