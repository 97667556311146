import React, { useContext, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faMusic, faMapMarkedAlt, faQrcode, faKey, faPuzzlePiece, faRadio, faFlask, faPlay } from '@fortawesome/free-solid-svg-icons';
import { WalkDataContext } from './hooks/walkDataContext';

function NavigationTabs({ activeTab, onTabChange }) {
  const { storyMetas, contentState, tracks } = useContext(WalkDataContext);

  const getIconForTab = (tab) => {
    const icons = {
      'home': faHome,
      'playlist': faMusic,
      'map': faMapMarkedAlt,
      'qr': faQrcode,
      'password': faKey,
      'quest' : faPuzzlePiece,
      'radio' : faRadio,
      'lab' : faPlay
    };
    return icons[tab];
  };

  let navigationTabs = ['home', 'playlist', 'map', 'qr'];

  if (storyMetas && storyMetas.storyMeta_trackpasswords && parseFloat(storyMetas.storyMeta_trackpasswords[1]) > 0) {
    navigationTabs.splice(1, 0, 'password');
  }

  if (storyMetas && storyMetas.storyMeta_type && storyMetas.storyMeta_type['1'] === 'adventure' ) {
    navigationTabs = ['quest', 'lab' ,'radio'];
  }

  const countHighlightedActiveTracks = useMemo(() => {
    return contentState.filter(item => 
      item.type === 'track' && 
      item.state === 'active' && 
      item.highlight === true &&
      !(tracks.find(track => track.slug === item.slug)?.metadata?.trackMeta_radio?.[1])
    ).length;
  }, [contentState, tracks]);

  const countHighlightedActivePuzzles = useMemo(() => {
    return contentState.filter(item => 
      item.type === 'puzzle' && 
      item.state === 'active'
    ).length;
  }, [contentState]);

  const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);

  return (
    <nav className="navbar fixed-bottom navbar-light bg-light">
      <div className="container-fluid justify-content-around">
        {navigationTabs.map(tab => (
          <div key={tab} className="nav-item" style={{ position: 'relative' }}>
            <button
              className={`btn btn-link ${activeTab === tab ? 'active' : ''}`}
              onClick={() => onTabChange(tab)}
            >
              <FontAwesomeIcon icon={getIconForTab(tab)} />
              <span className="visually-hidden">{capitalize(tab)}</span>
              {tab === 'lab' && countHighlightedActiveTracks > 0 && (
                <span style={{
                  position: 'absolute',
                  top: '-8px',
                  right: '-8px',
                  background: 'red',
                  color: 'white',
                  borderRadius: '50%',
                  width: '20px',
                  height: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '12px'
                }}>
                  {countHighlightedActiveTracks}
                </span>
              )}
              {tab === 'quest' && countHighlightedActivePuzzles > 0 && (
                <span style={{
                  position: 'absolute',
                  top: '-8px',
                  right: '-8px',
                  background: 'green',
                  color: 'white',
                  borderRadius: '50%',
                  width: '20px',
                  height: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '12px'
                }}>
                  {countHighlightedActivePuzzles}
                </span>
              )}
            </button>
          </div>
        ))}
      </div>
    </nav>
  );
}

export default NavigationTabs;