import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Form, Badge, Card } from 'react-bootstrap';
import { fetchData,fetchData2 } from '../../utility/ApiRequests';
import { handleSaveChanges as handleSaveChangesUtil, handleDeleteEntry, handleNewEntry, updateDb } from '../../utility/dataHandling';
import useSortableData from '../../hooks/useSortableData';
import { useNotification } from '../../hooks/useNotifications';
import EditableField from '../../utility/EditableField';
import SortIndicator from '../../utility/SortIndicator';
import { ReactComponent as Trash } from 'bootstrap-icons/icons/trash.svg';
import { v4 as uuidv4 } from 'uuid';
import 'leaflet/dist/leaflet.css';  // Import Leaflet CSS



const PuzzleDetails = () => {
    const { puzzleId } = useParams();
    const route = '/api/puzzles/' + puzzleId;
    const contextPlural = 'Puzzle Details';

    const [contextData, setContextData] = useState([]);
    const [metaCategories, setMetaCategories] = useState([]);
    const [categoryNames, setCategoryNames] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [puzzleData, setPuzzlesData] = useState([]);
    const [puzzleContentData, setPuzzleContentData] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState('de_DE');
    const [cities, setCities] = useState([]);
    const [storyCities, setStoryCities] = useState([]);
    const [categories, setCategories] = useState([]);
    const [storyCategories, setStoryCategories] = useState([]);
    const [quests, setQuests] = useState([]);
    const [questContents, setquestContents] = useState([]);

    const [mapDetails, setMapDetails] = useState(null);



    const [formattedContents, setFormattedContents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [editActive, setEditActive] = useState(false);
    const { items: sortedContextData, requestSort, sortConfig } = useSortableData(formattedContents);
    const { showNotification } = useNotification();
    const token = localStorage.getItem('token');
    const handleSaveChanges = handleSaveChangesUtil(setFormattedContents, showNotification);


    

    useEffect(() => {
        const initFetch = async () => {
            try {
                const data = await fetchData(route, token);
                setContextData(data);
                setMetaCategories(data.metaCategories);
                setPuzzlesData(data.puzzles);
                setLanguages(data.languages);
                setPuzzleContentData(data.puzzleContents);
                setCategoryNames(data.metaNames);
                setIsLoading(false);

                setMapDetails(null);

            } catch (error) {
                console.error('Fehler beim Laden der ' + contextPlural + ':', error);
                showNotification('Fehler beim Laden der ' + contextPlural + '.', 'danger');
                setIsLoading(false);
            }
        };
        initFetch();
    }, []);




    function transformData() {
        const languageCodeMap = languages.reduce((acc, lang) => {
            acc[lang.id] = lang.language_code;
            return acc;
        }, {});

        const metaNameMap = categoryNames.reduce((acc, name) => {
            if (!acc[name.content_key]) {
                acc[name.content_key] = {};
            }
            acc[name.content_key][languageCodeMap[name.language_id]] = name.content;
            return acc;
        }, {});

        const categoryTypeMap = metaCategories.reduce((acc, category) => {
            acc[category.id] = { type: category.type, has_translations: category.has_translations };
            return acc;
        }, {});

        const result = [];
        let newId = 1;


        

        const quest = puzzleData;

        const puzzleContentMap = puzzleContentData.filter(sc => sc.puzzle_id === quest.id).reduce((acc, sc) => {
            if (!acc[sc.meta_category_id]) {
                acc[sc.meta_category_id] = {};
            }
            acc[sc.meta_category_id][languageCodeMap[sc.language_id] || 'null'] = { content: sc.content, id: sc.content_id, content_id: sc.id, key: sc.key };
            return acc;
        }, {});
        
        metaCategories.forEach(metaCategory => {
            const newEntry = {
                id: newId++,
                puzzleId: quest.id,
                meta_category_id: metaCategory.id,
                name: metaNameMap[metaCategory.title_key]?.['de_DE'] || null,
                type: categoryTypeMap[metaCategory.id].type,
                has_translations: categoryTypeMap[metaCategory.id].has_translations,
            };
        
            languages.forEach(lang => {
                const langCode = lang.language_code;
                if (langCode !== 'null') {
                    newEntry[`${langCode}_name`] = puzzleContentMap[metaCategory.id]?.[langCode]?.content;
                    newEntry[`${langCode}_id`] = puzzleContentMap[metaCategory.id]?.[langCode]?.id || null;
                    newEntry[`${langCode}_content_id`] = puzzleContentMap[metaCategory.id]?.[langCode]?.content_id || null;
                    newEntry[`${langCode}_key`] = puzzleContentMap[metaCategory.id]?.[langCode]?.key || null;
                }
            });
        
            newEntry['null_name'] = puzzleContentMap[metaCategory.id]?.['null']?.content || null;
            newEntry['null_id'] = puzzleContentMap[metaCategory.id]?.['null']?.id || null;
            newEntry['null_content_id'] = puzzleContentMap[metaCategory.id]?.['null']?.content_id || null;
            newEntry['null_content_key'] = puzzleContentMap[metaCategory.id]?.['null']?.key || null;
        
            result.push(newEntry);
        });

        return result;
    }

    useEffect(() => {
        if (puzzleContentData) {
            setFormattedContents(transformData());
        }
    }, [puzzleContentData, metaCategories, languages, categoryNames]);

    const getLanguageIdByCode = (languageCode) => {
        const language = languages.find(lang => lang.language_code === languageCode);
        return language ? language.id : null;
    };

    const renderData = (myData, type) => {
        if (myData && typeof myData === 'object') {
            if (Array.isArray(myData) && myData.length === 2) {
                return `[${myData[0]}, ${myData[1]}]`;
            } else if (!Array.isArray(myData) && myData.x !== undefined && myData.y !== undefined) {
                return `[${myData.x}, ${myData.y}]`;
            }
        }
        if (type === 'image') {
            return <img src={myData} style={{ width: "1.5rem", height: "1rem" }} />;
        }
        if (type === 'audio') {
            const uniqueKey = uuidv4();
            return (
                <audio controls key={uniqueKey}>
                    <source src={myData} type="audio/mpeg" />
                    Your browser does not support the audio element.
                </audio>
            );
        }
        return myData;
    };

    const setValueData = (myData) => {
        if (myData && typeof myData === 'object') {
            if (Array.isArray(myData) && myData.length === 2) {
                return [myData[0], myData[1]];
            } else if (!Array.isArray(myData) && myData.x !== undefined && myData.y !== undefined) {
                return [myData.x, myData.y];
            }
        }
        return myData;
    };

    const getFieldType = (type) => {
        switch (type) {
            case 'geo':
                return 'point';
            case 'numeric':
                return 'float';
            case 'longtext':
                return 'textarea';
            case 'image':
                return 'image';
            case 'audio':
                return 'audio';
            case 'date':
                return 'date';
            default:
                return 'text';
        }
    };

    const handleLanguageChange = (event) => {
        setSelectedLanguage(event.target.value);
    };



    const dummy =() =>{
        return;
    }


    if (isLoading) {
        return <div><div className="spinner"></div> Lädt... </div>;
    }


    if (contextData.length===0){
        return <div>kein Puzzle gefunden</div>
    }

    return (
        <div>
            <h2>{contextPlural}</h2>
            <div className="content-right">
                <Button onClick={() => console.log(contextData)}>
                    DEBUG1
                </Button>
                <Button onClick={() => console.log(questContents)}>
                    DEBUG2
                </Button>
                <Button onClick={() => console.log(formattedContents)}>
                    DEBUG3
                </Button>
            </div>
            <div className="content-right">
                <Button onClick={() => setEditActive(!editActive)}>
                    Bearbeitung {editActive ? 'deaktivieren' : 'aktivieren'}
                </Button>
            </div>
            <Form.Group controlId="languageSelect">
                <Form.Label>Sprache auswählen</Form.Label>
                <Form.Control  className="w-8" as="select" value={selectedLanguage} onChange={handleLanguageChange}>
                    {languages.filter(lang => lang.language_code !== 'null').map(lang => (
                        <option key={lang.id} value={lang.language_code}>
                            {lang.language_name}
                        </option>
                    ))}
                </Form.Control>
            </Form.Group>
            <div>Slug:
            <EditableField
                value={puzzleData.slug}
                onSave={async (newValue) => {
                    const val = await updateDb('quest_puzzles', puzzleData.questPuzzles_id, 'slug', newValue);
                    if (val) {
                        setPuzzlesData(prevData => ({
                            ...prevData,
                            slug: newValue
                        }));
                        showNotification('Slug erfolgreich geändert', 'success');
                    } else {
                        showNotification('Fehler beim ändern - ist die Slug für dieses Puzzle einzigartig?', 'danger');
                    }
                }}
                fieldType="text"
            />
 
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th onClick={() => requestSort('name')}>
                            Name
                            <SortIndicator isSorted={sortConfig.key === 'name'} direction={sortConfig.direction} />
                        </th>
                        <th onClick={() => requestSort(`${selectedLanguage}_name`)}>
                            Wert {selectedLanguage}
                            <SortIndicator isSorted={sortConfig.key === `${selectedLanguage}_name`} direction={sortConfig.direction} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                {sortedContextData.map(lan => {
                    const metaCategory = metaCategories.find(category => category.id === lan.meta_category_id);
                    const languageId = metaCategory && metaCategory.has_translations === 0 ? 1 : getLanguageIdByCode(selectedLanguage);
                    const languageField = metaCategory && metaCategory.has_translations === 0 ? 'null_name' : `${selectedLanguage}_name`;
                    const languageIdField = metaCategory && metaCategory.has_translations === 0 ? 'null_id' : `${selectedLanguage}_id`;

                    return (
                        <tr key={lan.id}>
                            <td style={metaCategories.some(category => category.id === lan.meta_category_id && category.has_translations === 0) ? { backgroundColor: 'lightgreen' } : {}}>{lan.name}</td>
                            <td style={lan['null_name'] ? { backgroundColor: 'lightgreen' } : {}}>
                                {editActive ? (
                                    <EditableField
                                        value={setValueData(lan['null_name']) || setValueData(lan[`${selectedLanguage}_name`]) || ''}
                                        //geoObjects={geoObjects}
                                        {...(getFieldType(lan.type) === 'image' && { 
                                            fileId: lan['null_id'] || lan[`${selectedLanguage}_id`],
                                            height: "8rem",
                                            width: "8rem",
                                            fileKey: lan['null_name_key'] || lan[`${selectedLanguage}_key`],
                                            fileDestination: "image_contents",
                                            fileUsageName: "image",
                                            languageId: metaCategory && metaCategory.has_translations === 0 ? 1 : getLanguageIdByCode(selectedLanguage),
                                            
                                        })}
                                        {...(getFieldType(lan.type) === 'audio' && { 
                                            fileId: lan['null_id'] || lan[`${selectedLanguage}_id`],
                                            height: "8rem",
                                            width: "8rem",
                                            fileKey: lan['null_name_key'] || lan[`${selectedLanguage}_key`],
                                            fileDestination: "audio_contents",
                                            fileUsageName: "audio",
                                            languageId: metaCategory && metaCategory.has_translations === 0 ? 1 : getLanguageIdByCode(selectedLanguage),
                                            
                                        })}
                                        {...(getFieldType(lan.type) === 'point' && { 
                                            //lastEditedPoint: mapDetails.startLocation? mapDetails.startLocation: null,
                                            //geoObjects: mapDetails.questLocation? mapDetails.questLocation: null,
                                            //mapImage: mapDetails.mapImage ? mapDetails.mapImage : null,
                                        })}

                                        placeholder={lan.name}
                                        ifEmpty="---"
                                        onSave={async (newValue) => {
                                            const metaCategory = metaCategories.find(category => category.id === lan.meta_category_id);
                                            const languageField = metaCategory && metaCategory.has_translations === 0 ? 'null_name' : `${selectedLanguage}_name`;
                                            const languageIdField = metaCategory && metaCategory.has_translations === 0 ? 'null_id' : `${selectedLanguage}_id`;

                                            if (lan[languageField]) {
                                                    if (getFieldType(lan.type) === 'image' || getFieldType(lan.type) === 'audio') {
                                                        const { file_url, filename } = newValue;
                                                        setPuzzleContentData(prevContextData =>
                                                            prevContextData.map(item =>
                                                                item.id === lan[`${selectedLanguage}_content_id`] || item.id === lan['null_content_id']
                                                                    ? { ...item, content: file_url, key: filename }
                                                                    : item
                                                            )
                                                        );
                                                } else {
                                                    handleSaveChanges(newValue, languageField, lan.id, lan.type + '_contents', 'content', lan[languageIdField]);
                                                }
                                            } else {
                                                let newContentEntry ={}
                                                if (getFieldType(lan.type) === 'image' || getFieldType(lan.type) === 'audio') {
                                                    newContentEntry.id = newValue.file_id;

                                                } else {
                                                newContentEntry = await handleNewEntry(lan.type + '_contents', dummy, dummy, {
                                                    'content': newValue,
                                                });
                                            }

                                                if (newContentEntry?.id) {
                                                    const languageId = metaCategory && metaCategory.has_translations === 0 ? 1 : getLanguageIdByCode(selectedLanguage);
                                                    const newPuzzleContentEntry = await handleNewEntry('puzzle_contents', dummy, showNotification, {
                                                        'puzzle_id': lan.puzzleId,
                                                        'meta_category_id': lan.meta_category_id,
                                                        'content_id': newContentEntry.id,
                                                        'language_id': languageId
                                                    });
                                                    if (getFieldType(lan.type) === 'image' || getFieldType(lan.type) === 'audio') {
                                                        newPuzzleContentEntry['content'] = newValue.file_url;
                                                        newPuzzleContentEntry['key'] = newValue.file_name;
                                                    } else{
                                                        newPuzzleContentEntry['content'] = newValue;
                                                    }
                                                    
                                                    setPuzzleContentData(prevContextData => [...prevContextData, newPuzzleContentEntry]);
                                                }
                                            }
                                        }}
                                        fieldType={getFieldType(lan.type)}
                                    />
                                ) : (
                                    renderData(lan['null_name'] || lan[`${selectedLanguage}_name`], lan.type) || <span className='text-red'>noch kein Wert</span>
                                )}
                                {editActive && (
                                    <Trash onClick={() => {
                                        handleDeleteEntry('puzzle_contents', lan[`${selectedLanguage}_content_id`] || lan['null_content_id'], dummy, showNotification);
                                        setPuzzleContentData(prevContextData => prevContextData.filter(item => item.id !== lan[`${selectedLanguage}_content_id`] && item.id !== lan['null_content_id']));
                                    }} />
                                )}
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>

        </div>
    );
};

export default PuzzleDetails;

