export const createReverb = (Reverb, decay = 1, wet = 0) => {
    return new Reverb({
      decay,
      wet
    }).toDestination();
  };

export const createPingPongDelay = (PingPongDelay, delayTime = 0.25, feedback = 0.5, wet = 0.5) => {
return new PingPongDelay({
    delayTime,
    feedback,
    wet
});
};

export const connectEffects = (player, pingPongDelay, reverb, isPingPongDelayEnabled) => {
player.disconnect();
if (isPingPongDelayEnabled) {
    player.chain(pingPongDelay, reverb);
} else {
    player.connect(reverb);
}
};