import React, { useEffect, useRef, useCallback } from 'react';

const ProgressBar = React.memo(({ audioRef, progressBarRef, duration, timeProgress, setTimeProgress }) => {
  const playAnimationRef = useRef();

  const repeat = useCallback(() => {
    if (audioRef.current && progressBarRef.current) {
      const currentTime = audioRef.current.currentTime;
      setTimeProgress(currentTime);
      progressBarRef.current.value = currentTime;
      progressBarRef.current.max = duration;
      progressBarRef.current.style.setProperty(
        '--range-progress',
        `${(progressBarRef.current.value / duration) * 100}%`
      );
      playAnimationRef.current = requestAnimationFrame(repeat);
    }
  }, [audioRef, duration, progressBarRef, setTimeProgress]);

  useEffect(() => {
    if (audioRef.current) {
      playAnimationRef.current = requestAnimationFrame(repeat);
    }

    const updateProgress = () => {
      if (audioRef.current && progressBarRef.current) {
        const currentTime = audioRef.current.currentTime;
        setTimeProgress(currentTime);
        progressBarRef.current.value = currentTime;
        progressBarRef.current.max = duration;
        progressBarRef.current.style.setProperty(
          '--range-progress',
          `${(currentTime / duration) * 100}%`
        );
      }
    };

    audioRef.current.addEventListener('timeupdate', updateProgress);

    return () => {
      cancelAnimationFrame(playAnimationRef.current);
      if (audioRef.current) {
        audioRef.current.removeEventListener('timeupdate', updateProgress);
      }
    };
  }, [repeat, duration, setTimeProgress]);

  const handleProgressChange = () => {
    audioRef.current.currentTime = progressBarRef.current.value;
  };

  const formatTime = (time) => {
    if (time && !isNaN(time)) {
      const minutes = Math.floor(time / 60);
      const formatMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
      const seconds = Math.floor(time % 60);
      const formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      return `${formatMinutes}:${formatSeconds}`;
    }
    return '00:00';
  };

  return (
    duration > 0 &&
    <div className="progress">
      <span className="time">{formatTime(timeProgress)}</span>
      <input
        className="progress-bar"
        type="range"
        ref={progressBarRef}
        defaultValue="0"
        onChange={handleProgressChange}
      />
      <span className="time">{formatTime(duration)}</span>
    </div>
  );
});

export default ProgressBar;
