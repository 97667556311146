import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App'; // Stellen Sie sicher, dass der Pfad zu Ihrer App-Komponente korrekt ist
// css
import './styles/index.css';
import './styles/customize-progress-bar.css';

const container = document.getElementById('root'); // Der DOM-Container, in dem die App gerendert wird
const root = createRoot(container); // Erstellen Sie die Wurzel

root.render(
 //<React.StrictMode>
    <App />
 //</React.StrictMode>
);