import React, { useContext } from 'react';
import { WalkDataContext } from '../../hooks/walkDataContext';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGaugeHigh,  faCircleRight, faCircleLeft} from '@fortawesome/free-solid-svg-icons';

const AudioEffectControls = ({
  effectName,
  label,
  type,
  min,
  max,
  step,
  activeLabel,
  inactiveLabel,
  className,
  activeIcon,
  inactiveIcon
}) => {
  const { audioEffects, updateAudioEffect } = useContext(WalkDataContext);

  const handleChange = (value) => {
    if (type === 'slider') {
      const realValue = transformFromSlider(parseFloat(value));
      updateAudioEffect(effectName, realValue);
    } else if (type === 'toggle' || type === 'button') {
      updateAudioEffect(effectName, !audioEffects[effectName]);
    }
  };

  // Skalierungsfaktor berechnen
  const scaleFactorPositive = 100 / Math.log(max);
  const scaleFactorNegative = 100 / Math.log(1/min);

  // Transformation vom echten Wert zum Slider-Wert (-100 bis 100)
  const transformToSlider = (value) => {
    if (value === 1) return 0;
    if (value < 1) {
      // Negative Werte für Verlangsamung, skaliert auf min
      return -Math.round(Math.log(1/value) * scaleFactorNegative);
    }
    // Positive Werte für Beschleunigung, skaliert auf max
    return Math.round(Math.log(value) * scaleFactorPositive);
  };

  // Transformation vom Slider-Wert zum echten Wert
  const transformFromSlider = (sliderValue) => {
    if (sliderValue === 0) return 1;
    if (sliderValue < 0) {
      // Verlangsamung: min bis 1
      return 1 / Math.exp(-sliderValue / scaleFactorNegative);
    }
    // Beschleunigung: 1 bis max
    return Math.exp(sliderValue / scaleFactorPositive);
  };

    if (type === 'slider') {
      const sliderValue = transformToSlider(audioEffects[effectName]);
      
      return (
        <div className={`speed-range-container ${className || ''}`}>
          <input
            type="range"
            className="speed-range"
            min={-100}
            max={100}
            value={sliderValue}
            onChange={(e) => handleChange(e.target.value)}
          />
        </div>
      );
    }
    else if (type === 'toggle') {
    return (
      <div className={className || ''}>
        <input
          type="checkbox"
          checked={audioEffects[effectName]}
          onChange={() => handleChange()}
          id={`toggle-${effectName}`}
        />
        <label htmlFor={`toggle-${effectName}`}>{label}</label>
      </div>
    );
  } else   if (type === 'button') {
    return (
      <Button
        onClick={() => handleChange()}
        className={`audio-effect-button ${audioEffects[effectName] ? 'active' : ''} ${className || ''}`}
      >
        {audioEffects[effectName] 
          ? (activeIcon ? <FontAwesomeIcon icon={activeIcon} className="fs-4 flip-vertical" /> : activeLabel)
          : (inactiveIcon ? <FontAwesomeIcon icon={inactiveIcon} className="fs-4" /> : inactiveLabel)
        }
      </Button>
    );
  }

  return null;
};

export default AudioEffectControls;