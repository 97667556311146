import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { ReactComponent as Trash } from 'bootstrap-icons/icons/trash.svg';
import { fetchData } from '../../utility/ApiRequests';
import { handleSaveChanges as handleSaveChangesUtil, handleNewEntry, handleDeleteEntry } from '../../utility/dataHandling';
import useSortableData from '../../hooks/useSortableData';
import { useNotification } from '../../hooks/useNotifications';
import EditableField from '../../utility/EditableField';
import SortIndicator from '../../utility/SortIndicator';
import formatDate from '../../utility/dateUtils';
import axios from 'axios';

const DeletionQueue = () => {
    const route = '/api/deletionQueue';
    const context_singular = 'Warteschlange zum Löschen';
    const context_plural = 'Warteschlange zum Löschen';

    const [contextData, setContextData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [editActive, setEditActive] = useState(false);
    const { items: sortedContextData, requestSort, sortConfig } = useSortableData(contextData);
    const { showNotification } = useNotification();
    const token = localStorage.getItem('token');
    const handleSaveChanges = handleSaveChangesUtil(setContextData, showNotification);
 
    useEffect(() => {
        const initFetch = async () => {
            try {
                const data = await fetchData(route, token);
                setContextData(data);
                setIsLoading(false);
            } catch (error) {
                console.error('Fehler beim Laden der ' + context_plural + ':', error);
                showNotification('Fehler beim Laden der ' + context_plural + '.', 'danger');
                setIsLoading(false);
            }
        };
        initFetch();
    }, []);

    if (isLoading) {
        return <div><div className="spinner"></div> Lädt... </div>;
    }
    const deleteFile = async (useCase, fileUrl, id) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/deleteFile`, {
                headers: {
                    'authorization': `Bearer ${token}`,
                },
                params: {
                    useCase,
                    fileUrl,
                }
            });
    
            if (response.status === 200) {
                handleDeleteEntry('deletion_queue', id, setContextData, showNotification);
            } else {
                showNotification('Löschen fehlgeschlagen', 'warning');
            }
        } catch (error) {
            showNotification('Fehler beim Senden der Anfrage', 'danger');
            console.error('Fehler beim Senden der Anfrage', error);
        }
    };

    const deleteQueue = async () => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/deleteQueue`, {
                headers: {
                    'authorization': `Bearer ${token}`,
                }
            });
    
            if (response.status === 200) {
                setContextData([]);
            } else {
                showNotification('Löschen fehlgeschlagen', 'warning');
            }
        } catch (error) {
            showNotification('Fehler beim Senden der Anfrage', 'danger');
            console.error('Fehler beim Senden der Anfrage', error);
        }
    };

    
    return (
        <div>
            <h2>{context_plural}</h2>
            {contextData.length===0 ? ('Keine Dateine in der Warteschlange' ): 
            (<div>
            <div className='content-right'><Button className='' onClick={() => deleteQueue()}> alle löschen </Button></div>
            <table className="table">
                <thead>
                    <tr>
                        <th onClick={() => requestSort('id', 'numeric')}>
                            ID
                            <SortIndicator isSorted={sortConfig.key === 'id'} direction={sortConfig.direction} />
                        </th>
                        <th onClick={() => requestSort('use_case')}>
                            Usecase
                            <SortIndicator isSorted={sortConfig.key === 'use_case'} direction={sortConfig.direction} />
                        </th>
                        <th onClick={() => requestSort('file_url')}>
                            FileKey
                            <SortIndicator isSorted={sortConfig.key === 'file_url'} direction={sortConfig.direction} />
                        </th>
                        <th onClick={() => requestSort('created_at', 'date')}>
                            gelöscht am
                            <SortIndicator isSorted={sortConfig.key === 'created_at'} direction={sortConfig.direction} />
                        </th>
                        <th>
                            endgültig löschen
                        </th>
                        {/* Weitere Spalten hier */}
                    </tr>
                </thead>
                <tbody>
                    {sortedContextData.map(lan => {
                        return (
                            <tr key={lan.id}>
                                <td>{lan.id}</td>
                                <td>
                                    {lan.use_case}
                                </td>
                                <td>
                                    {lan.file_url}
                                </td>

                                <td>{formatDate(lan.created_at.trim())}</td>

                                <td>
                                    <Trash
                                        onClick={() => deleteFile(lan.use_case,lan.file_url,lan.id)}
                                    ></Trash>
                                </td>

                            </tr>
                        );
                    })}
                </tbody>
            </table>
        
    </div>)}
    </div>
    );
};

export default DeletionQueue;
