export const createUniqueSlug = (name, existingSlugs, prefix='') => {
    const umlautMap = {
        'ä': 'ae',
        'ö': 'oe',
        'ü': 'ue',
        'Ä': 'Ae',
        'Ö': 'Oe',
        'Ü': 'Ue',
        'ß': 'ss'
    };
    let slug = name
        .toLowerCase()
        .replace(/[äöüÄÖÜß]/g, match => umlautMap[match])
        .replace(/[^a-z0-9]+/g, '') // Entfernt alle nicht-alphanumerischen Zeichen
        .replace(/\s+/g, ''); // Entfernt Leerzeichen

    slug = `${prefix}${slug}`;

    // Falls der Slug bereits existiert, inkrementieren
    let uniqueSlug = slug;
    let counter = 2;
    while (existingSlugs.includes(uniqueSlug)) {
        uniqueSlug = `${slug}-${counter}`;
        counter++;
    }

    return uniqueSlug;
};
