import React, {useContext} from 'react';
import { AuthContext }from '../auth/AuthContext';
import { Button } from 'react-bootstrap';

const LogoutButton = () => {
    const { logout } = useContext(AuthContext); // Zugriff auf die logout-Funktion aus dem AuthContext
    const handleLogout = () => {
        
        // Token aus dem Local Storage entfernen
        localStorage.removeItem('token');
        logout();
    


        // Weitere Aktionen nach dem Logout, z.B. Benutzerstatus aktualisieren oder zur Login-Seite umleiten
        // ...

        // Optional: Zustand im AuthContext aktualisieren, falls verwendet
        // const { logout } = useContext(AuthContext);
        // logout();
    };

    return (
        <span onClick={handleLogout}>Logout</span>
    );
};

export default LogoutButton;