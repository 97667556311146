import React, {} from 'react';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

function More({closeMenuItem}) {

    return (

<div className="overlay"> 
<div className='popup-container'>
<div className='popup-content'>
<div className='title-page'>Weitere Inhalte</div>


<div>
  <p>
  Schon gewusst? Viele Hörspaziergänge lassen sich auch per App erleben. Es braucht dann vor Ort keine Internetverbindung und Kapitel starten automatisch, wenn der passende Ort erreicht ist. Einige Audiowalks sind speziell für die Erfahrung per App erstellt und nur dort verfügbar. Hier geht es zu den kostenfreien Apps Storydive und Ortspiel:
</p>
<strong>Storydive</strong>
<ul>
  <li><a href="https://apps.apple.com/de/app/storydive/id1504043902">für iPhone</a></li>
  <li><a href="https://play.google.com/store/apps/details?id=de.storydive.app">für Android</a></li>
</ul>
<strong>Ortspiel</strong>
<ul>
  <li><a href="https://apps.apple.com/us/app/ortspiel/id1483590350">für iPhone</a></li>
  <li><a href="https://play.google.com/store/apps/details?id=com.storydive.ortspiel">für Android</a></li>
</ul>


</div>
</div>

        <button onClick={() => closeMenuItem()} className='close-Button'>
        <FontAwesomeIcon icon={faTimes} size="lg" />
        </button>

</div>
</div>

);
}

export default More;
