import React, { useContext, useState } from 'react';
import { Card, ListGroup, Button, Modal } from 'react-bootstrap';
import { getStoryMetadataValue } from './utility/MetadataFetcher';
import { WalkDataContext } from './hooks/walkDataContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo} from '@fortawesome/free-solid-svg-icons';

const ResetModal = ({ show, handleClose, handleReset }) => (
 <Modal show={show} onHide={handleClose}>
   <Modal.Header closeButton>
     <Modal.Title>Reset progress?</Modal.Title>
   </Modal.Header>
   <Modal.Body>
     <i className="fas fa-exclamation-triangle text-warning me-2"></i>
     All your progress will be lost.
   </Modal.Body>
   <Modal.Footer>
     <Button variant="secondary" onClick={handleClose}>
       No
     </Button>
     <Button variant="danger" onClick={handleReset}>
      Yes, reset
     </Button>
   </Modal.Footer>
 </Modal>
);

const StoryDetails = ({metas, language}) => {
 const [showResetModal, setShowResetModal] = useState(false);
 const { storyData } = useContext(WalkDataContext);

 const storyTitle = getStoryMetadataValue(metas, language, 'storyMeta_title', '-- ohne Titel --');
 const storySubTitle = getStoryMetadataValue(metas, language, 'storyMeta_subtitle', '');
 const storyAuthor = getStoryMetadataValue(metas, 1, 'storyMeta_author', '');
 const storyCover = getStoryMetadataValue(metas, language, 'storyMeta_cover', '/assets/illustrations/placeholder-cover.jpg');
 const storyDescription = getStoryMetadataValue(metas, language, 'storyMeta_description', '', true);
 const storyDuration = getStoryMetadataValue(metas, 1, 'storyMeta_duration', '');
 const storyReleasedate = getStoryMetadataValue(metas, 1, 'storyMeta_releasedate', '');

 function formatDate(inputDate) {
   const date = new Date(inputDate);
   
   const monthNames = [
     "Januar", "Februar", "März", "April", "Mai", "Juni",
     "Juli", "August", "September", "Oktober", "November", "Dezember"
   ];

   const month = monthNames[date.getMonth()];
   const year = date.getFullYear();

   return `${month} ${year}`;
 }

 const formatMinutes = (minutes) => {
   if (minutes < 60) {
     return `etwa ${Math.round(minutes)} Minuten`;
   } else {
     const hours = Math.floor(minutes / 60);
     const remainingMinutes = minutes % 60;

     let roundedMinutes;
     if (remainingMinutes === 0) {
       return `etwa ${hours} Stunde${hours > 1 ? 'n' : ''}`;
     } else if (remainingMinutes <= 15) {
       roundedMinutes = 15;
     } else if (remainingMinutes <= 30) {
       roundedMinutes = 30;
     } else if (remainingMinutes <= 45) {
       roundedMinutes = 45;
     } else if (remainingMinutes <= 60) {
       roundedMinutes = 0;
     }

     if (roundedMinutes === 0) {
       return `etwa ${hours + 1} Stunde${hours + 1 > 1 ? 'n' : ''}`;
     } else if (roundedMinutes === 30) {
       return `etwa ${hours}.5h`;
     } else {
       return `etwa ${hours}h ${roundedMinutes} min`;
     }
   }
 };

 const handleReset = () => {
   localStorage.removeItem(`progress_${storyData.story.slug}`);
   window.location.reload();
 };

 if (!storyData?.story) {
  return (
    <div className="card-container d-flex justify-content-center align-items-center">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}

 return (
   <div className="card-container">
     <Card>
       <Card.Img variant="top" src={storyCover} alt={`${storyTitle} Coverbild`} className='card-coverimage' /><br />
       <Card.Body>
         {storyAuthor && 
           <span className='track-description'> {storyAuthor} </span>
         }
         <Card.Title>{storyTitle}</Card.Title>
         {storySubTitle && 
           <span className='track-description pb-3'> {storySubTitle} </span>
         }
         {storyDescription && <Card.Text>
           <span className='track-description' dangerouslySetInnerHTML={storyDescription} />
         </Card.Text>}
       </Card.Body>
       <ListGroup className="list-group-flush">
         {(storyDuration || storyReleasedate) && storyData?.story?.metadata?.storyMeta_type?.[1] !== 'adventure' &&( //TODO: change this when translations are implemented!!
           <ListGroup.Item>
             {storyDuration && <span>{formatMinutes(storyDuration)}</span>}
             {storyReleasedate && <span className='ml-150'>{formatDate(storyReleasedate)}</span>}
           </ListGroup.Item>
         )}
       </ListGroup>
     </Card>
     
     {storyData?.story?.metadata?.storyMeta_type?.[1] === 'adventure' && (
       <>
         <Button
           variant="secondary"
           className='mt-150 d-flex align-items-center gap-2'
           onClick={() => setShowResetModal(true)}>
           <FontAwesomeIcon icon={faUndo} className="fs-4" />
           Reset
          </Button>

         <ResetModal 
           show={showResetModal}
           handleClose={() => setShowResetModal(false)}
           handleReset={handleReset}
         />
       </>
     )}
   </div>
 );
};

export default StoryDetails;