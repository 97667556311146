import React, { useContext, useEffect } from 'react';
import AdminPanel from '../components/admin/AdminPanel';
import { useNavigate } from 'react-router-dom';
import { AuthContext }from '../components/auth/AuthContext';
import { Link } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';


const AdminPage = () => {
  const token = localStorage.getItem('token');
  const { user, expiryTime } = useContext(AuthContext);
  const navigate = useNavigate();
  let decodedToken;
  if (token){
    decodedToken = jwtDecode(token);
  }

  useEffect(() => {
    const currentTime = Math.floor(Date.now() / 1000);
      // Wenn der Benutzer nicht eingeloggt ist, umleiten zur Login-Seite
      if ( !token || decodedToken.role!==1) {
        // !user || user.userrole!==1 || expiryTime <= currentTime) 
        navigate('/');  // for now...
      }
  }, [user, token, expiryTime, navigate]);

  return (
    <div>
    <AdminPanel />
    {/* Weitere Admin-spezifische Komponenten könnten hier hinzugefügt werden */}
  </div>
  );
};

export default AdminPage;