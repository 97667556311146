import React, { useEffect, useState, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DraggableCard from '../../../utility/DraggableCard';
import update from 'immutability-helper';
import { handleNewEntry, updateElementPositionsInDB, handleDeleteEntry } from '../../../utility/dataHandling';
import { createUniqueSlug } from '../../../utility/Slug';
import { getLanguageIdByCode } from './DetailHelpers';

const QuestPuzzles = ({ puzzlesOrder, setPuzzlesOrder, puzzles, setPuzzles, puzzleContents, setPuzzleContents, languages, questId, showNotification,handleSaveChanges,selectedLanguage}) => {
    const [isAddingPuzzle, setisAddingPuzzle] = useState(false);
    const [newPuzzleName, setNewPuzzleName] = useState('');

    useEffect(() => {
        if (puzzles && puzzles.length > 0) {
            const initialOrder = puzzles
                .sort((a, b) => a.position - b.position)
                .map((puzzle, index) => {
                    const puzzleData = puzzleContents.filter(tc => tc.puzzle_id === puzzle.puzzle_id);
                    const name = puzzleData.reduce((acc, tc) => {
                        const langCode = languages.find(lang => lang.id === tc.language_id)?.language_code;
                        if (langCode) {
                            if (tc.meta_category_id === 2) {
                                acc[`${langCode}_audioUrl`] = tc.content;
                                acc[`${langCode}_audioUrl_id`] = tc.content_id;
                            }
                            if (tc.meta_category_id === 1) {
                                acc[`${langCode}_name`] = tc.content;
                                acc[`${langCode}_name_id`] = tc.content_id;
                            }
                        }
                        return acc;
                    }, {});
                    const geoLocation = puzzleData.find(tc => tc.meta_category_id === 6)?.content;
                    const geoLocation_id = puzzleData.find(tc => tc.meta_category_id === 6)?.content_id;
                    return {
                        ...puzzle,
                        ...name,
                        geoLocation,
                        geoLocation_id,
                        position: index + 1 // Reassign positions here
                    };
                });
            setPuzzlesOrder(initialOrder);
            setPuzzles(puzzles.map((puzzle, index) => ({
                ...puzzle,
                position: index + 1
            })));
        } else {
            setPuzzlesOrder([]); // Ensures that the puzzle order is reset if there are no puzzles
        }
    }, [puzzleContents, languages]);

    const movePuzzle = useCallback((dragIndex, hoverIndex, finalize = false) => {
        const draggedPuzzle = puzzlesOrder[dragIndex];
        const newPuzzleOrder = update(puzzlesOrder, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, draggedPuzzle],
            ],
        });
        const updatedPuzzleOrder = newPuzzleOrder.map((puzzle, index) => ({
            ...puzzle,
            position: index + 1,
        }));
    
        setPuzzlesOrder(updatedPuzzleOrder);
        setPuzzles(updatedPuzzleOrder.map(puzzle => ({
            id: puzzle.id,
            quest_id: puzzle.quest_id,
            puzzle_id: puzzle.puzzle_id,
            position: puzzle.position
        })));
    
        if (finalize) {
            handleSavePositionsToDb(updatedPuzzleOrder);
        }
    }, [puzzlesOrder]);

    const handleSavePositionsToDb = async (puzzles) => {
        await updateElementPositionsInDB(puzzles, 'puzzles');
    };

    const handleAddNewEntry = () => {
        setisAddingPuzzle(true);
    };
    
    const handleCancelAdd = () => {
        setisAddingPuzzle(false);
        setNewPuzzleName('');
    };

    const handleSaveNewPuzzle = async () => {
        const existingSlugs = puzzles.map(data => data.slug);
        const slug = createUniqueSlug(newPuzzleName, existingSlugs);

        const languageId = languages.find(lang => lang.language_code === 'de_DE')?.id;

        if (languageId) {
            const newPuzzle = await handleNewEntry('puzzles', () => {}, showNotification, {
                'state': 'published',
            });

            const newPuzzleInQuest = await handleNewEntry('quest_puzzles', () => {}, showNotification, {
                'quest_id': questId,
                'puzzle_id': newPuzzle.id,
                'position': puzzles.length + 1,
                'slug': slug
            });

            const newPuzzleTitleContent = await handleNewEntry('text_contents', () => {}, showNotification, {
                'content': newPuzzleName,
            });

            const newPuzzleMetaContent = await handleNewEntry('puzzle_contents', () => {}, showNotification, {
                'puzzle_id': newPuzzle.id,
                'meta_category_id': 1,
                'content_id': newPuzzleTitleContent.id,
                'language_id': languageId
            });

            setPuzzles(prevPuzzles => {
                const updatedPuzzles = [
                    ...prevPuzzles,
                    { id: newPuzzleInQuest.id, quest_id: parseInt(questId), puzzle_id: newPuzzle.id, position: prevPuzzles.length + 1 }
                ];
                return updatedPuzzles.map((puzzle, index) => ({
                    ...puzzle,
                    position: index + 1
                }));
            });

            setPuzzleContents(prevpuzzleContents => [
                ...prevpuzzleContents,
                { id: newPuzzleMetaContent.id, puzzle_id: newPuzzle.id, meta_category_id: 1, content_id: newPuzzleTitleContent.id, language_id: languageId, content: newPuzzleName }
            ]);
        } else {
            showNotification('Sprache de_DE existiert nicht.', 'danger');
        }

        setisAddingPuzzle(false);
        setNewPuzzleName('');
    };

    const handleDeletePuzzle = async (questPuzzleId, puzzleId) => {
        const deletePuzzle = await handleDeleteEntry('quest_puzzles', questPuzzleId, () => {}, showNotification);
        if (deletePuzzle) {
            setPuzzles(prevPuzzles => {
                const updatedPuzzles = prevPuzzles.filter(puzzle => parseInt(puzzle.id) !== parseInt(questPuzzleId));
                return updatedPuzzles.map((puzzle, index) => ({
                    ...puzzle,
                    position: index + 1
                }));
            });
            setPuzzleContents(prevpuzzleContents => prevpuzzleContents.filter(puzzle => parseInt(puzzle.puzzle_id) !== parseInt(puzzleId)));
        }
    };

    // Funktion zur Aktualisierung eines bestehenden Puzzle-Inhalts
const updateExistingPuzzleContent = (puzzleId, newValue) => {
    const updatedpuzzleContents = puzzleContents.map((content) => {
        if (content.puzzle_id === puzzleId) { 
            return {
                ...content,
                content: newValue,
            };
        }
        return content;
    });
    setPuzzleContents(updatedpuzzleContents);
    handleSaveChanges(
        newValue, 
        `${selectedLanguage}_name`, 
        puzzlesOrder.findIndex(puzzle => puzzle.id === puzzleId) + 1, // +1 to convert index to position
        'text_contents', 
        'content', 
        puzzlesOrder.find(puzzle => puzzle.id === puzzleId)[`${selectedLanguage}_name_id`]
    );
};

// Funktion zur Erstellung eines neuen Puzzle-Inhalts
const createNewPuzzleContent = async (puzzleId, newValue) => {
    try {
        const newPuzzleTitleContent = await handleNewEntry('text_contents', () => {}, showNotification, {
            'content': newValue,
        });

        const newPuzzleContent = await handleNewEntry('puzzle_contents', () => {}, showNotification, {
            'puzzle_id': puzzleId,
            'meta_category_id': 1,
            'content_id': newPuzzleTitleContent.id,
            'language_id': getLanguageIdByCode(selectedLanguage, languages),
        });

        setPuzzleContents(prevpuzzleContents => [
            ...prevpuzzleContents,
            { id: newPuzzleContent.id, puzzle_id: puzzleId, meta_category_id: 1, content_id: newPuzzleTitleContent.id, language_id: getLanguageIdByCode(selectedLanguage, languages), content: newValue }
        ]);

    } catch (error) {
        console.error('Fehler beim Erstellen eines neuen Puzzle-Inhalts:', error);
    }
};

    return (
        <div>
            <h3>Verfügbare Puzzles</h3>
            {puzzlesOrder.length > 0 ? (
                <DndProvider backend={HTML5Backend}>
                    {puzzlesOrder.map((puzzle, index) => (
                        <DraggableCard
                            usage={'puzzles'}
                            key={puzzle.id}
                            index={index}
                            dragItem={puzzle}
                            elementOrder={puzzlesOrder}
                            setElementOrder={setPuzzlesOrder}
                            moveElement={movePuzzle}
                            selectedLanguage={selectedLanguage}
                            deleteElement={handleDeletePuzzle}
                            onSave={async (newValue) => {
                                if (puzzle[`${selectedLanguage}_name`]) {
                                    // Puzzle existiert bereits
                                    updateExistingPuzzleContent(puzzle.id, newValue);
                                } else {
                                    await createNewPuzzleContent(puzzle.id, newValue);
                                }
                            }}
                        />
                    ))}
                </DndProvider>
            ) : (
                <p>Keine Puzzles verfügbar</p>
            )}
            {!isAddingPuzzle && (
                <Button onClick={handleAddNewEntry}>Neues Puzzle hinzufügen</Button>
            )}
            {isAddingPuzzle && (
                <div>
                    <p>Name
                        <input className='ml-1'
                            type="text"
                            value={newPuzzleName}
                            onChange={(e) => setNewPuzzleName(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && handleSaveNewPuzzle()}
                            placeholder={`Puzzlename auf Deutsch`}
                        />
                    </p>
                    <p>
                        <Button onClick={handleSaveNewPuzzle}>Speichern</Button>
                        <Button className='ml-1' onClick={handleCancelAdd}>Abbrechen</Button>
                    </p>
                </div>
            )}
        </div>
    );
};

export default QuestPuzzles;
