import React, { useState } from 'react';
import { Route, Routes, Link } from 'react-router-dom';


import UserManagerMain from  './userManager/UserManagerMain';
import AddUser from  './userManager/AddUser';

import UserDetails from  './userManager/userDetails/UserDetails';
import UserDetailsTab from  './userManager/userDetails/UserDetailsTab';

import AdminMainPage from  './AdminMainPage';

import LanguageTab from  './languages/LanguageTab';
import DeletionQueueTab from  './deletionQueue/deletionQueueTab';
import ContentsTab from  './contents/ContentsTab';
import CitiesTab from  './cities/CitiesTab';
import CategoriesTab from  './categories/CategoriesTab';

import StoriesTab from  './stories/StoriesTab';
import StoryMetasTab from  './stories/StoryMetasTab';
import StoryContentsTab from  './stories/StoryContentsTab';
import StoryDetails from  './stories/StoryDetails';

import GroupsMetasTab from  './groups/GroupsMetasTab';

import TrackMetasTab from  './tracks/TrackMetasTab';
import TrackContentsTab from  './tracks/TrackContentsTab';
import TrackDetails from  './tracks/TrackDetails';

import BackupDb from  './backup/BackupDb';

import QuestMetasTab from  './quests/QuestMetasTab';
import QuestContentsTab from  './quests/QuestContentsTab';
import QuestDetails from  './quests/QuestDetails';

import PuzzleMetasTab from  './puzzles/PuzzleMetasTab';
import PuzzleContentsTab from  './puzzles/PuzzleContentsTab';
import PuzzleDetails from  './puzzles/PuzzleDetails';






import { Navbar, Nav, NavDropdown } from 'react-bootstrap';

// Weitere Komponenten-Imports

const AdminPanel = () => {
    const [expanded, setExpanded] = useState(false);
    return (
        <>
            <Navbar bg="light" expand="lg" expanded={expanded}>
                <Navbar.Brand as={Link} to="/admin">AdminPanel</Navbar.Brand>
                <Navbar.Toggle aria-controls="admin-navbar-nav" onClick={() => setExpanded(expanded => !expanded)} />
                <Navbar.Collapse id="admin-navbar-nav">
                    <Nav className="mr-auto">
                        <NavDropdown title="new Stuff" id="admin-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/admin/languages" onClick={() => setExpanded(false)}>Sprachen</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/deletionQueue" onClick={() => setExpanded(false)}>Dateien zum löschen</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/contents" onClick={() => setExpanded(false)}>Contents</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/cities" onClick={() => setExpanded(false)}>Städte</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/categories" onClick={() => setExpanded(false)}>Kategorien</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/story-metas" onClick={() => setExpanded(false)}>Story Metas</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/group-metas" onClick={() => setExpanded(false)}>Gruppen Metas</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/stories" onClick={() => setExpanded(false)}>Stories</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/story-contents" onClick={() => setExpanded(false)}>Story Contents DEV</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/track-metas/" onClick={() => setExpanded(false)}>Track Metas</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/track-contents" onClick={() => setExpanded(false)}>Track Contents DEV</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/backup/db" onClick={() => setExpanded(false)}>Backup DB</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/quest-metas/" onClick={() => setExpanded(false)}>Quest Metas</NavDropdown.Item>

                        </NavDropdown>


                        <NavDropdown title="User Manager" id="admin-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/admin/userManager" onClick={() => setExpanded(false)}>User Manager</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/userManager/addUser" onClick={() => setExpanded(false)}>Nutzer hinzufügen</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/admin/userManager/userDetails/" onClick={() => setExpanded(false)}>Nutzer Liste</NavDropdown.Item>
                            {/* Weitere Dropdown-Items */}
                        </NavDropdown>

                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Routes>

                <Route path="/" element={<AdminMainPage />} />
               
                <Route path="/userManager/" element={<UserManagerMain />} />
                <Route path="/userManager/addUser" element={<AddUser />} />
                <Route path="/userManager/userDetails/:id" element={<UserDetails />} />
                <Route path="/userManager/userDetails/" element={<UserDetailsTab />} />

                <Route path="/languages/" element={<LanguageTab />} />
                <Route path="/deletionQueue/" element={<DeletionQueueTab />} />
                <Route path="/contents/" element={<ContentsTab />} />
                <Route path="/cities/" element={<CitiesTab />} />
                <Route path="/categories/" element={<CategoriesTab />} />
                <Route path="/story-metas/" element={<StoryMetasTab />} />
                <Route path="/stories/" element={<StoriesTab />} />
                <Route path="/story-contents/" element={<StoryContentsTab/>} />
                <Route path="/stories/:storyId" element={<StoryDetails />} />

                <Route path="/group-metas/" element={<GroupsMetasTab />} />
                <Route path="/track-metas/" element={<TrackMetasTab />} />
                <Route path="/track-contents/" element={<TrackContentsTab/>} />
                <Route path="/tracks/:trackId" element={<TrackDetails />} />

                <Route path="/backup/db/" element={<BackupDb />} />

                <Route path="/quest-metas/" element={<QuestMetasTab />} />
                <Route path="/quest-contents/" element={<QuestContentsTab />} />
                <Route path="/quests/:questId" element={<QuestDetails />} />

                <Route path="/puzzle-metas/" element={<PuzzleMetasTab />} />
                <Route path="/puzzle-contents/" element={<PuzzleContentsTab />} />
                <Route path="/puzzles/:puzzleId" element={<PuzzleDetails />} />







                {/* Weitere Routen */}
            </Routes>

        </>
    );
};

export default AdminPanel;
