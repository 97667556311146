import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { ReactComponent as Trash } from 'bootstrap-icons/icons/trash.svg';
import { fetchData } from '../../utility/ApiRequests';
import { handleSaveChanges as handleSaveChangesUtil, handleDeleteEntry, handleNewEntry } from '../../utility/dataHandling';
import useSortableData from '../../hooks/useSortableData';
import { useNotification } from '../../hooks/useNotifications';
import EditableField from '../../utility/EditableField';
import SortIndicator from '../../utility/SortIndicator';

const TrackContentsTab = () => {
    const route = '/api/track-contents';
    const contextSingular = 'Track Content';
    const contextPlural = 'Track Contents';
    const dataBseName = 'track_contents';

    const [contextData, setContextData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [categoryNames, setCategoryNames] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [storiesData, setStoriesData] = useState([]);
    const [storyContentData, setStoryContentData] = useState([]);

    const [formattedContents, setFormattedContents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [editActive, setEditActive] = useState(false);
    const { items: sortedContextData, requestSort, sortConfig } = useSortableData(formattedContents);
    const { showNotification } = useNotification();
    const token = localStorage.getItem('token');
    const handleSaveChanges = handleSaveChangesUtil(setFormattedContents, showNotification);

    useEffect(() => {
        const initFetch = async () => {
            try {
                const data = await fetchData(route, token);
                setContextData(data);
                setCategories(data.metaCategories);
                setStoriesData(data.stories);
                setLanguages(data.languages);
                setStoryContentData(data.storyContents);
                setCategoryNames(data.metaNames);
                setIsLoading(false);
            } catch (error) {
                console.error('Fehler beim Laden der ' + contextPlural + ':', error);
                showNotification('Fehler beim Laden der ' + contextPlural + '.', 'danger');
                setIsLoading(false);
            }
        };
        initFetch();
    }, []);

    function transformData() {
        const languageCodeMap = languages.reduce((acc, lang) => {
            acc[lang.id] = lang.language_code;
            return acc;
        }, {});

        const metaNameMap = categoryNames.reduce((acc, name) => {
            if (!acc[name.content_key]) {
                acc[name.content_key] = {};
            }
            acc[name.content_key][languageCodeMap[name.language_id]] = name.content;
            return acc;
        }, {});

        const categoryTypeMap = categories.reduce((acc, category) => {
            acc[category.id] = { type: category.type, has_translations: category.has_translations };
            return acc;
        }, {});

        const result = [];
        let newId = 1;

        storiesData.forEach(story => {
            const storyContentMap = storyContentData.filter(sc => sc.track_id === story.id).reduce((acc, sc) => {
                if (!acc[sc.meta_category_id]) {
                    acc[sc.meta_category_id] = {};
                }
                acc[sc.meta_category_id][languageCodeMap[sc.language_id]] = { content: sc.content, id: sc.content_id, content_id: sc.id};
                return acc;
            }, {});
            categories.forEach(metaCategory => {
                const newEntry = {
                    id: newId++,
                    storyId: story.id,
                    meta_category_id: metaCategory.id,
                    name: metaNameMap[metaCategory.title_key]?.['de_DE'] || null,
                    type: categoryTypeMap[metaCategory.id].type,
                    has_translations: categoryTypeMap[metaCategory.id].has_translations,
                };

                if (categoryTypeMap[metaCategory.id].has_translations) {
                    languages.forEach(lang => {
                        const langCode = lang.language_code;
                        if (langCode !== 'null') {
                            newEntry[`${langCode}_name`] = storyContentMap[metaCategory.id]?.[langCode]?.content || null;
                            newEntry[`${langCode}_id`] = storyContentMap[metaCategory.id]?.[langCode]?.id || null;
                            newEntry[`${langCode}_content_id`] = storyContentMap[metaCategory.id]?.[langCode]?.content_id || null;
                        }
                    });
                } else {
                    newEntry['single_name'] = storyContentMap[metaCategory.id]?.['null']?.content || null;
                    newEntry['single_id'] = storyContentMap[metaCategory.id]?.['null']?.id || null;
                    newEntry['single_content_id'] = storyContentMap[metaCategory.id]?.['null']?.content_id || null;
                }

                result.push(newEntry);
            });
        });

        return result;
    }

    useEffect(() => {
        if (storyContentData) {
            setFormattedContents(transformData());
        }
    }, [storyContentData, categories, languages, categoryNames]);

    const getLanguageIdByCode = (languageCode) => {
        const language = languages.find(lang => lang.language_code === languageCode);
        return language ? language.id : null;
    };

    const renderData = (myData) => {
        if (myData && typeof myData === 'object') {
            if (Array.isArray(myData) && myData.length === 2) {
                return `[${myData[0]}, ${myData[1]}]`;
            } else if (!Array.isArray(myData) && myData.x !== undefined && myData.y !== undefined) {
                return `[${myData.x}, ${myData.y}]`;
            }
        }
        return myData;
    };

    const setValueData = (myData) => {
        if (myData && typeof myData === 'object') {
            if (Array.isArray(myData) && myData.length === 2) {
                return [myData[0], myData[1]];
            } else if (!Array.isArray(myData) && myData.x !== undefined && myData.y !== undefined) {
                return [myData.x, myData.y];
            }
        }
        return myData;
    };

    const getFieldType = (type) => {
        switch (type) {
            case 'geo':
                return 'point';
            case 'numeric':
                return 'float';
            case 'longtext':
                return 'textarea';
            default:
                return 'text';
        }
    };

    function dummy() {
        return
    }

    if (isLoading) {
        return <div><div className="spinner"></div> Lädt... </div>;
    }

    return (
        <div>
            <h2>{contextPlural}</h2>
            Hauptsächlich für Debug Zwecke und zum schnellen Erstellen von Übersetzungen
            <div className="content-right">
                <Button onClick={() => console.log(contextData)}>
                    DEBUG1
                </Button>
                <Button onClick={() => console.log(sortedContextData)}>
                    DEBUG2
                </Button>
            </div>
            <div className="content-right">
                <Button onClick={() => setEditActive(!editActive)}>
                    Bearbeitung {editActive ? 'deaktivieren' : 'aktivieren'}
                </Button>
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th onClick={() => requestSort('id', 'numeric')}>
                            ID
                            <SortIndicator isSorted={sortConfig.key === 'id'} direction={sortConfig.direction} />
                        </th>
                        <th onClick={() => requestSort('storyId', 'numeric')}>
                            StoryId
                            <SortIndicator isSorted={sortConfig.key === 'storyId'} direction={sortConfig.direction} />
                        </th>
                        <th onClick={() => requestSort('name')}>
                            Name
                            <SortIndicator isSorted={sortConfig.key === 'name'} direction={sortConfig.direction} />
                        </th>
                        {languages.filter(lang => lang.language_code !== 'null').map(lang => (
                            <th key={lang.language_code} onClick={() => requestSort(`${lang.language_code}_name`)}>
                                Name {lang.language_code}
                                <SortIndicator isSorted={sortConfig.key === `${lang.language_code}_name`} direction={sortConfig.direction} />
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {sortedContextData.map(lan => (
                        <tr key={lan.id}>
                            <td>{lan.id}</td>
                            <td>{lan.storyId}</td>
                            <td>{lan.name}</td>
                            {lan.has_translations ? (
                                languages.filter(lang => lang.language_code !== 'null').map(lang => (
                                    <td key={lang.language_code} style={lan['single_name'] ? { backgroundColor: 'lightgreen' } : {}}>
                                        {editActive ? (
                                             <>
                                            <EditableField
                                                value={setValueData(lan[`${lang.language_code}_name`]) || ''}
                                                placeholder={lan.name}
                                                ifEmpty="---"
                                                onSave={async (newValue) => {
                                                    if (lan[`${lang.language_code}_name`]) {
                                                        handleSaveChanges(newValue, `${lang.language_code}_name`, lan.id, lan.type+'_contents', 'content', lan[`${lang.language_code}_id`]);
                                                    } else {
                                                        const newContentEntry = await handleNewEntry(lan.type+'_contents', dummy, showNotification, {
                                                            'content': newValue,
                                                        });
                                                        if (newContentEntry?.id) {
                                                            const newStoryContentEntry = await handleNewEntry(dataBseName, dummy, showNotification, {
                                                                'track_id': lan.storyId,
                                                                'meta_category_id': lan.meta_category_id,
                                                                'content_id': newContentEntry.id,
                                                                'language_id': getLanguageIdByCode(lang.language_code)
                                                            });
                                                            newStoryContentEntry['content'] = newValue;
                                                            setStoryContentData(prevContextData => [...prevContextData, newStoryContentEntry]);

                                                        
                                                        }
                                                    }
                                                }}
                                                fieldType={getFieldType(lan.type)}
                                            />
                                            {lan[`${lang.language_code}_name`]&& <Trash onClick={() => { 
                                                //handleDeleteEntry(lan.type+'_contents', lan[`${lang.language_code}_id`], setFormattedContents, showNotification); 
                                                handleDeleteEntry(dataBseName, lan[`${lang.language_code}_content_id`], dummy, showNotification); 
                                                setStoryContentData(prevContextData => prevContextData.filter(item => item.id !== lan[`${lang.language_code}_content_id`]));
                                                
                                            }}/>}
                                           </>
                                        ) : (
                                            renderData(lan[`${lang.language_code}_name`]) || <span className='text-red'>noch kein Wert</span>
                                        )}
                                    </td>
                                ))
                            ) : (
                                <td colSpan={languages.length} style={{ backgroundColor: 'lightgreen' }}>
                                    {editActive ? (
                                        <>
                                        <EditableField
                                        value={setValueData(lan['single_name'])  || ''}
                                        placeholder={lan.name}
                                        ifEmpty="---"

                                        onSave={async (newValue) => {
                                            if (lan['single_name']) {
                                                handleSaveChanges(newValue, 'single_name', lan.id, lan.type+'_contents', 'content',lan['single_id'])
                                            } else {
                                                const newContentEntry = await handleNewEntry(lan.type+'_contents', dummy, showNotification, {
                                                    'content': newValue,
                                                });

                                                if (newContentEntry?.id) {
                                                    const newStoryContentEntry = await handleNewEntry(dataBseName, dummy, showNotification, {
                                                        'track_id': lan.storyId,
                                                        'meta_category_id': lan.meta_category_id,
                                                        'content_id': newContentEntry.id,
                                                        'language_id': 1
                                                    });
                                                    newStoryContentEntry['content'] = newValue;
                                                    setStoryContentData(prevContextData => [...prevContextData, newStoryContentEntry]);
                                                }
                                            }
                                        }
                                    }
                                        fieldType={getFieldType(lan.type)}
                                    />
                                        {lan['single_name'] && <Trash onClick={() => { 
                                            //handleDeleteEntry(lan.type+'_contents', lan['single_id'], setFormattedContents, showNotification); 
                                            handleDeleteEntry(dataBseName, lan['single_content_id'], dummy, showNotification);
                                            setStoryContentData(prevContextData => prevContextData.filter(item => item.id !== lan['single_content_id']));
                                        }}/>}
                                    </>
                                    ) : (
                                        renderData(lan['single_name']) || <span className='text-red'>noch kein Wert</span>
                                    )}
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TrackContentsTab;
