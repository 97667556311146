import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { ReactComponent as Trash } from 'bootstrap-icons/icons/trash.svg';
import { fetchData } from '../../utility/ApiRequests';
import { handleSaveChanges as handleSaveChangesUtil, handleNewEntry, handleDeleteEntry, handleDeleteKey } from '../../utility/dataHandling';
import useSortableData from '../../hooks/useSortableData';
import { useNotification } from '../../hooks/useNotifications';
import EditableField from '../../utility/EditableField';
import SortIndicator from '../../utility/SortIndicator';

const ContentsTab = () => {
    const route = '/api/contents';
    const context_singular = 'Content';
    const context_plural = 'Contents';

    const [contents, setContextData] = useState([]);
    const [languages, setLanguages] = useState({});
    const [formattedContents, setFormattedContents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [editActive, setEditActive] = useState(false);
    const { items: sortedContextData, requestSort, sortConfig } = useSortableData(formattedContents);
    const { showNotification } = useNotification();
    const token = localStorage.getItem('token');
    //const handleSaveChanges = handleSaveChangesUtil(setFormattedContents, showNotification);
    const handleSaveChanges = handleSaveChangesUtil(setContextData, showNotification);

    useEffect(() => {
        const initFetch = async () => {
            try {
                const data = await fetchData(route, token);
                setContextData(data.contents);
                setLanguages(data.languages);
                setIsLoading(false);
            } catch (error) {
                console.error('Fehler beim Laden der ' + context_plural + ':', error);
                showNotification('Fehler beim Laden der ' + context_plural + '.', 'danger');
                setIsLoading(false);
            }
        };
        initFetch();
    }, [route, token, context_plural]);

    useEffect(() => {
        const reformatContents = () => {
            const groupedContents = contents.reduce((acc, item) => {
                const { content_key, language_id, content, id } = item;
                const languageCode = languages[language_id];
                if (!acc[content_key]) {
                    acc[content_key] = { id: acc.length + 1, content_key }; // Fortlaufende ID
                }
                acc[content_key][`${languageCode}_content`] = content;
                acc[content_key][`${languageCode}_id`] = id;
                return acc;
            }, {});

            const transformedData = Object.values(groupedContents).map((item, index) => ({
                ...item,
                id: index + 1,
            }));

            setFormattedContents(transformedData);
        };

        if (contents.length > 0 && Object.keys(languages).length > 0) {
            reformatContents();
        }
    }, [contents]);


    if (isLoading) {
        return <div><div className="spinner"></div> Lädt... </div>;
    }

    const availableLanguages = Object.values(languages).filter(lang => lang !== 'null');
    const getLanguageIdByValue = (value) => {
        return Object.keys(languages).find(key => languages[key] === value);
    };

    return (
        <div>
            <h2>{context_plural}</h2>
            <p>[DEV] - Contents sollen hier nicht gelöscht oder umbenannt oder erstellt (?) werden um die Konsistenz der DB beizubehalten</p>
            <p>[DEV] - TODO: Such/ Filterfunktion für content_keys + Filteroption für Sprachen</p>
            <div className='content-right'>
                <Button className='' onClick={() => setEditActive(!editActive)}> Bearbeitung {editActive ? 'deaktivieren' : 'aktivieren'}</Button>
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th onClick={() => requestSort('content_key')}>
                            content_key
                            <SortIndicator isSorted={sortConfig.key === 'content_key'} direction={sortConfig.direction} />
                        </th>
                        {availableLanguages.map(lang => (
                            <React.Fragment key={lang}>
                                <th onClick={() => requestSort(`${lang}_content`)}>
                                    {lang} Inhalt
                                    <SortIndicator isSorted={sortConfig.key === `${lang}_content`} direction={sortConfig.direction} />
                                </th>
                            </React.Fragment>
                        ))}
                        {editActive && <th>löschen</th>}
                    </tr>
                </thead>
                <tbody>
                    {sortedContextData.map(item => (
                        <tr key={item.id}>
                            <td>{item.content_key}</td>
                            {availableLanguages.map(lang => (
                                <React.Fragment key={lang}>
                                    <td>
                                    {editActive ? (
                                        <EditableField
                                            value={item[`${lang}_content`] || ''}
                                            minValue="3"
                                            maxValue="100"
                                            size="15"
                                            placeholder={`${item.content_key}`}
                                            ifEmpty="---"
                                            onSave={(newValue) => item[`${lang}_content`] 
                                                ? handleSaveChanges(newValue, 'content', item[`${lang}_id`], 'contents')
                                                : handleNewEntry('contents', setContextData, showNotification, {
                                                    'content_key': item['content_key'],
                                                    'content': newValue,
                                                    'language_id': getLanguageIdByValue(lang)
                                                })
                                            }
                                            fieldType="text"
                                        />
                                    ) : (
                                        item[`${lang}_content`] ? item[`${lang}_content`] : <span className='text-red'>noch kein Wert</span>
                                    )}
                                    </td>
                                </React.Fragment>
                            ))}
                            {editActive && (
                                <td>
                                    <Trash onClick={() => handleDeleteKey('contents','content_key',item.content_key,setContextData, showNotification)}></Trash>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>

        </div>
    );
};

export default ContentsTab;