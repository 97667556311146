import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { ReactComponent as Trash } from 'bootstrap-icons/icons/trash.svg';
import { fetchData } from '../../utility/ApiRequests';
import { handleSaveChanges as handleSaveChangesUtil, handleDeleteEntry, handleNewEntry } from '../../utility/dataHandling';
import useSortableData from '../../hooks/useSortableData';
import { useNotification } from '../../hooks/useNotifications';
import { createUniqueSlug } from '../../utility/Slug';
import EditableField from '../../utility/EditableField';
import SortIndicator from '../../utility/SortIndicator';

const CitiesTab = () => {
    const route = '/api/cities';
    const contextSingular = 'Stadt';
    const contextPlural = 'Städte';
    const contentSlugPrefix = 'city_';
    const dataBseName='cities';

    const [contextData, setContextData] = useState([]);
    const [cities, setCities] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [contentData, setContentData] = useState([]);
    const [formattedContents, setFormattedContents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [editActive, setEditActive] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [newCityName, setNewCityName] = useState('');
    const { items: sortedContextData, requestSort, sortConfig } = useSortableData(formattedContents);
    const { showNotification } = useNotification();
    const token = localStorage.getItem('token');
    const handleSaveChanges = handleSaveChangesUtil(setFormattedContents, showNotification);

    useEffect(() => {
        const initFetch = async () => {
            try {
                const data = await fetchData(route, token);
                setContextData(data);
                setCities(data.cities);
                setContentData(data.contents);
                setLanguages(data.languages);
                setIsLoading(false);
                //console.log(data);
            } catch (error) {
                console.error('Fehler beim Laden der ' + contextPlural + ':', error);
                showNotification('Fehler beim Laden der ' + contextPlural + '.', 'danger');
                setIsLoading(false);
            }
        };
        initFetch();
    }, [route, token, contextPlural, showNotification]);

    const transformData = (data) => {
        if (!data || !Array.isArray(data.cities) || !Array.isArray(data.contents) || !Array.isArray(data.languages)) {
            console.error('Invalid data structure:', data);
            throw new Error('Invalid data structure');
        }

        const { cities, contents, languages } = data;

        const languageCodeToIdMap = languages.reduce((acc, language) => {
            acc[language.language_code] = language.id;
            return acc;
        }, {});

        const contentMap = contents.reduce((acc, content) => {
            if (!acc[content.content_key]) {
                acc[content.content_key] = {};
            }
            const language = languages.find(lang => lang.id === content.language_id);
            if (language) {
                const languageCode = language.language_code;
                acc[content.content_key][languageCode] = {
                    content: content.content,
                    id: content.id
                };
            }
            return acc;
        }, {});

        const result = cities.map(city => {
            const cityData = {
                id: city.id,
                title_key: city.title_key,
                illustration: city.illustration,
                file_url:city.file_url,
                location: city.location && [city.location.x, city.location.y]
            };

            Object.keys(languageCodeToIdMap).forEach(languageCode => {
                if (contentMap[city.title_key] && contentMap[city.title_key][languageCode]) {
                    cityData[`${languageCode}_name`] = contentMap[city.title_key][languageCode].content;
                    cityData[`${languageCode}_id`] = contentMap[city.title_key][languageCode].id;
                }
            });

            return cityData;
        });

        return result;
    };

    useEffect(() => {
        if (contextData && contextData.cities && contextData.contents && contextData.languages) {
            setFormattedContents(transformData(contextData));
        }
    }, [contextData]);

    const handleAddNewCity = () => {
        setIsAdding(true);
    };

    const handleCancelAdd = () => {
        setIsAdding(false);
        setNewCityName('');
    };

    const handleSaveNewCity = () => {
        // Logik zum Speichern der neuen Stadt
        const existingSlugs = cities.map(city => city.title_key);
        const slug = createUniqueSlug(newCityName, existingSlugs, contentSlugPrefix);
        //console.log('Speichern der neuen Stadt:', newCityName);
        handleNewEntry(dataBseName, setFormattedContents, showNotification, {'title_key': slug});
    
        // WICHTIG: die Sprache de_DE muss existieren
        const languageId = getLanguageIdByCode('de_DE');
        if (languageId) {
            handleNewEntry('contents', setFormattedContents, showNotification, {
                'content_key': slug,
                'content': newCityName,
                'language_id': languageId
            });
        } else {
            showNotification('Sprache de_DE existiert nicht.', 'danger');
        }
    
        setIsAdding(false);
        setNewCityName('');
    };

    const getLanguageIdByCode = (languageCode) => {
        const language = languages.find(lang => lang.language_code === languageCode);
        return language ? language.id : null;
    };

    if (isLoading) {
        return <div><div className="spinner"></div> Lädt... </div>;
    }

    return (
        <div>
            <h2>{contextPlural}</h2>
            <div className="content-right">
                <Button onClick={() => setEditActive(!editActive)}>
                    Bearbeitung {editActive ? 'deaktivieren' : 'aktivieren'}
                </Button>
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th onClick={() => requestSort('id', 'numeric')}>
                            ID
                            <SortIndicator isSorted={sortConfig.key === 'id'} direction={sortConfig.direction} />
                        </th>
                        <th onClick={() => requestSort('title_key')}>
                            Title Key
                            <SortIndicator isSorted={sortConfig.key === 'title_key'} direction={sortConfig.direction} />
                        </th>
                        {languages.filter(lang => lang.language_code !== 'null').map(lang => (
                            <th key={lang.language_code} onClick={() => requestSort(`${lang.language_code}_name`)}>
                                Name {lang.language_code}
                                <SortIndicator isSorted={sortConfig.key === `${lang.language_code}_name`} direction={sortConfig.direction} />
                            </th>
                        ))}
                        <th>Location</th>
                        <th>Illustration</th>
                        {editActive &&
                            <th>Löschen</th>
                        }
                    </tr>
                </thead>
                <tbody>
                    {sortedContextData.map(lan => (
                        <tr key={lan.id}>
                            <td>{lan.id}</td>
                            <td>
                                    {lan.title_key}
                            </td>
                            {languages.filter(lang => lang.language_code !== 'null').map(lang => (
                                <td key={lang.language_code}>
                                {editActive ? (
                                    <EditableField
                                        value={lan[`${lang.language_code}_name`] || ''}
                                        minValue="3"
                                        maxValue="100"
                                        size="15"
                                        onSave={(newValue) => lan[`${lang.language_code}_name`]
                                            ? handleSaveChanges(newValue, `${lang.language_code}_name`, lan.id, 'contents', 'content', lan[`${lang.language_code}_id`])
                                            : handleNewEntry('contents', setFormattedContents, showNotification, {
                                                'content_key': lan.title_key,
                                                'content': newValue,
                                                'language_id': getLanguageIdByCode(lang.language_code)
                                            })
                                        }
                                        fieldType="text"
                                    />
                                ) : (
                                    lan[`${lang.language_code}_name`] ? (
                                        lan[`${lang.language_code}_name`]
                                    ) : (
                                        <span className='text-red'>noch kein Wert</span>
                                    )
                                )}
                            </td>
                            ))}
                            <td>
     
                                {editActive ?(
                                    <EditableField
                                        value={lan.location ? lan.location : [0,0]}
                                        onSave={(newValue) => handleSaveChanges(newValue, 'location', lan.id, dataBseName)}
                                        fieldType="point"
                                    />)
                                    :(
                                    lan.location ? `[${lan.location[0]}, ${lan.location[1]}]` : <span className='text-red'>noch kein Wert</span>)
                                }
                            </td>
                            <td>

                                
                                {editActive ?
                                    <EditableField
                                        value={lan.file_url}
                                        fileId={lan.id}
                                        height="1rem"
                                        width="1.5rem"
                                        fileKey={lan.illustration}
                                        fileDestination="cities"
                                        fileUsageName="illustration"
                                        onSave={(newValue) => {
                                            //console.log(newValue.illustration);
                                            //handleSaveChanges();
                                        }}
                                        fieldType="image"
                                    />
                                    :
                                    <img src={lan.file_url}
                                        style={{ width: "1.5rem", height: "1rem" }} />
                                }
                            </td>
                            {editActive &&
                                <td>
                                    <Trash
                                        onClick={() => handleDeleteEntry(dataBseName, lan.id, setFormattedContents, showNotification)}
                                    />
                                </td>
                            }
                        </tr>
                    ))}
                </tbody>
            </table>

            {!isAdding && (
                <Button onClick={handleAddNewCity}>Neue {contextSingular}hinzufügen</Button>
            )}
            {isAdding && (
                <div>
                    <input
                        type="text"
                        value={newCityName}
                        onChange={(e) => setNewCityName(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && handleSaveNewCity()}
                        placeholder={`${contextSingular}name auf Deutsch`}
                    />
                    <Button onClick={handleSaveNewCity}>Speichern</Button>
                    <Button onClick={handleCancelAdd}>Abbrechen</Button>
                </div>
            )}
        </div>
    );
};

export default CitiesTab;
