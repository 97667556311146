import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { ReactComponent as Trash } from 'bootstrap-icons/icons/trash.svg';
import { fetchData } from '../../utility/ApiRequests';
import { handleSaveChanges as handleSaveChangesUtil, handleDeleteEntry, handleNewEntry } from '../../utility/dataHandling';
import useSortableData from '../../hooks/useSortableData';
import { useNotification } from '../../hooks/useNotifications';
import EditableField from '../../utility/EditableField';
import SortIndicator from '../../utility/SortIndicator';
import { createUniqueSlug } from '../../utility/Slug';
import { selectableMetaDataTypes } from '../../utility/DropdownOptions';

const StoryMetasTab = () => {
    const route = '/api/story-meta-categories';
    const contextSingular = 'Story Meta';
    const contextPlural = 'Story Metas';
    const contentSlugPrefix = 'storyMeta_';
    const dataBseName='story_meta_categories';

    const [contextData, setContextData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [contentData, setContentData] = useState([]);
    const [formattedContents, setFormattedContents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [editActive, setEditActive] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [newEntryName, setNewEntryName] = useState('');
    const [newEntryTranslatable, setNewEntryTranslatable] = useState(true);
    const [newEntryType, setNewEntryType] = useState(selectableMetaDataTypes()[0].value);
    const { items: sortedContextData, requestSort, sortConfig } = useSortableData(formattedContents);
    const { showNotification } = useNotification();
    const token = localStorage.getItem('token');
    const handleSaveChanges = handleSaveChangesUtil(setFormattedContents, showNotification);

    useEffect(() => {
        const initFetch = async () => {
            try {
                const data = await fetchData(route, token);
                setContextData(data);
                setCategories(data.metaTypes);
                setContentData(data.contents);
                setLanguages(data.languages);
                setIsLoading(false);
                //console.log(data);
            } catch (error) {
                console.error('Fehler beim Laden der ' + contextPlural + ':', error);
                showNotification('Fehler beim Laden der ' + contextPlural + '.', 'danger');
                setIsLoading(false);
            }
        };
        initFetch();
    }, [route, token, contextPlural, showNotification]);

    const transformData = (data) => {
        if (!data || !Array.isArray(data.metaTypes) || !Array.isArray(data.contents) || !Array.isArray(data.languages)) {
            console.error('Invalid data structure:', data);
            throw new Error('Invalid data structure');
        }

        const { metaTypes, contents, languages } = data;

        const languageCodeToIdMap = languages.reduce((acc, language) => {
            acc[language.language_code] = language.id;
            return acc;
        }, {});

        const contentMap = contents.reduce((acc, content) => {
            if (!acc[content.content_key]) {
                acc[content.content_key] = {};
            }
            const language = languages.find(lang => lang.id === content.language_id);
            if (language) {
                const languageCode = language.language_code;
                acc[content.content_key][languageCode] = {
                    content: content.content,
                    id: content.id
                };
            }
            return acc;
        }, {});

        const result = metaTypes.map(data => {
            const metaTypesData = {
                id: data.id,
                title_key: data.title_key,
                has_translations: data.has_translations,
                type: data.type,
            };

            Object.keys(languageCodeToIdMap).forEach(languageCode => {
                if (contentMap[data.title_key] && contentMap[data.title_key][languageCode]) {
                    metaTypesData[`${languageCode}_name`] = contentMap[data.title_key][languageCode].content;
                    metaTypesData[`${languageCode}_id`] = contentMap[data.title_key][languageCode].id;
                }
            });

            return metaTypesData;
        });

        return result;
    };

    useEffect(() => {
        if (contextData && contextData.metaTypes && contextData.contents && contextData.languages) {
            setFormattedContents(transformData(contextData));
        }
    }, [contextData]);

    const handleAddNewEntry = () => {
        setIsAdding(true);
    };

    const handleCancelAdd = () => {
        setIsAdding(false);
        setNewEntryName('');
        setNewEntryType(selectableMetaDataTypes()[0].value);
    };


    const handleSaveNewEntry = () => {
        // Logik zum Speichern der neuen Stadt
        const existingSlugs = categories.map(data => data.title_key);
        const slug = createUniqueSlug(newEntryName, existingSlugs, contentSlugPrefix);

    
        // WICHTIG: die Sprache de_DE muss existieren
        const languageId = getLanguageIdByCode('de_DE');
        if (languageId) {
            handleNewEntry('contents', setFormattedContents, showNotification, {
                'content_key': slug,
                'content': newEntryName,
                'language_id': languageId,
            });
            handleNewEntry(dataBseName, setCategories, showNotification, {
                'title_key': slug,
                'type': newEntryType,
                'has_translations': newEntryTranslatable ? 1 : 0,
                //'language_id': languageId
            });
        } else {
            showNotification('Sprache de_DE existiert nicht.', 'danger');
        }
    
        setIsAdding(false);
        setNewEntryName('');
    };

    const getLanguageIdByCode = (languageCode) => {
        const language = languages.find(lang => lang.language_code === languageCode);
        return language ? language.id : null;
    };

    if (isLoading) {
        return <div><div className="spinner"></div> Lädt... </div>;
    }

    return (
        <div>
            <h2>{contextPlural}</h2>
            storyMeta mit ID 1 sollte Titel sein
            <div className="content-right">
            <Button onClick={() => console.log(console.log(contextData))}>
                    DEBUG1
                </Button>
                <Button onClick={() => console.log(console.log(formattedContents))}>
                    DEBUG2
                </Button>
            </div>
            <div className="content-right">
                <Button onClick={() => setEditActive(!editActive)}>
                    Bearbeitung {editActive ? 'deaktivieren' : 'aktivieren'}
                </Button>
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th onClick={() => requestSort('id', 'numeric')}>
                            ID
                            <SortIndicator isSorted={sortConfig.key === 'id'} direction={sortConfig.direction} />
                        </th>
                        <th onClick={() => requestSort('title_key')}>
                            Title Key
                            <SortIndicator isSorted={sortConfig.key === 'title_key'} direction={sortConfig.direction} />
                        </th>
                        {languages.filter(lang => lang.language_code !== 'null').map(lang => (
                            <th key={lang.language_code} onClick={() => requestSort(`${lang.language_code}_name`)}>
                                Name {lang.language_code}
                                <SortIndicator isSorted={sortConfig.key === `${lang.language_code}_name`} direction={sortConfig.direction} />
                            </th>
                        ))}
                        <th onClick={() => requestSort('has_translations', 'numeric')}>
                            Übersetzbar
                            <SortIndicator isSorted={sortConfig.key === 'has_translations'} direction={sortConfig.direction} />
                        </th>
                        <th onClick={() => requestSort('type')}>
                            type
                            <SortIndicator isSorted={sortConfig.key === 'type'} direction={sortConfig.direction} />
                        </th>

                        {editActive &&
                            <th>Löschen</th>
                        }
                    </tr>
                </thead>
                <tbody>
                    {sortedContextData.map(lan => (
                        <tr key={lan.id}>
                            <td>{lan.id}</td>
                            <td>
                                    {lan.title_key}
                            </td>
                            {languages.filter(lang => lang.language_code !== 'null').map(lang => (
                                <td key={lang.language_code}>
                                {editActive ? (
                                    <EditableField
                                        value={lan[`${lang.language_code}_name`] || ''}
                                        minValue="3"
                                        maxValue="100"
                                        size="15"
                                        placeholder={lan.title_key}
                                        ifEmpty="---"
                                        onSave={(newValue) => lan[`${lang.language_code}_name`]
                                            ? handleSaveChanges(newValue, `${lang.language_code}_name`, lan.id, 'contents', 'content', lan[`${lang.language_code}_id`])
                                            : handleNewEntry('contents', setFormattedContents, showNotification, {
                                                'content_key': lan.title_key,
                                                'content': newValue,
                                                'language_id': getLanguageIdByCode(lang.language_code)
                                            })
                                        }
                                        fieldType="text"
                                    />
                                ) : (
                                    lan[`${lang.language_code}_name`] ? (
                                        lan[`${lang.language_code}_name`]
                                    ) : (
                                        <span className='text-red'>noch kein Wert</span>
                                    )
                                )}
                            </td>
                            ))}
                            <td>
                                {lan.has_translations === 0 ? 'nein' : 'ja'}
                            </td>
                            <td>
                                {lan.type}
                            </td>
                            
                            {editActive &&
                                <td>
                                    <Trash
                                        onClick={() => handleDeleteEntry(dataBseName, lan.id, setFormattedContents, showNotification)}
                                    />
                                </td>
                            }
                        </tr>
                    ))}
                </tbody>
            </table>

            {!isAdding && (
                <Button onClick={handleAddNewEntry}>Neue {contextSingular} hinzufügen</Button>
            )}
            {isAdding && (
                <div><p><strong>WICHTIG</strong>: der Datentyp kann später nicht editiert werden</p>
                <p>Name
                    <input className='ml-1'
                        type="text"
                        value={newEntryName}
                        onChange={(e) => setNewEntryName(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && handleSaveNewEntry()}
                        placeholder={`${contextSingular}name auf Deutsch`}
                    />
                    </p>
                    <p>Übersetzbar
                    <input className='ml-1'
                        type="checkbox"
                        checked={newEntryTranslatable}
                        onChange={(e) => setNewEntryTranslatable(e.target.checked )}
                        onKeyPress={(e) => e.key === 'Enter' && handleSaveNewEntry()}
                    /> 
                    </p>
                    <p>Datentyp
                    <select className='ml-1'
                        value={newEntryType}
                        onChange={(e) => setNewEntryType(e.target.value)}
                    >
                        {selectableMetaDataTypes().map(type => (
                            <option key={type.value} value={type.value}>{type.label}</option>
                        ))}
                    </select>
                    </p><p>
                    <Button onClick={handleSaveNewEntry}>Speichern</Button>
                    <Button className='ml-1' onClick={handleCancelAdd}>Abbrechen</Button>
                    </p>
                </div>
            )}
        </div>
    );
};

export default StoryMetasTab;
