// src/components/StoryDetails.js
import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import StoryCategories from './detailsComponets/StoryCategories';
import StoryCities from './detailsComponets/StoryCities';
import StoryTracks from './detailsComponets/StoryTracks';
import StoryMetas from './detailsComponets/StoryMetas';
import StoryQuests from './detailsComponets/StoryQuests';
import {transformData, getLanguageIdByCode} from './detailsComponets/StoryDetailHelpers';
import { fetchData } from '../../utility/ApiRequests';
import useSortableData from '../../hooks/useSortableData';
import { useNotification } from '../../hooks/useNotifications';
import { handleSaveChanges as handleSaveChangesUtil, handleDeleteEntry, handleNewEntry, deleteEntry } from '../../utility/dataHandling';

const StoryDetails = () => {
    const { storyId } = useParams();
    const route = '/api/stories/' + storyId;
    const contextPlural = 'Story Details';

    const [contextData, setContextData] = useState([]);
    const [metaCategories, setMetaCategories] = useState([]);
    const [categoryNames, setCategoryNames] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [storiesData, setStoriesData] = useState([]);
    const [storyContentData, setStoryContentData] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState('de_DE');
    const [cities, setCities] = useState([]);
    const [storyCities, setStoryCities] = useState([]);
    const [categories, setCategories] = useState([]);
    const [storyCategories, setStoryCategories] = useState([]);
    const [tracks, setTracks] = useState([]);
    const [trackContents, setTrackContents] = useState([]);
    const [quests, setQuests] = useState([]);
    const [questContents, setQuestContents] = useState([]);
    //const [trackOrder, setTrackOrder] = useState([]);
    const [trackOrder, setTrackOrder] = useState(tracks.map((track, index) => ({ ...track, order: index + 1 })));
    const [questOrder, setQuestOrder] = useState(quests.map((quest, index) => ({ ...quest, order: index + 1 })));
    const [isLoading, setIsLoading] = useState(true);
    const [editActive, setEditActive] = useState(false);


    const [formattedContents, setFormattedContents] = useState([]);
    const { showNotification } = useNotification();
    const token = localStorage.getItem('token');
    //const { items: sortedContextData, requestSort, sortConfig } = useSortableData([]);
    const { items: sortedContextData, requestSort, sortConfig } = useSortableData(formattedContents);
    const handleSaveChanges = handleSaveChangesUtil(setFormattedContents, showNotification);

    useEffect(() => {
        const initFetch = async () => {
            try {
                const data = await fetchData(route, token);
                setContextData(data);
                setMetaCategories(data.metaCategories);
                setStoriesData(data.stories);
                setLanguages(data.languages);
                setStoryContentData(data.storyContents);
                setCategoryNames(data.metaNames);
                setCities(data.cities);
                setStoryCities(data.storyCities);
                setCategories(data.categories);
                setStoryCategories(data.storyCategories);
                setTracks(data.tracks);
                setTrackContents(data.trackContents);
                setQuests(data.quests);
                setQuestContents(data.questContents);
                setIsLoading(false);
            } catch (error) {
                console.error('Fehler beim Laden der ' + contextPlural + ':', error);
                showNotification('Fehler beim Laden der ' + contextPlural + '.', 'danger');
                setIsLoading(false);
            }
        };
        initFetch();
    }, []);

    useEffect(() => {
        if (storyContentData) {
            setFormattedContents(transformData(languages, categoryNames, metaCategories, storiesData, storyContentData));
        }
    }, [languages, storyContentData]);

    if (isLoading) {
        return <div><div className="spinner"></div> Lädt... </div>;
    }

    if (contextData.length === 0) {
        return <div>keine Story gefunden</div>;
    }

    return (
        <div>
            <h2>{contextPlural}</h2>
            <div className="content-right">
                <Button onClick={() => setEditActive(!editActive)}>
                    Bearbeitung {editActive ? 'deaktivieren' : 'aktivieren'}
                </Button>
            </div>
            <Form.Group controlId="languageSelect">
                <Form.Label>Sprache auswählen</Form.Label>
                <Form.Control className="w-8" as="select" value={selectedLanguage} onChange={(e) => setSelectedLanguage(e.target.value)}>
                    {languages.filter(lang => lang.language_code !== 'null').map(lang => (
                        <option key={lang.id} value={lang.language_code}>
                            {lang.language_name}
                        </option>
                    ))}
                </Form.Control>
            </Form.Group>

            <StoryMetas
                sortedContextData={sortedContextData}
                requestSort={requestSort}
                sortConfig={sortConfig}
                metaCategories={metaCategories}
                selectedLanguage={selectedLanguage}
                languages={languages}
                setFormattedContents={setFormattedContents}
                handleSaveChanges={handleSaveChanges}
                storyContentData={storyContentData}
                setStoryContentData={setStoryContentData}
                editActive={editActive}
                showNotification={showNotification}
                getLanguageIdByCode={getLanguageIdByCode}
            />

            <StoryCities
                storyCities={storyCities}
                setStoryCities={setStoryCities}
                cities={cities}
                storyId={storyId}
                showNotification={showNotification}
            />

            <StoryCategories
                storyCategories={storyCategories}
                setStoryCategories={setStoryCategories}
                categories={categories}
                storyId={storyId}
                showNotification={showNotification}
            />

            <StoryQuests
                questOrder={questOrder}
                setQuestOrder={setQuestOrder}
                quests={quests}
                setQuests={setQuests}
                questContents={questContents}
                setQuestContents={setQuestContents}
                languages={languages}
                storyId={storyId}
                showNotification={showNotification}
                handleSaveChanges={handleSaveChanges}
                selectedLanguage={selectedLanguage}
            />

            <StoryTracks
                trackOrder={trackOrder}
                setTrackOrder={setTrackOrder}
                tracks={tracks}
                setTracks={setTracks}
                trackContents={trackContents}
                setTrackContents={setTrackContents}
                languages={languages}
                storyId={storyId}
                showNotification={showNotification}
                handleSaveChanges={handleSaveChanges}
                selectedLanguage={selectedLanguage}
            />
        </div>
    );
};

export default StoryDetails;
