// dataHandling.js
import axios from 'axios';

const token = localStorage.getItem('token');

export const handleSaveChanges = (setContextData, showNotification) => (newValue, fieldNameArray, tableKey, database = null, fieldNameDb = null, dbKey = null) => {
    //console.log(' newValue: ' + newValue +' fieldNameArray: ' + fieldNameArray + '  tableKey: ' + tableKey + '  database: ' + database + '  fieldNameDb: ' + fieldNameDb + '  dbKey: '+dbKey);
    if (typeof newValue === 'object' && newValue !== null && !Array.isArray(newValue)) {
        Object.entries(newValue).forEach(([field, value]) => {
            updateData(setContextData, showNotification, value, field, tableKey, database, fieldNameDb, dbKey);
        });
    } else {
        updateData(setContextData, showNotification, newValue, fieldNameArray, tableKey, database, fieldNameDb, dbKey);
    }
};

export const updateData = (setContextData, showNotification, newValue, fieldNameArray, tableKey, database, fieldNameDb = null, dbKey=null) => {

    if (newValue === '') {
        showNotification('Feld darf nicht leer sein', 'warning');
        return;
    }
    if (!dbKey){
        dbKey=tableKey;
    }
    if (!fieldNameDb){
        fieldNameDb=fieldNameArray 
    }

    setContextData(prevContextData => {
        return prevContextData.map(contextData => {
            if (contextData.id === tableKey) {
                if (database) {
                    updateDb(database, dbKey, fieldNameDb, newValue);
                }
                //console.log({ ...contextData, [fieldNameArray]: newValue });
                return { ...contextData, [fieldNameArray]: newValue.toString() };  // newValue.toString() damit der enentuelle numerische Wert 0 zu '0' gewandelt und angezeigt wird TODO: Numerische 0 Werte für Ediatable Fioelds zulassen statt emptyValue anzeigen.
            }
            return contextData;
        });
    });
};

export const updateDb = async (database, dataKey, fieldName, value) => {
    if (!database || !dataKey || !fieldName || (value === undefined || value === null || value === '')) { // der numerisch Wert '0' ist erlaubt
        console.error("Invalid input: ", { database, dataKey, fieldName, value });
        return;
    }

    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append("database", database);
    formData.append("key", dataKey);
    formData.append("name", fieldName);
    formData.append("value", value);

    try {
        const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/update`, formData, {
            headers: {
                'authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        if (response.status === 200) {
            return true;
            // showNotification('Änderungen gespeichert', 'success');
        } else {
            console.error('Fehler beim Speichern der Daten');
            return false;
        }
    } catch (error) {
        console.error("Upload Fehler: ", error.message);
        //alert(error.message);
        return false;
    }
};

// Andere exportierte Funktionen bleiben unverändert
export const newEntry = async (database, showNotification, data = null) => {
    if (!database) {
        console.error("Invalid input: ", { database });
        return;
    }

    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append("database", database);
    formData.append("data", JSON.stringify(data));

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/add`, formData, {
            headers: {
                'authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        if (response.status === 200) {
            return response.data.entry;
        } else {
            console.error('Fehler beim Erstellen des neuen Eintrags');
            return null;
        }
    } catch (error) {
        if (error.response && error.response.status === 400 && error.response.data.message === 'Eintrag bereits vorhanden.') {
            showNotification('Eintrag bereits vorhanden.');  
        } else {
            console.error("Upload Fehler: ", error.message);
            showNotification(error.message);
        }
        return null;
    }
};

export const deleteEntry = async (database, id) => {
    if (!database || !id) {
        console.error("Invalid input: ", { database });
        return;
    }

    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append("database", database);
    formData.append("key", id);

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/delete`, formData, {
            headers: {
                'authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        if (response.status === 200) {
            return true;
        } else {
            console.error('Fehler beim Löschen des Eintrags');
            return false;
        }
    } catch (error) {
        console.error("Delete Fehler: ", error.message);
        //alert(error.message);
    }
};

export const deleteKey = async (database, keyName, keyValue) => {
    if (!database || !keyName || !keyValue) {
        console.error("Invalid input: ", { database, keyName, keyValue });
        return;
    }

    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append("database", database);
    formData.append("keyname", keyName);
    formData.append("keyvalue", keyValue);

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/deleteKey`, formData, {
            headers: {
                'authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        if (response.status === 200) {
            return true;
        } else {
            console.error('Fehler beim Löschen des Eintrags');
            return false;
        }
    } catch (error) {
        console.error("Delete Fehler: ", error.message);
        //alert(error.message);
    }
};

export const handleNewEntry = async (database, setContextData, showNotification, data = null) => {
    const newEntryData = await newEntry(database, showNotification, data);
    if (newEntryData) {
        setContextData(prevContextData => [...prevContextData, newEntryData]);
        showNotification('Eintrag erfolgreich erstellt.', 'success');
        return newEntryData;
    } else {
        // showNotification('Fehler beim Erstellen des neuen Eintrags', 'danger');
    }
};



export const handleDeleteEntry = async (database, key, setContextData, showNotification) => {
    const deleteData = await deleteEntry(database, key, showNotification);
    if (deleteData) {
        setContextData(prevContextData => prevContextData.filter(item => item.id !== key));
        showNotification('Eintrag erfolgreich gelöscht.', 'success');
        return true;
    } else {
        showNotification('Fehler beim Löschen des Eintrags', 'danger');
        return false;
    }
};

export const handleDeleteKey = async (database, keyName, keyValue, setContextData, showNotification) => {
    try {
        const deleteData = await deleteKey(database, keyName, keyValue);
        if (deleteData) {
            setContextData(prevContextData => prevContextData.filter(item => item[keyName] !== keyValue));
            showNotification('Eintrag erfolgreich gelöscht.', 'success');
        } else {
            showNotification('Fehler beim Löschen des Eintrags', 'danger');
        }
    } catch (error) {
        console.error('Fehler beim Löschen des Eintrags:', error);
        showNotification('Fehler beim Löschen des Eintrags', 'danger');
    }
};

export const uploadFile = async (file, fileId, fileDestination, fileUsageName, fileKey) => {
    const token = localStorage.getItem('token');
    let fileType;
    if (fileUsageName==='flag' || fileUsageName==='illustration'){
        fileType='image';
    } else {
        fileType=fileUsageName;
    }
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", fileType);
    formData.append("id", fileId);
    formData.append("fileDestination", fileDestination);
    formData.append("fileUsageName", fileUsageName);
    formData.append("oldKey", fileKey);

    return axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/upload`, formData, {
        headers: {
            'authorization': `Bearer ${token}`,
        },
    });
};


export const updateElementPositionsInDB = async (elements, type) => {
    let apiEndpoint;
    let contextStr;
    if (type ==="tracks"){
        apiEndpoint = '/api/updateTrackPositions';
        contextStr = 'Track'
    }
    if (type ==="quests"){
        apiEndpoint = '/api/updateQuestPositions';
        contextStr = 'Quest'
    }
    if (type ==="puzzles"){
        apiEndpoint = '/api/updatePuzzlePositions';
        contextStr = 'Puzzle'
    }

    // Nur id und position senden
    const elementToUpdate = elements.map(element => ({
        id: element.id,
        position: element.position,
    }));

    // Anfrage an das Backend senden
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}${apiEndpoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(elementToUpdate),
        });
        if (response.ok) {
            return await response.json();
        } else {
            throw new Error('Fehler beim Aktualisieren der ' + contextStr + '-Positionen');
        }
    } catch (error) {
        console.error('Fehler beim Aktualisieren der ' + contextStr + '-Positionen:', error);
        throw error;
    }
};

