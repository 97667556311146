import React, { useEffect, useContext, useState } from 'react';
import { AuthContext } from './AuthContext';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import CookieConsentPopup from '../common/overlays/CookieConsentOverlay'; // Importieren der Popup-Komponente

const UserManager = () => {
  const { login, logout, allowCoockies, coockies } = useContext(AuthContext);

  useEffect(() => {
    if (localStorage.getItem('userId')){
      allowCoockies();
    }
  },[]);

  useEffect(() => {
    if (!coockies){
    const token = localStorage.getItem('token');
    if (token) {
      allowCoockies();
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Math.floor(Date.now() / 1000);
        const expiryTime = decodedToken.exp;
        if (decodedToken && decodedToken.username && expiryTime >= currentTime) {
          const userId = decodedToken.userId;
          localStorage.setItem('userId', userId);
          axios.post(`${process.env.REACT_APP_API_BASE_URL}/user`, { userId });
          login({ username: decodedToken.username, userid: userId, userrole: decodedToken.role}, expiryTime);

        } else {
          localStorage.removeItem('token');
          logout();
          checkAndHandleUserId(); 
        }
      } catch (error) {
        console.error('Fehler beim Dekodieren des Tokens:', error);
        localStorage.removeItem('token');
        logout();
        checkAndHandleUserId(); 
      }
    } else {
      checkAndHandleUserId(); // Kein Token, also Prüfung der userID userId
    }}
  }, [coockies]);

  const checkAndHandleUserId = () => {
    const userId = localStorage.getItem('userId');
    // NEU: kein serverseitiges erstellen non Nutzern ohne Login
    if (!userId) {
      //setShowCookiePopup(true);
    } else {
          allowCoockies();
          localStorage.setItem('userId', userId);
      }
  };

  const handleAcceptCookies = () => {
          localStorage.setItem('userId','empty');
          allowCoockies();
          //setShowCookiePopup(false);
  };

// deactivate Cookiebanner for now

  return !coockies ? (
   null  //  <CookieConsentPopup onAccept={handleAcceptCookies} />
  ) : null;
};

export default UserManager;