export const selectableMetaDataTypes = () => {
    return [
            {value: 'text', label: 'text'}, 
            {value: 'longtext', label: 'longtext'},
            {value: 'numeric', label: 'numeric'},
            {value: 'date', label: 'date'},
            {value: 'image', label: 'image'},
            {value: 'audio', label: 'audio'},
            {value: 'geo', label: 'geo'},
    ]
};

export const storyStateOptiones = () => {
    return [
            {value: 'published', label: 'published'}, 
            {value: 'private', label: 'private'},
    ]
};