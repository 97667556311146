import React from 'react';
import { Button, Form } from 'react-bootstrap';

const Password = ({ password, setPassword, validatePassword, isPasswordValid, passwordAttempted }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    validatePassword(password, () => setPassword(''));
  };

  return (
    <div className='title-page'><h2>Passwort</h2>
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="password">
        
        <Form.Control
          type="text"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Geben Sie das Passwort ein"
        />
      </Form.Group>
      <Button variant="primary" type="submit">
        Einreichen
      </Button>
      {passwordAttempted && !isPasswordValid && <p>Falsches Passwort, bitte erneut versuchen.</p>}
      {passwordAttempted && isPasswordValid && <p>Passwort korrekt, Track freigeschaltet!</p>}
    </Form>
    </div>
  );
};

export default Password;
