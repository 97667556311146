import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faLanguage, faTrash, faFileAlt, faCity, faTags, faInfoCircle, faUsers, faFileCode, faDatabase, faPuzzlePiece, faMotorcycle } from '@fortawesome/free-solid-svg-icons';

const AdminMainPage = () => {
  const [expanded, setExpanded] = useState(false);

  const menuItems = [
    { href: "/admin/stories", label: "Stories", icon: faBook },
    { href: "/admin/languages", label: "Sprachen", icon: faLanguage },
    { href: "/admin/deletionQueue", label: "Dateien zum löschen", icon: faTrash },
    { href: "/admin/contents", label: "Contents", icon: faFileAlt },
    { href: "/admin/cities", label: "Städte", icon: faCity },
    { href: "/admin/categories", label: "Kategorien", icon: faTags },
    { href: "/admin/story-metas", label: "Story Metas", icon: faInfoCircle },
    { href: "/admin/group-metas", label: "Gruppen Metas", icon: faUsers },
    { href: "/admin/story-contents", label: "Story Contents DEV", icon: faFileCode },
    { href: "/admin/track-metas", label: "Track Metas DEV", icon: faInfoCircle },
    { href: "/admin/track-contents", label: "Track Contents DEV", icon: faFileCode },
    { href: "/admin/backup/db", label: "Backup DB", icon: faDatabase },
    { href: "/admin/quest-metas", label: "Quest Metas", icon: faMotorcycle },
    { href: "/admin/quest-contents", label: "Quest Contents", icon: faMotorcycle },
    { href: "/admin/puzzle-metas", label: "Puzzle Metas", icon: faPuzzlePiece },
    { href: "/admin/puzzle-contents", label: "Puzzle Contents", icon: faPuzzlePiece },


  ];

  return (
    <Container>
      <Row className="g-4">
        {menuItems.map((item, index) => (
          <Col key={index} xs={12} sm={6} md={4}>
            <Card className="text-center h-100">
              <Card.Body>
                <FontAwesomeIcon icon={item.icon} size="3x" className="mb-3" />
                <Card.Title>{item.label}</Card.Title>
                <Button variant="primary" as={Link} to={item.href}>
                  {item.label}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default AdminMainPage;
