import React from 'react';
import { ReactComponent as ArrowUp } from 'bootstrap-icons/icons/caret-up-fill.svg';
import { ReactComponent as ArrowDown } from 'bootstrap-icons/icons/caret-down-fill.svg';

const SortIndicator = ({ isSorted, direction }) => {
    if (!isSorted) return null; // Kein Icon, wenn nicht sortiert
    if (direction === 'asc') return <ArrowUp />;
    if (direction === 'desc') return <ArrowDown />;
    return null;
};


export default SortIndicator;