// src/components/StoryCities.js
import React from 'react';
import { Form, Badge } from 'react-bootstrap';
import { getEntityNames, getUnassignedEntities, handleAddEntity, handleRemoveEntity } from './StoryDetailHelpers';

const StoryCities = ({ storyCities, setStoryCities, cities, storyId, showNotification }) => {

    const getCityNames = () => getEntityNames(storyCities, cities, 'city_id');
    const getUnassignedCities = () => getUnassignedEntities(storyCities, cities, 'city_id');

    const handleAddCity = (event) => handleAddEntity(event, 'cities', storyCities, setStoryCities, 'Stadt', 'city_id', storyId, showNotification);
    const handleRemoveCity = (cityId) => handleRemoveEntity(cityId, storyCities, setStoryCities, 'cities', 'Stadt', 'city_id', storyId, showNotification);

    return (
        <div>
            <Form.Group controlId="unassignedCities">
                <Form.Label><h3>Stadt hinzufügen</h3></Form.Label>
                <Form.Control className="w-12" as="select" onChange={handleAddCity}>
                    <option value="">Stadt auswählen...</option>
                    {getUnassignedCities().map(city => (
                        <option key={city.id} value={city.id}>
                            {city.name}
                        </option>
                    ))}
                </Form.Control>
            </Form.Group>

            {storyCities.length > 0 ? (
                <div>
                    <span>Zugeordnete {storyCities.length === 1 ? 'Stadt' : 'Städte'}: </span>
                    {getCityNames().map(city => (
                        <Badge key={city.id} pill variant="primary" className="m-1 pa-05">
                            {city.name}
                            <span
                                style={{ cursor: 'pointer', marginLeft: '0.5rem', color: 'red' }}
                                onClick={() => handleRemoveCity(city.id)}
                            >
                                &times;
                            </span>
                        </Badge>
                    ))}
                </div>
            ) : (
                <p>Keine Stadt zugeordnet</p>
            )}
        </div>
    );
};

export default StoryCities;
