import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { ReactComponent as Trash } from 'bootstrap-icons/icons/trash.svg';
import { fetchData } from '../../utility/ApiRequests';
import { handleSaveChanges as handleSaveChangesUtil, handleNewEntry, handleDeleteEntry } from '../../utility/dataHandling';
import useSortableData from '../../hooks/useSortableData';
import { useNotification } from '../../hooks/useNotifications';
import EditableField from '../../utility/EditableField';
import SortIndicator from '../../utility/SortIndicator';


const LanguageTab = () => {
    const route = '/api/languages';
    const contextSingular = 'Sprache';
    const contextPlural = 'Sprachen';
    const dataBseName='languages';

    const [contextData, setContextData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [editActive, setEditActive] = useState(false);
    const { items: sortedContextData, requestSort, sortConfig } = useSortableData(contextData);
    const { showNotification } = useNotification();
    const token = localStorage.getItem('token');
    const handleSaveChanges = handleSaveChangesUtil(setContextData, showNotification);
 
    useEffect(() => {
        const initFetch = async () => {
            try {
                const data = await fetchData(route, token);
                setContextData(data);
                setIsLoading(false);
            } catch (error) {
                console.error('Fehler beim Laden der ' + contextPlural + ':', error);
                showNotification('Fehler beim Laden der ' + contextPlural + '.', 'danger');
                setIsLoading(false);
            }
        };
        initFetch();
    }, []);

    if (isLoading) {
        return <div><div className="spinner"></div> Lädt... </div>;
    }

    return (
        <div>
            <h2>{contextPlural}</h2>
            Sprache mit ID 2 sollte deutsch sein
            <div className='content-right'><Button className='' onClick={() => setEditActive(!editActive)}> Bearbeitung {editActive ? 'deaktivieren' : 'aktivieren'}</Button></div>
            <table className="table">
                <thead>
                    <tr>
                        <th onClick={() => requestSort('id', 'numeric')}>
                            ID
                            <SortIndicator isSorted={sortConfig.key === 'id'} direction={sortConfig.direction} />
                        </th>
                        <th onClick={() => requestSort('language_name')}>
                            verfügbare Sprachen
                            <SortIndicator isSorted={sortConfig.key === 'language_name'} direction={sortConfig.direction} />
                        </th>
                        <th onClick={() => requestSort('language_code')}>
                            Kürzel
                            <SortIndicator isSorted={sortConfig.key === 'language_code'} direction={sortConfig.direction} />
                        </th>
                        <th>
                            Flagge
                        </th>
                        {editActive &&
                            <th>
                                löschen
                            </th>
                        }
                        {/* Weitere Spalten hier */}
                    </tr>
                </thead>
                <tbody>
                    {sortedContextData.map(lan => {
                        if (lan.id === 1) return null;  // 'keine Sprach' nicht anzeigen
                        return (
                            <tr key={lan.id}>
                                <td>{lan.id}</td>
                                <td>{
                                    editActive ?
                                        <EditableField
                                            value={lan.language_name}
                                            minValue="3"
                                            maxValue="20"
                                            size="15"
                                            onSave={(newValue) => handleSaveChanges(newValue, 'language_name', lan.id, dataBseName)}
                                            fieldType="text"
                                        />
                                        :
                                        lan.language_name
                                }
                                </td>
                                <td>
                                    {
                                        editActive ?
                                            <EditableField
                                                value={lan.language_code}
                                                minValue="2"
                                                maxValue="7"
                                                size="7"
                                                onSave={(newValue) => handleSaveChanges(newValue, 'language_code', lan.id, dataBseName)}
                                                fieldType="text"
                                            />
                                            :
                                            lan.language_code
                                    }
                                </td>
                                <td>
                                    {
                                        editActive ?
                                            <EditableField
                                                value={lan.file_url}
                                                fileId={lan.id}
                                                height="1rem"
                                                width="1.5rem"
                                                fileKey={lan.illustration}
                                                fileDestination={dataBseName}
                                                fileUsageName="flag"
                                                onSave={(newValue) => {
                                                    handleSaveChanges({
                                                        'illustration': newValue.illustration,
                                                        'file_url': newValue.file_url,
                                                    }, null, lan.id, null);
                                                }}
                                                fieldType="image"
                                            />
                                            :
                                            <img src={lan.file_url}
                                                style={{ width: "1.5rem", height: "1rem" }} />
                                    }
                                </td>
                                {editActive &&
                                    <td>
                                        <Trash
                                            onClick={() => handleDeleteEntry(dataBseName, lan.id, setContextData, showNotification)}
                                        ></Trash>
                                    </td>
                                }
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {editActive && <Button
                onClick={() => handleNewEntry(dataBseName, setContextData, showNotification)}
            >{contextSingular} hinzufügen</Button>}
        </div>
    );
};

export default LanguageTab;
