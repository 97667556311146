import React, { useState, useRef, useEffect, useMemo } from 'react';
import { MapContainer, ImageOverlay, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { faMapMarkerAlt, faHome, faCrosshairs } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { renderToStaticMarkup } from 'react-dom/server';
import 'leaflet/dist/leaflet.css';
import { Container, Button, Row, Col } from 'react-bootstrap';

const IndoorMap = ({ tracks = [], handleTrack, currentTrackSlug, imageUrl, language, metas }) => {

    const mapRef = useRef(null);
    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
    const [initialZoom, setInitialZoom] = useState(1);
    const [bounds, setBounds] = useState(null);

    const getImageDimensions = (imageUrl) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = imageUrl;
            img.onload = () => {
                const dimensions = { width: img.naturalWidth, height: img.naturalHeight };
                resolve(dimensions);
            };
            img.onerror = (err) => {
                console.error('Error loading image in IndoorMap:', err);
                reject(err);
            };
        });
    };
    

    useEffect(() => {
        if (imageUrl) {
            getImageDimensions(imageUrl).then(dimensions => {
                setImageDimensions(dimensions);
                const initialBounds = L.latLngBounds([
                    [0, 0],
                    [dimensions.height, dimensions.width]
                ]);
                setBounds(initialBounds);
            }).catch(err => {
                console.error('Failed to load image dimensions in IndoorMap:', err);
            });
        }
    }, [imageUrl]);

    useEffect(() => {
        if (imageDimensions.width && imageDimensions.height && mapRef.current) {
            const map = mapRef.current;
            const fitZoomLevel = () => {
                const container = map._container;
                const { width: containerWidth, height: containerHeight } = container.getBoundingClientRect();
                const widthZoom = containerWidth / imageDimensions.width;
                const heightZoom = containerHeight / imageDimensions.height;
                return Math.min(widthZoom, heightZoom);
            };

            const zoomLevel = fitZoomLevel();
            setInitialZoom(zoomLevel * 0.5); // Set initial zoom to 50% of the calculated fit zoom level
            map.setView([imageDimensions.height / 2, imageDimensions.width / 2], zoomLevel * 0.5);
        }
    }, [imageDimensions]);

    const createIconMarkup = (icon, color) => {
        const htmlString = renderToStaticMarkup(
            <FontAwesomeIcon icon={icon} style={{ color: color, fontSize: '1em' }} />
        );
        const svgEncoded = encodeURIComponent(htmlString);
        return `data:image/svg+xml,${svgEncoded}`;
    };

    const customIconUrl = createIconMarkup(faMapMarkerAlt, "#ff0000");
    const customIcon = new L.Icon({
        iconUrl: customIconUrl,
        iconSize: [31, 41],
        iconAnchor: [15.5, 41],
        popupAnchor: [0, -41],
        className: 'custom-icon',
    });

    const currentTrack = useMemo(() => {
        return tracks.find(track => track.slug === currentTrackSlug);
    }, [tracks, currentTrackSlug]);

    const getWaypoint = (track) => {
        if (!track?.metadata?.trackMeta_location) {
            return [0, 0];
        }
        const locationData = track.metadata.trackMeta_location[1];
        const location = JSON.parse(locationData);
        return [location.x, location.y];
    };

    useEffect(() => {
        if (mapRef.current && currentTrack?.metadata?.trackMeta_location) {
            const locationData = currentTrack.metadata.trackMeta_location[1];
            const { x, y } = JSON.parse(locationData);
            mapRef.current.flyTo([x, y], mapRef.current.getZoom());
            mapRef.current.closePopup();
        }
    }, [currentTrackSlug]);

    const getIcon = (isSelected, trackCoverUrl, storyCoverUrl) => new L.Icon({
        iconUrl: trackCoverUrl || storyCoverUrl || "/assets/illustrations/placeholder-cover.jpg",
        iconSize: isSelected ? [50, 50] : [30, 30],
        iconAnchor: isSelected ? [25, 25] : [15, 15],
        className: isSelected ? 'custom-icon selected-icon' : 'custom-icon',
    });

    const handleCenterMap = () => {
        if (mapRef.current) {
            mapRef.current.setView([imageDimensions.height / 2, imageDimensions.width / 2], initialZoom);
        }
    };

    const handleCenterTrack = () => {
        if (mapRef.current && currentTrack?.metadata?.trackMeta_location) {
            const locationData = currentTrack.metadata.trackMeta_location[1];
            const { x, y } = JSON.parse(locationData);
            mapRef.current.setView([x, y], mapRef.current.getZoom());
        }
    };

    return (
        <Container fluid>
        <Row>
          <Col>
            <div className='title-page'><h2>Übersichtskarte</h2></div>
          </Col>
        </Row>
        <Row className="map-container">
          <Col>
          <MapContainer
            center={[imageDimensions.height / 2, imageDimensions.width / 2]}
            zoom={initialZoom}
            minZoom={0.01}  // Allow more zoom out
            maxZoom={6}  // Increase maxZoom for more dynamic zooming
            crs={L.CRS.Simple}
            ref={mapRef}
            className="h-100"
        >
            
            {bounds && (
                <ImageOverlay
                    url={imageUrl}
                    bounds={bounds}
                />
            )}

                {tracks.map((track) => (
                    <Marker
                        key={track.slug}
                        position={getWaypoint(track)}
                        icon={getIcon(
                            track.slug === currentTrackSlug,
                            track.metadata?.trackMeta_cover ? track.metadata.trackMeta_cover[language] : null,
                            metas.storyMeta_cover ? metas.storyMeta_cover[language] : null
                        )}
                        zIndexOffset={track.slug === currentTrackSlug ? 1000 : 500}
                        eventHandlers={{
                            click: () => {
                                handleTrack(track.slug);
                                if (mapRef.current) {
                                    const waypoint = getWaypoint(track);
                                    mapRef.current.flyTo([waypoint[0], waypoint[1]], mapRef.current.getZoom());
                                }
                            },
                        }}
                    >
                        <Popup>
                            {track.metadata?.trackMeta_name ? track.metadata.trackMeta_name[language] : 'Unbenannter Track'}
                        </Popup>
                    </Marker>
                ))}
                            <div className="map-controls">
                <button className="map-control-button" onClick={handleCenterMap}>
                    <FontAwesomeIcon icon={faHome} />
                </button>
                <button className="map-control-button" onClick={handleCenterTrack}>
                    <FontAwesomeIcon icon={faCrosshairs} />
                </button>
            </div>
            </MapContainer>
            </Col>
      </Row>
    </Container>



    );
};

export default IndoorMap;
