import React, { useState, useEffect } from 'react';
import { Button, Table, Form } from 'react-bootstrap';
import { fetchData, postData, deleteData } from '../../utility/ApiRequests';
import { useNotification } from '../../hooks/useNotifications';

const BackupDb = () => {
    const [backups, setBackups] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    
    const { showNotification } = useNotification();
    const token = localStorage.getItem('token');

    const routes = {
        backup: '/api/backupDb',
        restore: '/api/restoreDb',
        restoreFile: '/api/restoreFromFile',
        list: '/api/listBackups',
        download: (filename) => `/api/downloadBackup/${filename}`,
        delete: (filename) => `/api/deleteBackup/${filename}`
    };

    // Lade Liste der Backups
    const fetchBackups = async () => {
        try {
            const response = await fetchData(routes.list, token);
            if (response.success && Array.isArray(response.files)) {
                // Sortiere nach lastModified (neueste zuerst)
                const sortedBackups = [...response.files].sort((a, b) => 
                    new Date(b.lastModified) - new Date(a.lastModified)
                );
                setBackups(sortedBackups);
            } else {
                setBackups([]);
            }
        } catch (error) {
            console.error('Fehler beim Laden der Backups:', error);
            showNotification('Fehler beim Laden der Backups.', 'danger');
            setBackups([]);
        }
    };

    // Initialer Load
    useEffect(() => {
        fetchBackups();
    }, []);

    // Backup erstellen
    const createBackup = async () => {
        setIsLoading(true);
        try {
            const data = await fetchData(routes.backup, token);
            showNotification('Backup wurde erfolgreich erstellt.', 'success');
            fetchBackups(); // Liste aktualisieren
        } catch (error) {
            console.error('Fehler beim Erstellen des Backups:', error);
            showNotification('Fehler beim Erstellen des Backups.', 'danger');
        }
        setIsLoading(false);
    };

    const restoreBackup = async (fileName) => {
        if (window.confirm('Sind Sie sicher, dass Sie dieses Backup wiederherstellen möchten? Alle aktuellen Daten werden überschrieben.')) {
            setIsLoading(true);
            try {
                const data = await postData(
                    routes.restore,
                    token,
                    { filename: `${fileName}` }
                );
                showNotification('Backup wurde erfolgreich wiederhergestellt.', 'success');
            } catch (error) {
                console.error('Fehler bei der Wiederherstellung:', error);
                showNotification('Fehler bei der Wiederherstellung des Backups.', 'danger');
            }
            setIsLoading(false);
        }
    };

    // Download-Funktion
    const downloadBackup = async (fileName) => {
        try {
            const response = await fetchData(routes.download(fileName), token);
            if (response.success && response.url) {
                // Öffne Download in neuem Tab
                window.open(response.url, '_blank');
            } else {
                showNotification('Fehler beim Generieren der Download-URL.', 'danger');
            }
        } catch (error) {
            console.error('Fehler beim Download:', error);
            showNotification('Fehler beim Download des Backups.', 'danger');
        }
    };

    // Lösch-Funktion
    const deleteBackup = async (fileName) => {
        if (window.confirm('Sind Sie sicher, dass Sie dieses Backup löschen möchten?')) {
            try {
                const response = await deleteData(
                    routes.delete(fileName), 
                    token
                );
                if (response.success) {
                    showNotification('Backup wurde erfolgreich gelöscht.', 'success');
                    fetchBackups(); // Liste aktualisieren
                } else {
                    showNotification('Fehler beim Löschen des Backups.', 'danger');
                }
            } catch (error) {
                console.error('Fehler beim Löschen:', error);
                showNotification('Fehler beim Löschen des Backups.', 'danger');
            }
        }
    };



    // Formatiere Dateigröße
    const formatFileSize = (size) => {
        const units = ['B', 'KB', 'MB', 'GB'];
        let unitIndex = 0;
        let fileSize = size;

        while (fileSize >= 1024 && unitIndex < units.length - 1) {
            fileSize /= 1024;
            unitIndex++;
        }

        return `${fileSize.toFixed(1)} ${units[unitIndex]}`;
    };

    return (
        <div className="p-3">
            <h2>Datenbank-Backup</h2>
            
            {/* Backup erstellen */}
            <div className="mb-4">
                <Button 
                    onClick={createBackup} 
                    disabled={isLoading}
                    variant="primary"
                >
                    {isLoading ? 'Wird ausgeführt...' : 'Neues Backup erstellen'}
                </Button>
            </div>

            {/* Liste der Backups */}
            <div>
                <h4>Verfügbare Backups ({backups.length})</h4>
                {backups.length > 0 ? (
                    <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Datum</th>
                            <th>Dateiname</th>
                            <th>Größe</th>
                            <th>Aktionen</th>
                        </tr>
                    </thead>
                    <tbody>
                        {backups.map((backup) => (
                            <tr key={backup.name}>
                                <td>{new Date(backup.lastModified).toLocaleString('de-DE')}</td>
                                <td>{backup.fileName}</td>
                                <td>{formatFileSize(backup.size)}</td>
                                <td>
                                    <div className="d-flex gap-2">
                                        <Button
                                            onClick={() => restoreBackup(backup.fileName)}
                                            disabled={isLoading}
                                            variant="warning"
                                            size="sm"
                                        >
                                            Wiederherstellen
                                        </Button>
                                        <Button
                                            onClick={() => downloadBackup(backup.fileName)}
                                            variant="info"
                                            size="sm"
                                        >
                                            Download
                                        </Button>
                                        <Button
                                            onClick={() => deleteBackup(backup.fileName)}
                                            variant="danger"
                                            size="sm"
                                        >
                                            Löschen
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                ) : (
                    <p className="text-muted">Keine Backups verfügbar</p>
                )}
            </div>

            {isLoading && (
                <div className="text-center mt-3">
                    <p>Vorgang wird ausgeführt...</p>
                </div>
            )}
        </div>
    );
};

export default BackupDb;