import React from 'react';
import { Table } from 'react-bootstrap';
import EditableField from '../../../utility/EditableField';
import SortIndicator from '../../../utility/SortIndicator';
import { ReactComponent as Trash } from 'bootstrap-icons/icons/trash.svg';
import { handleNewEntry, handleDeleteEntry } from '../../../utility/dataHandling';
import { v4 as uuidv4 } from 'uuid';

const QuestMetas = ({ sortedContextData, requestSort, sortConfig, metaCategories, selectedLanguage, languages, setQuestsContentData, setFormattedContents, handleSaveChanges, editActive, showNotification, getLanguageIdByCode }) => {

    const renderData = (myData, type) => {
        if (myData && typeof myData === 'object') {
            if (Array.isArray(myData) && myData.length === 2) {
                return `[${myData[0]}, ${myData[1]}]`;
            } else if (!Array.isArray(myData) && myData.x !== undefined && myData.y !== undefined) {
                return `[${myData.x}, ${myData.y}]`;
            }
        }
        if (type === 'image') {
            console.log(myData)
            return <img src={myData} style={{ width: "1.5rem", height: "1rem" }} />;
        }
        if (type === 'audio') {
            const uniqueKey = uuidv4();
            return (
                <audio controls key={uniqueKey}>
                    <source src={myData} type="audio/mpeg" />
                    Your browser does not support the audio element.
                </audio>
            );
        }
        return myData;
    };

    const setValueData = (myData) => {
        if (myData && typeof myData === 'object') {
            if (Array.isArray(myData) && myData.length === 2) {
                return [myData[0], myData[1]];
            } else if (!Array.isArray(myData) && myData.x !== undefined && myData.y !== undefined) {
                return [myData.x, myData.y];
            }
        }
        return myData;
    };

    const getFieldType = (type) => {
        switch (type) {
            case 'geo':
                return 'point';
            case 'numeric':
                return 'float';
            case 'longtext':
                return 'textarea';
            case 'image':
                return 'image';
            case 'audio':
                return 'audio';
            case 'date':
                return 'date';
            default:
                return 'text';
        }
    };

    return (
        <Table>
            <thead>
                <tr>
                    <th onClick={() => requestSort('name')}>
                        Name
                        <SortIndicator isSorted={sortConfig.key === 'name'} direction={sortConfig.direction} />
                    </th>
                    <th onClick={() => requestSort(`${selectedLanguage}_name`)}>
                        Wert {selectedLanguage}
                        <SortIndicator isSorted={sortConfig.key === `${selectedLanguage}_name`} direction={sortConfig.direction} />
                    </th>
                </tr>
            </thead>
            <tbody>
                {sortedContextData.map(lan => {
                    const metaCategory = metaCategories.find(category => category.id === lan.meta_category_id);
                    const languageId = metaCategory && metaCategory.has_translations === 0 ? 1 : getLanguageIdByCode(selectedLanguage,languages);
                    const languageField = metaCategory && metaCategory.has_translations === 0 ? 'null_name' : `${selectedLanguage}_name`;
                    const languageIdField = metaCategory && metaCategory.has_translations === 0 ? 'null_id' : `${selectedLanguage}_id`;

                    return (
                        <tr key={lan.id}>
                            <td style={metaCategories.some(category => category.id === lan.meta_category_id && category.has_translations === 0) ? { backgroundColor: 'lightgreen' } : {}}>{lan.name}</td>
                            <td style={lan['null_name'] ? { backgroundColor: 'lightgreen' } : {}}>
                                {editActive ? (
                                    <EditableField
                                    value={setValueData(lan['null_name']) || setValueData(lan[`${selectedLanguage}_name`]) || ''}
                                    //geoObjects={geoObjects}
                                    {...(getFieldType(lan.type) === 'image' && { 
                                        fileId: lan['null_id'] || lan[`${selectedLanguage}_id`],
                                        height: "8rem",
                                        width: "8rem",
                                        fileKey: lan['null_name_key'] || lan[`${selectedLanguage}_key`],
                                        fileDestination: "image_contents",
                                        fileUsageName: "image",
                                        languageId: metaCategory && metaCategory.has_translations === 0 ? 1 : getLanguageIdByCode(selectedLanguage, languages),
                                        
                                    })}
                                    {...(getFieldType(lan.type) === 'audio' && { 
                                        fileId: lan['null_id'] || lan[`${selectedLanguage}_id`],
                                        height: "8rem",
                                        width: "8rem",
                                        fileKey: lan['null_name_key'] || lan[`${selectedLanguage}_key`],
                                        fileDestination: "audio_contents",
                                        fileUsageName: "audio",
                                        languageId: metaCategory && metaCategory.has_translations === 0 ? 1 : getLanguageIdByCode(selectedLanguage, languages),
                                        
                                    })}
                                    {...(getFieldType(lan.type) === 'point' && { 
                                        //lastEditedPoint: mapDetails.startLocation? mapDetails.startLocation: null,
                                        //geoObjects: mapDetails.questLocation? mapDetails.questLocation: null,
                                        //mapImage: mapDetails.mapImage ? mapDetails.mapImage : null,
                                    })}

                                    placeholder={lan.name}
                                    ifEmpty="---"
                                    onSave={async (newValue) => {
                                        const metaCategory = metaCategories.find(category => category.id === lan.meta_category_id);
                                        const languageField = metaCategory && metaCategory.has_translations === 0 ? 'null_name' : `${selectedLanguage}_name`;
                                        const languageIdField = metaCategory && metaCategory.has_translations === 0 ? 'null_id' : `${selectedLanguage}_id`;

                                        if (lan[languageField]) {
                                                if (getFieldType(lan.type) === 'image' || getFieldType(lan.type) === 'audio') {
                                                    const { file_url, filename } = newValue;
                                                    setQuestsContentData(prevContextData =>
                                                        prevContextData.map(item =>
                                                            item.id === lan[`${selectedLanguage}_content_id`] || item.id === lan['null_content_id']
                                                                ? { ...item, content: file_url, key: filename }
                                                                : item
                                                        )
                                                    );
                                            } else {
                                                handleSaveChanges(newValue, languageField, lan.id, lan.type + '_contents', 'content', lan[languageIdField]);
                                            }
                                        } else {
                                            let newContentEntry ={}
                                            if (getFieldType(lan.type) === 'image' || getFieldType(lan.type) === 'audio') {
                                                newContentEntry.id = newValue.file_id;

                                            } else {
                                            newContentEntry = await handleNewEntry(lan.type + '_contents', () =>{}, () =>{}, {
                                                'content': newValue,
                                            });
                                        }

                                            if (newContentEntry?.id) {
                                                const languageId = metaCategory && metaCategory.has_translations === 0 ? 1 : getLanguageIdByCode(selectedLanguage, languages);
                                                const newQuestContentEntry = await handleNewEntry('quest_contents', () =>{}, showNotification, {
                                                    'quest_id': lan.questId,
                                                    'meta_category_id': lan.meta_category_id,
                                                    'content_id': newContentEntry.id,
                                                    'language_id': languageId
                                                });
                                                if (getFieldType(lan.type) === 'image' || getFieldType(lan.type) === 'audio') {
                                                    newQuestContentEntry['content'] = newValue.file_url;
                                                    newQuestContentEntry['key'] = newValue.file_name;
                                                } else{
                                                    newQuestContentEntry['content'] = newValue;
                                                }
                                                
                                                setQuestsContentData(prevContextData => [...prevContextData, newQuestContentEntry]);
                                            }
                                        }
                                    }}
                                    fieldType={getFieldType(lan.type)}
                                />
                            ) : (
                                renderData(lan['null_name'] || lan[`${selectedLanguage}_name`], lan.type) || <span className='text-red'>noch kein Wert</span>
                            )}
                            {editActive && (
                                <Trash onClick={() => {
                                    handleDeleteEntry('quest_contents', lan[`${selectedLanguage}_content_id`] || lan['null_content_id'], () =>{}, showNotification);
                                    setQuestsContentData(prevContextData => prevContextData.filter(item => item.id !== lan[`${selectedLanguage}_content_id`] && item.id !== lan['null_content_id']));
                                }} />
                                )}
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
};

export default QuestMetas;
