import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


export const fetchData = async (route, token = null, params = null) => {
    try {
        const response = await axios.get(`${API_BASE_URL + route}`, {
            headers: { 'authorization': `Bearer ${token}` },
            params: params
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const postData = async (route, token = null, data = null) => {
    try {
        const response = await axios.post(`${API_BASE_URL + route}`, data, {
            headers: { 'authorization': `Bearer ${token}` }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteData = async (route, token = null, data = null) => {
    try {
        const config = {
            headers: { 'authorization': `Bearer ${token}` }
        };
        if (data) {
            config.data = data; 
        }
        const response = await axios.delete(`${API_BASE_URL + route}`, config);
        return response.data;
    } catch (error) {
        throw error;
    }
};