import React, { useState } from 'react';
import axios from 'axios';
import { Card, Button } from 'react-bootstrap';
import { useNotification } from '../../hooks/useNotifications';

const AddUser = () => {
    const [userName, setUserName] = useState('');
    const [userRole, setUserRole] = useState(3);
    const [userPassword, setUserPassword] = useState('');

    const { showNotification } = useNotification();
    const token = localStorage.getItem('token');

    const handleAddUser = async () => {
        const userData = {
            username: userName.trim(),
            password: userPassword.trim(),
            role: parseInt(userRole, 10),

        };

        try {
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/signup`, userData, {
                headers: {
                    'authorization': `Bearer ${token}`,
                }
            });
            showNotification('Nutzer erfolgreich erstellt', 'success');
        } catch (error) {
            console.error('Fehler beim Hinzufügen des Nutzers:', error);
            showNotification('Nutzer konnte nicht erstellt werden', 'danger');
        }
    };

    const enableSend = userName.trim().length > 0 && userPassword.trim().length > 0 && userRole > 0;

    return (
        <div>
            <h1>Nutzer hinzufügen</h1>
            <Card>
                <p>Neuen Nutzer hinzufügen</p>
                Name: <input type="text" value={userName} onChange={(e) => setUserName(e.target.value)} />
                Passwort: <input type="text" value={userPassword} onChange={(e) => setUserPassword(e.target.value)} />
                Rolle: <input type="number" value={userRole.toString()} onChange={(e) => setUserRole(Number(e.target.value))} />

                {enableSend && 
                    <Button onClick={handleAddUser}>
                        abschicken
                    </Button>
                }
            </Card>
        </div>
    );
};

export default AddUser;
