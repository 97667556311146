// src/components/StoryTracks.js
import React, { useEffect, useState, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DraggableCard from '../../../utility/DraggableCard';
import update from 'immutability-helper';
import { handleNewEntry, updateElementPositionsInDB, handleDeleteEntry } from '../../../utility/dataHandling';
import { createUniqueSlug } from '../../../utility/Slug';
import { getLanguageIdByCode } from './StoryDetailHelpers';

const StoryTracks = ({ trackOrder, setTrackOrder, tracks, setTracks, trackContents, setTrackContents, languages, storyId, showNotification,handleSaveChanges,selectedLanguage}) => {
    const [isAddingTrack, setIsAddingTrack] = useState(false);
    const [newTrackName, setNewTrackName] = useState('');

    useEffect(() => {
        if (tracks && tracks.length > 0) {
            const initialOrder = tracks
                .sort((a, b) => a.position - b.position)
                .map((track, index) => {
                    const trackData = trackContents.filter(tc => tc.track_id === track.track_id);
                    const name = trackData.reduce((acc, tc) => {
                        const langCode = languages.find(lang => lang.id === tc.language_id)?.language_code;
                        if (langCode) {
                            if (tc.meta_category_id === 2) {
                                acc[`${langCode}_audioUrl`] = tc.content;
                                acc[`${langCode}_audioUrl_id`] = tc.content_id;
                            }
                            if (tc.meta_category_id === 1) {
                                acc[`${langCode}_name`] = tc.content;
                                acc[`${langCode}_name_id`] = tc.content_id;
                            }
                        }
                        return acc;
                    }, {});
                    const geoLocation = trackData.find(tc => tc.meta_category_id === 6)?.content;
                    const geoLocation_id = trackData.find(tc => tc.meta_category_id === 6)?.content_id;
                    return {
                        ...track,
                        ...name,
                        geoLocation,
                        geoLocation_id,
                        position: index + 1 // Reassign positions here
                    };
                });
            setTrackOrder(initialOrder);
            setTracks(tracks.map((track, index) => ({
                ...track,
                position: index + 1
            })));
        } else {
            setTrackOrder([]); // Ensures that the track order is reset if there are no tracks
        }
    }, [trackContents, languages]);

    const moveTrack = useCallback((dragIndex, hoverIndex, finalize = false) => {
        const draggedTrack = trackOrder[dragIndex];
        const newTrackOrder = update(trackOrder, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, draggedTrack],
            ],
        });
        const updatedTrackOrder = newTrackOrder.map((track, index) => ({
            ...track,
            position: index + 1,
        }));
    
        setTrackOrder(updatedTrackOrder);
        setTracks(updatedTrackOrder.map(track => ({
            id: track.id,
            story_id: track.story_id,
            track_id: track.track_id,
            position: track.position
        })));
    
        if (finalize) {
            handleSavePositionsToDb(updatedTrackOrder);
        }
    }, [trackOrder]);

    const handleSavePositionsToDb = async (tracks) => {
        await updateElementPositionsInDB(tracks, 'tracks');
    };

    const handleAddNewEntry = () => {
        setIsAddingTrack(true);
    };
    
    const handleCancelAdd = () => {
        setIsAddingTrack(false);
        setNewTrackName('');
    };

    const handleSaveNewTrack = async () => {
        const existingSlugs = tracks.map(data => data.slug);
        const slug = createUniqueSlug(newTrackName, existingSlugs);

        const languageId = languages.find(lang => lang.language_code === 'de_DE')?.id;

        if (languageId) {
            const newTrack = await handleNewEntry('tracks', () => {}, showNotification, {
                'state': 'published',
            });

            const newTrackInStory = await handleNewEntry('story_tracks', () => {}, showNotification, {
                'story_id': storyId,
                'track_id': newTrack.id,
                'position': tracks.length + 1,
                'slug': slug
            });

            const newTrackTitleContent = await handleNewEntry('text_contents', () => {}, showNotification, {
                'content': newTrackName,
            });

            const newTrackMetaContent = await handleNewEntry('track_contents', () => {}, showNotification, {
                'track_id': newTrack.id,
                'meta_category_id': 1,
                'content_id': newTrackTitleContent.id,
                'language_id': languageId
            });

            setTracks(prevTracks => {
                const updatedTracks = [
                    ...prevTracks,
                    { id: newTrackInStory.id, story_id: parseInt(storyId), track_id: newTrack.id, position: prevTracks.length + 1 }
                ];
                return updatedTracks.map((track, index) => ({
                    ...track,
                    position: index + 1
                }));
            });

            setTrackContents(prevTrackContents => [
                ...prevTrackContents,
                { id: newTrackMetaContent.id, track_id: newTrack.id, meta_category_id: 1, content_id: newTrackTitleContent.id, language_id: languageId, content: newTrackName }
            ]);
        } else {
            showNotification('Sprache de_DE existiert nicht.', 'danger');
        }

        setIsAddingTrack(false);
        setNewTrackName('');
    };

    const handleDeleteTrack = async (storyTrackId, trackId) => {
        const deleteTrack = await handleDeleteEntry('story_tracks', storyTrackId, () => {}, showNotification);
        if (deleteTrack) {
            setTracks(prevTracks => {
                const updatedTracks = prevTracks.filter(track => parseInt(track.id) !== parseInt(storyTrackId));
                return updatedTracks.map((track, index) => ({
                    ...track,
                    position: index + 1
                }));
            });
            setTrackContents(prevTrackContents => prevTrackContents.filter(track => parseInt(track.track_id) !== parseInt(trackId)));
        }
    };

    // Funktion zur Aktualisierung eines bestehenden Track-Inhalts
const updateExistingTrackContent = (trackId, newValue) => {
    const updatedTrackContents = trackContents.map((content) => {
        if (content.track_id === trackId) { 
            return {
                ...content,
                content: newValue,
            };
        }
        return content;
    });
    setTrackContents(updatedTrackContents);
    handleSaveChanges(
        newValue, 
        `${selectedLanguage}_name`, 
        trackOrder.findIndex(track => track.id === trackId) + 1, // +1 to convert index to position
        'text_contents', 
        'content', 
        trackOrder.find(track => track.id === trackId)[`${selectedLanguage}_name_id`]
    );
};

// Funktion zur Erstellung eines neuen Track-Inhalts
const createNewTrackContent = async (trackId, newValue) => {
    try {
        const newTrackTitleContent = await handleNewEntry('text_contents', () => {}, showNotification, {
            'content': newValue,
        });

        const newTrackContent = await handleNewEntry('track_contents', () => {}, showNotification, {
            'track_id': trackId,
            'meta_category_id': 1,
            'content_id': newTrackTitleContent.id,
            'language_id': getLanguageIdByCode(selectedLanguage, languages),
        });

        setTrackContents(prevTrackContents => [
            ...prevTrackContents,
            { id: newTrackContent.id, track_id: trackId, meta_category_id: 1, content_id: newTrackTitleContent.id, language_id: getLanguageIdByCode(selectedLanguage, languages), content: newValue }
        ]);

    } catch (error) {
        console.error('Fehler beim Erstellen eines neuen Track-Inhalts:', error);
    }
};

    return (
        <div>
            <h3>Verfügbare Tracks</h3>
            {trackOrder.length > 0 ? (
                <DndProvider backend={HTML5Backend}>
                    {trackOrder.map((track, index) => (
                        <DraggableCard
                            usage={'tracks'}
                            key={track.id}
                            index={index}
                            dragItem={track}
                            elementOrder={trackOrder}
                            setElementOrder={setTrackOrder}
                            moveElement={moveTrack}
                            selectedLanguage={selectedLanguage}
                            deleteElement={handleDeleteTrack}
                            onSave={async (newValue) => {
                                if (track[`${selectedLanguage}_name`]) {
                                    // Track existiert bereits
                                    updateExistingTrackContent(track.id, newValue);
                                } else {
                                    await createNewTrackContent(track.id, newValue);
                                }
                            }}
                        />
                    ))}
                </DndProvider>
            ) : (
                <p>Keine Tracks verfügbar</p>
            )}
            {!isAddingTrack && (
                <Button onClick={handleAddNewEntry}>Neuen Track hinzufügen</Button>
            )}
            {isAddingTrack && (
                <div>
                    <p>Name
                        <input className='ml-1'
                            type="text"
                            value={newTrackName}
                            onChange={(e) => setNewTrackName(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && handleSaveNewTrack()}
                            placeholder={`Trackname auf Deutsch`}
                        />
                    </p>
                    <p>
                        <Button onClick={handleSaveNewTrack}>Speichern</Button>
                        <Button className='ml-1' onClick={handleCancelAdd}>Abbrechen</Button>
                    </p>
                </div>
            )}
        </div>
    );
};

export default StoryTracks;
