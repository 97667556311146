import React, { useState, useContext } from 'react';
import { Card, Image, Badge, Modal, Button } from 'react-bootstrap';
import { WalkDataContext } from '../hooks/walkDataContext';
import PuzzleInputText from './PuzzleInputText';
import PuzzleInputMultipleChoice from './PuzzleInputMultipleChoice';
import PuzzleInputQr from './PuzzleInputQr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointRight, faCheck, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FindNextPuzzleButton } from '../utility/NavigatePuzzles';


function Puzzle({ puzzle, language }) {
  const { expandedPuzzle, setExpandedPuzzle, setExpandedQuest, getElementState, setElementState, setCurrentTrack, contentState, quests } = useContext(WalkDataContext);
  const puzzleTitel = puzzle.metadata.puzzleMeta_title?.[language] ?? null;
  const puzzleDescription = puzzle.metadata.puzzleMeta_description?.[language] ?? null;
  const puzzleIllustration = puzzle.metadata.puzzleMeta_illustration?.[language] ?? null;
  const puzzleIAudio = puzzle.metadata.puzzleMeta_audio?.[language] ?? null;
  const puzzleType = puzzle.metadata.puzzleMeta_type?.['1'] ?? null;
  const puzzleContent = puzzle.metadata.puzzleMeta_content?.[language] ?? null;  // Auswahlmöglichkeiten bei Multiple Choice
  const puzzleHint1 = puzzle.metadata.puzzleMeta_hint1?.[language] ?? null;
  const puzzleSovedText = puzzle.metadata.puzzleMeta_finishedtext?.[language] ?? null;
  //const puzzleHint2 = puzzle.metadata.puzzleMeta_hint2?.[language] ?? null;
  //const puzzleHint3 = puzzle.metadata.puzzleMeta_hint3?.[language] ?? null;
  const puzzleSlug = puzzle.slug ?? null;
  const puzzleState = getElementState('puzzle', puzzleSlug);
  const badgeVariant = puzzleState === 'active' ? 'success' : 'secondary';


  const [showImageModal, setShowImageModal] = useState(false);

  const handleImageClick = (e) => {
    e.stopPropagation(); // Verhindert, dass der Klick die Karte aufklappt/zuklappt
    setShowImageModal(true);
  };

  // berücksichtigt noch keinen Sprachwechsel
  const setExternalAudio = () => setCurrentTrack({
    'position':99,
    'slug':'temp', 
    'metadata': {
      'trackMeta_audiotrack':{
        '2':puzzleIAudio
      },
      //'trackMeta_author':{'1':''},
      'trackMeta_cover':{'2':puzzleIllustration ? puzzleIllustration : null},
      'trackMeta_name':{'2': puzzleTitel}
    }
  })

  const convertToValidJson = (str) => {
    // Ersetze einfache Anführungszeichen durch doppelte
    return str.replace(/'/g, '"');
  };

  const parseSolution = (solutionString) => {
    try {
      const validJsonString = convertToValidJson(solutionString);
      return JSON.parse(validJsonString);
    } catch (error) {
      console.error("Error parsing solution:", error);
      return [];
    }
  };
  const puzzleSolution = parseSolution(puzzle.metadata.puzzleMeta_solution?.[language] ?? '[]');

  
  
  const parseActivatesContent = (contentString) => {
    //console.log(contentString);
    try {
      const validJsonString = convertToValidJson(contentString);
      //console.log(JSON.parse(validJsonString));
      return JSON.parse(validJsonString);
    } catch (error) {
      console.error("Error parsing activates content:", error);
      return [];
    }
  };

  const puzzleActivatesContent = parseActivatesContent(puzzle.metadata.puzzleMeta_activatescontent?.['1'] ?? '[]');

  const updateSmallestActivePuzzle = (quests, contentState, setExpandedQuest, setExpandedPuzzle) => {
    const smallest = findSmallestActivePuzzle(quests, contentState);
    if (smallest) {
      setExpandedQuest(smallest.questSlug);
      setExpandedPuzzle(smallest.puzzleSlug);
    }
  };


  const checkSolution = (matchedSolutionIndex) => {
    if (matchedSolutionIndex !== -1) {
      setTimeout(() => {
        setElementState('puzzle', puzzleSlug, 'finished');
      
      
      const itemsToActivate = puzzleActivatesContent[matchedSolutionIndex] || [];
      //console.log(itemsToActivate);
      
      // Erstellen eine Kopie des aktuellen contentState
      const updatedContentState = [...contentState];
  
      // Aktualisieren den lokalen Zustand und die Kopie des contentState
      itemsToActivate.forEach(item => {
        
        const index = updatedContentState.findIndex(
          stateItem => stateItem.type === item.type && stateItem.slug === item.slug
        );

        //console.log(item.type, '; ', item.slug);

        setElementState(item.type, item.slug, 'active', updatedContentState[index].state==='inactive' ? true : updatedContentState[index].highlight);
        if (index !== -1) {
          updatedContentState[index] = { 
            ...updatedContentState[index], 
            state: 'active', 
            highlight: updatedContentState[index].state === 'inactive' ? true : updatedContentState[index].highlight
          };
        } else {
          updatedContentState.push({ type: item.type, slug: item.slug, state: 'active', highlight: true });
        }
      });
  
      // Aktualisiere auch den Zustand des gelösten Puzzles in der Kopie
      const puzzleIndex = updatedContentState.findIndex(
        item => item.type === 'puzzle' && item.slug === puzzleSlug
      );
      if (puzzleIndex !== -1) {
        updatedContentState[puzzleIndex] = { ...updatedContentState[puzzleIndex], state: 'finished', highlight: false };
      }
  
      // Verwende die aktualisierte Kopie des contentState
      updateSmallestActivePuzzle(quests, updatedContentState, setExpandedQuest, setExpandedPuzzle);
    }, 2000);
    }
    // Bei nicht-übereinstimmender Lösung wird nichts ausgeführt
  };

  const findSmallestActivePuzzle = (quests, contentState) => {
    const sortedQuests = quests.sort((a, b) => a.position - b.position);
  
    for (const quest of sortedQuests) {
      const questState = contentState.find(item => item.type === 'quest' && item.slug === quest.slug);
      
      if (questState && questState.state === 'active') {
        const sortedPuzzles = quest.puzzles.sort((a, b) => a.position - b.position);
        
        for (const puzzle of sortedPuzzles) {
          const puzzleState = contentState.find(item => item.type === 'puzzle' && item.slug === puzzle.slug);
          if (puzzleState && puzzleState.state === 'active') {
            return {
              questSlug: quest.slug,
              puzzleSlug: puzzle.slug
            };
          }
        }
      }
    }
    
    // Falls kein aktives Puzzle in einem aktiven Quest gefunden wurde
    return null;
  };

// gedacht war dass das kleinste mögliche puzzle beim steitenstart angezeigt wird - der code verhindert das manuelle wecjseln des Quests
//useEffect(() => {
  //updateSmallestActivePuzzle(quests, contentState, setExpandedQuest, setExpandedPuzzle);
//}, []);

  return (
    <>
    <Card className="mb-4">
      {puzzleTitel && (
        <Card.Header
          as="h5"
          className="d-flex"
          onClick={() => setExpandedPuzzle(puzzleSlug)}
        >
          <span className='mr-1'>
          {puzzleState === 'active' && <FontAwesomeIcon icon={faHandPointRight} className="text-primary fs-4" />}
          {puzzleState === 'finished' && <FontAwesomeIcon icon={faCheck} className="text-success fs-4" />}
          </span>
          {puzzleTitel}

        </Card.Header>
      )}
      {puzzleSlug === expandedPuzzle && (
        <Card.Body>
          {puzzleIllustration && (
            <div style={{
              float: 'left',
              width: '40%',
              position: 'relative',
              paddingBottom: '30%',
              cursor: 'pointer'
            }}>
              <Image 
                src={puzzleIllustration} 
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain'
                }}
                onClick={handleImageClick}
              />
            </div>
          )}
            {puzzleDescription && (
              <div className="puzzle-description">
                <div dangerouslySetInnerHTML={{ __html: puzzleDescription }} />
                {puzzleIAudio && (
                  <div className="mt-2">
                    <Button onClick={() => setExternalAudio()} variant="outline-secondary" size="sm">
                      <FontAwesomeIcon icon={faPlay} />
                    </Button>
                  </div>
                )}
              </div>
            )}
          <div style={{ clear: 'both' }}>
            {puzzleState === 'active' && (
              <>
                {puzzleType === "MultipleChoice" && (
                  <PuzzleInputMultipleChoice
                    content={puzzleContent}
                    solution={puzzleSolution}
                    hint1={puzzleHint1}
                    checkSolution={checkSolution}
                  />
                )}
                {puzzleType === "QR" && (
                  <PuzzleInputQr
                    solution={puzzleSolution}
                    hint1={puzzleHint1}
                    checkSolution={checkSolution}
                    cameraActive={expandedPuzzle === puzzleSlug}
                  />
                )}
                {puzzleType !== "MultipleChoice" && puzzleType !== "QR" && (
                  <PuzzleInputText
                    solution={puzzleSolution}
                    hint1={puzzleHint1}
                    checkSolution={checkSolution}
                  />
                )}
              </>
            )}
            {
              puzzleState === 'finished' && puzzleSovedText && (
                <>
                <div dangerouslySetInnerHTML={{ __html: puzzleSovedText }} />
                <FindNextPuzzleButton 
                contentState={contentState}
                  expandedPuzzle={expandedPuzzle}
                  quests={quests}
                  setExpandedPuzzle={setExpandedPuzzle}
                  setExpandedQuest={setExpandedQuest}
                />
            </>
              )
            }
          </div>
        </Card.Body>
      )}
    </Card>

    <Modal 
        show={showImageModal} 
        onHide={() => setShowImageModal(false)} 
        size="xl" 
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Image</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <Image 
            src={puzzleIllustration} 
            style={{
              width: '100%',
              height: '80vh',
              objectFit: 'contain'
            }}
          />
        </Modal.Body>
      </Modal>
  </>
  );
}

export default Puzzle;
