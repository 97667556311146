import React, { useEffect, useRef, useState } from 'react';
import QrScanner from 'qr-scanner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode, faKeyboard } from '@fortawesome/free-solid-svg-icons';
import { Button, Alert } from 'react-bootstrap';
import '../../styles/QrStyles.css'; 

function PuzzleInputQr({ puzzle, solution, hint1, checkSolution }) {
  const QrFrame = '/assets/illustrations/qr-frame.svg';
  const scanner = useRef(null);
  const videoEl = useRef(null);
  const qrBoxEl = useRef(null);
  const [qrVisible, setQrVisible] = useState(true);
  const [scannedResult, setScannedResult] = useState(null);
  const [cameraError, setCameraError] = useState('');
  const [manualInput, setManualInput] = useState('');

  useEffect(() => {
    const checkCameras = async () => {
      try {
        const cameras = await QrScanner.listCameras(true);
        if (cameras.length > 0) {
          initiateScanner(cameras[0].id);
        } else {
          setCameraError('Keine Kamera verfügbar.');
        }
      } catch (error) {
        setCameraError('Zugriff auf die Kamera nicht möglich. Bitte Kameraberechtigungen überprüfen.');
      }
    };

    if (qrVisible) {
      checkCameras();
    } else {
      stopScanner();
    }

    return () => {
      stopScanner();
    };
  }, [qrVisible]);

  const stopScanner = () => {
    if (scanner.current) {
      scanner.current.stop();
      scanner.current.destroy();
      scanner.current = null;
      setCameraError('');
    }
  };

  const initiateScanner = (cameraId) => {
    if (videoEl.current && qrVisible) {
      scanner.current = new QrScanner(videoEl.current, onScanSuccess, {
        onDecodeError: onScanFail,
        preferredCamera: "environment",
        highlightScanRegion: true,
        highlightCodeOutline: true,
        overlay: qrBoxEl.current || undefined,
      });

      scanner.current.start().catch((error) => {
        console.error('Fehler beim Starten des Scanners:', error);
        setCameraError('Fehler beim Starten des Scanners. Stellen Sie sicher, dass eine Kamera verfügbar und berechtigt ist.');
      });
    }
  };

  const onScanSuccess = (result) => {
    const matchedSolutionIndex = validateResult(result.data);
    if (matchedSolutionIndex !== -1) {
      setScannedResult({ type: 'success', message: "QR Code erkannt" });
      checkSolution(matchedSolutionIndex);
      stopScanner();
      setQrVisible(false);
    } else {
      setScannedResult({ type: 'danger', message: "QR Code nicht lesbar" });
    }
  };

  const onScanFail = (error) => {
    //console.error("Scanning failed:", error);
  };

  useEffect(() => {
    document.body.style.overflow = qrVisible ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [qrVisible]);

  const validateResult = (data) => {
    return solution.findIndex(
      solutionGroup => solutionGroup.some(
        sol => sol.trim().toLowerCase() === data.trim().toLowerCase()
      )
    );
  };

  const toggleMode = () => {
    setQrVisible(!qrVisible);
    stopScanner();
    setScannedResult(null);
    setManualInput('');
  };

  const handleManualSubmit = () => {
    const matchedSolutionIndex = validateResult(manualInput);
    if (matchedSolutionIndex !== -1) {
      setScannedResult({ type: 'success', message: "Manuelle Eingabe korrekt" });
      checkSolution(matchedSolutionIndex);
    } else {
      setScannedResult({ type: 'danger', message: hint1 || "Manuelle Eingabe falsch" });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleManualSubmit();
    }
  };

  return (
    <div>
      <button onClick={toggleMode} className="qr-toggle-button">
        <FontAwesomeIcon icon={qrVisible ? faKeyboard : faQrcode} size="lg" />
      </button>

      {qrVisible ? (
        <>
          {cameraError ? (
            <Alert variant="danger">{cameraError}</Alert>
          ) : (
            <>
              <video className="qr-video" ref={videoEl}></video>
              <div ref={qrBoxEl} className="qr-box">
                <img src={QrFrame} alt="Qr Frame" width="256" height="256" className="qr-frame" />
              </div>
            </>
          )}
          {scannedResult && (
            <Alert variant={scannedResult.type} className="mt-3" style={{ position: "absolute", top: 70, left: 30 }}>
              {scannedResult.message}
            </Alert>
          )}
        </>
      ) : (
        <div className="manual-input-container">
          <input
            type="text"
            value={manualInput}
            onChange={(e) => setManualInput(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Enter QR code manually"
            className="manual-input"
          />
          <Button onClick={handleManualSubmit} className="manual-submit-button" variant="primary">
            Submit Answer
          </Button>
          {scannedResult && (
            <Alert variant={scannedResult.type} className="mt-3">
              {scannedResult.message}
            </Alert>
          )}
        </div>
      )}
    </div>
  );
}

export default PuzzleInputQr;