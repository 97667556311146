import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import QuestPuzzles from './detailsComponets/QuestPuzzles';
import QuestMetas from './detailsComponets/QuestMetas';
import { transformData, getLanguageIdByCode } from './detailsComponets/DetailHelpers';
import { fetchData } from '../../utility/ApiRequests';
import useSortableData from '../../hooks/useSortableData';
import { useNotification } from '../../hooks/useNotifications';
import EditableField from '../../utility/EditableField';
import { handleSaveChanges as handleSaveChangesUtil, handleDeleteEntry, handleNewEntry, deleteEntry, updateDb } from '../../utility/dataHandling';


const QuestDetails = () => {
    const { questId } = useParams();
    const route = '/api/quests/' + questId;
    const contextPlural = 'Quest Details';

    const [contextData, setContextData] = useState([]);
    const [metaCategories, setMetaCategories] = useState([]);
    const [categoryNames, setCategoryNames] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [questsData, setQuestsData] = useState([]);
    const [questContentData, setQuestsContentData] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState('de_DE');
    const [puzzles, setPuzzles] = useState([]);
    const [puzzleContents, setPuzzleContents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [editActive, setEditActive] = useState(false);
    const [formattedContents, setFormattedContents] = useState([]);
    const { showNotification } = useNotification();
    const token = localStorage.getItem('token');
    const { items: sortedContextData, requestSort, sortConfig } = useSortableData(formattedContents);
    const handleSaveChanges = handleSaveChangesUtil(setFormattedContents, showNotification);
    const [puzzlesOrder, setPuzzlesOrder] = useState(puzzles.map((puzzle, index) => ({ ...puzzle, order: index + 1 })));

    useEffect(() => {
        const initFetch = async () => {
            try {
                const data = await fetchData(route, token);
                setContextData(data);
                setMetaCategories(data.metaCategories);
                setQuestsData(data.quests);
                setQuestsContentData(data.questContents);
                setLanguages(data.languages);
                setCategoryNames(data.metaNames);
                setPuzzles(data.puzzles);
                setPuzzleContents(data.puzzleContents);
                setIsLoading(false);
                //console.log(data);
            } catch (error) {
                console.error('Fehler beim Laden der ' + contextPlural + ':', error);
                showNotification('Fehler beim Laden der ' + contextPlural + '.', 'danger');
                setIsLoading(false);
            }
        };
        initFetch();
    }, []);

    useEffect(() => {
        if (questContentData) {
            setFormattedContents(transformData(languages, categoryNames, metaCategories, questsData, questContentData));
        }
    }, [languages, questContentData, questsData]);


    if (isLoading) {
        return <div><div className="spinner"></div> Lädt... </div>;
    }

    if (contextData.length === 0) {
        return <div>kein Quest gefunden</div>;
    }

    return (
        <div>
            <h2>{contextPlural}</h2>
            <div className="content-right">
                <Button onClick={() => setEditActive(!editActive)}>
                    Bearbeitung {editActive ? 'deaktivieren' : 'aktivieren'}
                </Button>
            </div>
            <Form.Group controlId="languageSelect">
                <Form.Label>Sprache auswählen</Form.Label>
                <Form.Control className="w-8" as="select" value={selectedLanguage} onChange={(e) => setSelectedLanguage(e.target.value)}>
                    {languages.filter(lang => lang.language_code !== 'null').map(lang => (
                        <option key={lang.id} value={lang.language_code}>
                            {lang.language_name}
                        </option>
                    ))}
                </Form.Control>
            </Form.Group>
            <div>Slug:
            <EditableField
                value={questsData.slug}
                onSave={async (newValue) => {
                    const val = await updateDb('story_quests', questsData.storyQuest_id, 'slug', newValue);
                    if (val) {
                        setQuestsData(prevData => ({
                            ...prevData,
                            slug: newValue
                        }));
                        showNotification('Slug erfolgreich geändert', 'success');
                    } else {
                        showNotification('Fehler beim ändern - ist die Slug für dieses Quest einzigartig?', 'danger');
                    }
                }}
                fieldType="text"
            />
 
            </div>
            <QuestMetas
                sortedContextData={sortedContextData}
                requestSort={requestSort}
                sortConfig={sortConfig}
                metaCategories={metaCategories}
                selectedLanguage={selectedLanguage}
                languages={languages}
                setFormattedContents={setFormattedContents}
                handleSaveChanges={handleSaveChanges}
                questContentData={questContentData}
                setQuestsContentData={setQuestsContentData}
                editActive={editActive}
                showNotification={showNotification}
                getLanguageIdByCode={getLanguageIdByCode}
            />

            <QuestPuzzles
                puzzlesOrder={puzzlesOrder}
                setPuzzlesOrder={setPuzzlesOrder}
                puzzles={puzzles}
                setPuzzles={setPuzzles}
                puzzleContents={puzzleContents}
                setPuzzleContents={setPuzzleContents}
                languages={languages}
                questId={questId}
                showNotification={showNotification}
                handleSaveChanges={handleSaveChanges}
                selectedLanguage={selectedLanguage}
            />
        </div>
    );
};

export default QuestDetails;
