import React, { useMemo } from 'react';
import { ListGroup, Badge } from 'react-bootstrap';

function QuestListElement({ quest, state, contentState, language, setExpandedQuest, handleCloseModal }) {
  const questTitle = quest.metadata.questMeta_title[language];
  const questSlug = quest.slug;

  const isQuestActive = useMemo(() => {
    // Überprüfe, ob das Quest aktive Puzzles hat
    const hasActivePuzzles = quest.puzzles.some(puzzle => 
      contentState.some(item => 
        item.type === 'puzzle' && 
        item.slug === puzzle.slug && 
        item.state === 'active'
      )
    );

    return hasActivePuzzles;
  }, [quest, contentState]);

  const badgeVariant = isQuestActive ? 'success' : 'secondary';
  const badgeText = isQuestActive ? 'Active' : ' - ';

  const handleClick = () => {
    setExpandedQuest(questSlug);
    handleCloseModal();
  };

  return (
    <ListGroup.Item className="d-flex justify-content-between align-items-center">
      <span onClick={handleClick}>{questTitle}</span>
      <Badge bg={badgeVariant}>
        {badgeText}
      </Badge>
    </ListGroup.Item>
  );
}

export default QuestListElement;