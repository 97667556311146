// src/components/StoryCategories.js
import React from 'react';
import { Form, Badge } from 'react-bootstrap';
import { getEntityNames, getUnassignedEntities, handleAddEntity, handleRemoveEntity } from './StoryDetailHelpers';

const StoryCategories = ({ storyCategories, setStoryCategories, categories, storyId, showNotification }) => {

    const getCategoryNames = () => getEntityNames(storyCategories, categories, 'category_id');
    const getUnassignedCategories = () => getUnassignedEntities(storyCategories, categories, 'category_id');

    const handleAddCategory = (event) => handleAddEntity(event, 'categories', storyCategories, setStoryCategories, 'Kategorie', 'category_id', storyId, showNotification);
    const handleRemoveCategory = (categoryId) => handleRemoveEntity(categoryId, storyCategories, setStoryCategories, 'categories', 'Kategorie', 'category_id', storyId, showNotification);

    return (
        <div>
            <Form.Group controlId="unassignedCategories">
                <Form.Label><h3>Kategorie hinzufügen</h3></Form.Label>
                <Form.Control className="w-12" as="select" onChange={handleAddCategory}>
                    <option value="">Kategorie auswählen...</option>
                    {getUnassignedCategories().map(category => (
                        <option key={category.id} value={category.id}>
                            {category.name}
                        </option>
                    ))}
                </Form.Control>
            </Form.Group>

            {storyCategories.length > 0 ? (
                <div>
                    <span>Zugeordnete {storyCategories.length === 1 ? 'Kategorie' : 'Kategorien'}:</span>
                    {getCategoryNames().map(category => (
                        <Badge key={category.id} pill variant="primary" className="m-1 pa-05">
                            {category.name}
                            <span
                                style={{ cursor: 'pointer', marginLeft: '0.5rem', color: 'red' }}
                                onClick={() => handleRemoveCategory(category.id)}
                            >
                                &times;
                            </span>
                        </Badge>
                    ))}
                </div>
            ) : (
                <p>Keine Kategorie zugeordnet</p>
            )}
        </div>
    );
};

export default StoryCategories;
