import React, { useRef, useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { useParams } from 'react-router-dom';
import PlayerDyn from '../components/PlayerDyn';
import Playlist from '../components/Playlist';
import Radio from '../components/Radio';
import AudioLab from '../components/AudioLab';
import MapPopup from '../components/map/MapPopup';
import Quests from '../components/quests/Quests';
import QrReader from '../components/QrReader';
import Password from '../components/Password';
import MediaSession from '../components/MediaSession';
import NavigationTabs from '../components/NavigationTabs';
import { fetchData } from '../components/utility/ApiRequests';
import { parseCustomStyles , generateCustomStyleTag } from '../components/utility/ParseStyles';
import { WalkDataContext } from '../components/hooks/walkDataContext';
import { Container } from 'react-bootstrap';
import StoryDetails from '../components/StoryDetails';
import TonePlayer from '../components/audioControllers/tonePlayer/Tone';

const AudioPlayer = () => {
  const { trackSlug, walkSlug } = useParams();
  const {
    setStoryData,
    language,
    storyMetas,
    tracks,
    currentTrack, setCurrentTrack,
    playerSize,
    activeTab, setActiveTab,
    isPlaying, setIsPlaying,
    handleTabChange,
    password, setPassword,
    isPasswordValid, 
    validatePassword,
    validTrackSlugs,
    passwordAttempted, 
  } = useContext(WalkDataContext);

  const [timeProgress, setTimeProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(null);
  const [hasInteracted, setHasInteracted] = useState(false);
  const progressBarRef = useRef();
  const [customStyles, setCustomStyles] = useState(null);

  useEffect(() => {
    const initFetch = async () => {
      try {
        const data = await fetchData('/api/getStory/' + walkSlug);
        setStoryData(data);

        //console.log(data);

        if (data.story.metadata?.storyMeta_customstyling?.[1]) {
          setCustomStyles(data.story.metadata.storyMeta_customstyling[1]);
        }

        if (data.tracks && data.tracks.length > 0) {
          // Find the initial track without password protection
          let initialTrack = data.tracks.find(track => !track.metadata?.trackMeta_password);
          if (trackSlug) {
            const foundTrack = data.tracks.find(track => track.slug.toString() === trackSlug.toString());
            if (foundTrack) {
              initialTrack = foundTrack;
              setActiveTab('home');
            }
          }
  
          setCurrentTrack(initialTrack);
        }
      } catch (error) {
        console.error('Fehler beim Laden des Walks: ', error);
      }
    };
  
    if (walkSlug) {
      initFetch();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTrack = useCallback((slug) => {
    const track = tracks.find(track => track.slug === slug);
    if (track) {
      setCurrentTrack(track);
      if (storyMetas && storyMetas.storyMeta_type && storyMetas.storyMeta_type['1'] !== 'adventure'){ // etwas 'hacky' Metode um den normalen ausioplayer im adventure mode zu umgehen
        if (track.metadata?.trackMeta_audiotrack?.[language] ) {
          audioRef.current.src = track.metadata.trackMeta_audiotrack[language];
          audioRef.current.load();
          audioRef.current.play().then(() => {
            setIsPlaying(true);
          }).catch(e => {
            console.error('Error playing the track:', e);
            setIsPlaying(false);
          });
        } else {
          console.error('Audio source is undefined');
        }
      } 
    }else {
        setActiveTab('info');   // bei falsch gescanntem qu code
        console.error('Track not found for slug:', slug);
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tracks, language, setCurrentTrack, setIsPlaying, audioRef]);



  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };


  useEffect(() => {  // wichtig um den Fehler für Autoplay zu verhindern
    const handleUserInteraction = () => setHasInteracted(true);
    // Füge EventListener für verschiedene Benutzerinteraktionen hinzu
    window.addEventListener('click', handleUserInteraction);
    window.addEventListener('keydown', handleUserInteraction);
    window.addEventListener('touchstart', handleUserInteraction); // Für Touch-Geräte
    return () => {
      // Entferne EventListener bei der Demontage der Komponente
      window.removeEventListener('click', handleUserInteraction);
      window.removeEventListener('keydown', handleUserInteraction);
      window.removeEventListener('touchstart', handleUserInteraction);
    };
  }, []);
  
  const onLoadedMetadata = () => {
    const seconds = audioRef.current.duration;
    setDuration(seconds);

    if (hasInteracted) {
      audioRef.current.play().catch(e => console.error("Error playing the track:", e));
    } else {

    }
};


  const resetPlayer = useCallback(() => {
    setIsPlaying(false);
    setTimeProgress(0);
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
    }
  }, [setTimeProgress, setIsPlaying]);

  const memoizedPlayerDyn = useMemo(() => (
    <PlayerDyn
      size={playerSize}
      currentTrack={currentTrack}
      metas={storyMetas}
      language={language}
      audioRef={audioRef}
      progressBarRef={progressBarRef}
      duration={duration}
      setTimeProgress={setTimeProgress}
      isPlaying={isPlaying}
      togglePlayPause={togglePlayPause}
      timeProgress={timeProgress}
      resetPlayer={resetPlayer}
      handleTrack={handleTrack}
      setActiveTab={setActiveTab}
    />
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [playerSize, currentTrack, storyMetas, language, duration, togglePlayPause, isPlaying, timeProgress, resetPlayer, handleTrack]);

  const [playlistTracks, radioTracks] = useMemo(() => {
    if (!tracks) return [[], []];
  
    const playlist = [];
    const radio = [];
  
    tracks.forEach(track => {
      if (track.metadata?.trackMeta_radio) {
        radio.push(track);
      } else if (
        !storyMetas?.storyMeta_trackpasswords ||
        !track.metadata?.trackMeta_password ||
        validTrackSlugs.includes(track.slug)
      ) {
        playlist.push(track);
      }
    });
    return [playlist, radio];
  }, [tracks, validTrackSlugs, storyMetas]);

  


  return (
    <>

      {generateCustomStyleTag(customStyles)}
      <Container className='ml-125'>
        {!storyMetas || !tracks ? (
          <div>Loading...</div>
        ) : (
          <>
          
          <MediaSession/>
          

          {storyMetas && storyMetas.storyMeta_type && storyMetas.storyMeta_type['1'] === 'adventure' &&
            <TonePlayer/>
          }

          {(!storyMetas || !storyMetas.storyMeta_type || storyMetas.storyMeta_type['1'] !== 'adventure')  &&
            <audio 
              ref={audioRef} 
              crossOrigin="anonymous" 
              onLoadedMetadata={onLoadedMetadata} 
              onEnded={resetPlayer}
            >
              {currentTrack?.metadata?.trackMeta_audiotrack?.[language] && (
                <source src={currentTrack.metadata.trackMeta_audiotrack[language]} type="audio/mpeg" />
              )}
              Your browser does not support the audio element.
            </audio>
            }
            

            {(!storyMetas || !storyMetas.storyMeta_type || storyMetas.storyMeta_type['1'] !== 'adventure') && currentTrack && memoizedPlayerDyn}

            {playlistTracks && (
              <>
                {activeTab === 'info' && (
                  <>
                    <StoryDetails metas={storyMetas} language={language} />
                    <div className='miniplayer-spacer'></div>
                    <div className='navmenu-spacer'></div>
                  </>
                )}

                {activeTab === 'playlist' && (
                  <>
                    <Playlist
                      tracks={playlistTracks}
                      handleTrack={handleTrack}
                      currentTrackId={currentTrack ? currentTrack.slug : 0}
                      language={language}
                      metas={storyMetas}
                    />
                    <div className='miniplayer-spacer'></div>
                    <div className='navmenu-spacer'></div>
                  </>
                )}

            {storyMetas && storyMetas.storyMeta_type &&storyMetas.storyMeta_type && storyMetas.storyMeta_type['1'] === 'adventure' && activeTab === 'lab' && (
              <>
                <AudioLab
                  handleTrack={handleTrack}
                />
              </>
            )}

                {storyMetas && storyMetas.storyMeta_type &&storyMetas.storyMeta_type && storyMetas.storyMeta_type['1'] === 'adventure' && activeTab === 'radio' && (
                  <>
                    <Radio
                      tracks={radioTracks}
                      handleTrack={handleTrack}
                      language={language}
                      metas={storyMetas}
                    />
                    <div className='miniplayer-spacer'></div>
                    <div className='navmenu-spacer'></div>
                  </>
                )}

                {activeTab === 'password' && (
                  <>
                  <Password
                    password={password}
                    setPassword={setPassword}
                    validatePassword={validatePassword}
                    isPasswordValid={isPasswordValid}
                    passwordAttempted={passwordAttempted}
                  />
                    <div className='miniplayer-spacer'></div>
                    <div className='navmenu-spacer'></div>
                  </>
                )}

                { storyMetas && storyMetas.storyMeta_type && storyMetas.storyMeta_type['1'] === 'adventure' && activeTab === 'quest' && (
                  <>
                  <Quests
                    language={language}
                  />
                  </>
                )}

                {activeTab === 'map' && (
                  <>
                    <MapPopup
                      tracks={playlistTracks}
                      handleTrack={handleTrack}
                      currentTrackSlug={currentTrack ? currentTrack.slug : 0}
                      paths={null}
                      language={language}
                      metas={storyMetas}
                    />
                    <div className='miniplayer-spacer'></div>
                    <div className='navmenu-spacer'></div>
                  </>
                )}
              
              {(!storyMetas || !storyMetas.storyMeta_type || storyMetas.storyMeta_type['1'] !== 'adventure') && 
              activeTab === 'qr' && (
                  <>
                    <QrReader
                      tracks={tracks}
                      walkSlug={walkSlug}
                      handleTrack={handleTrack}
                      setActiveTab={setActiveTab}
                    />
                    <div className='miniplayer-spacer'></div>
                    <div className='navmenu-spacer'></div>
                  </>
                )}
              </>
            )}

            <NavigationTabs activeTab={activeTab} onTabChange={handleTabChange} />
          </>
        )}
      </Container>
    </>
  );
};

export default AudioPlayer;