import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { AuthContext }from '../components/auth/AuthContext';
import axios from 'axios';


function SignUp() {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useContext(AuthContext);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const userData = {
            //user_id: userId,  // Die zuvor generierte UUID
            username: username,
            password: password, // Achtung: Senden Sie Passwörter niemals im Klartext!
        };


    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/signup`, userData);
    
        localStorage.setItem('token', response.data.accessToken);
        localStorage.setItem('userId', response.data.userId);
        const decodedToken = jwtDecode(response.data.accessToken);
        const expiryTime = decodedToken.exp;
        login({ username: username }, expiryTime);
    
        console.log('Erfolgreich regestriert');
        navigate('/'); // Zur Startseite umleiten
    } catch (error) {
        throw new Error(`HTTP error! status: ${error.response ? error.response.status : 'unknown'}`);
    }

    };

    return (
        <form onSubmit={handleSubmit}>
            <h2>Registrieren</h2>
            <input
                name="username"
                autoComplete="given-name"
                type="text"
                placeholder="Benutzername"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            />
            <input
                name="password"
                type="password"
                placeholder="Passwort"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <button type="submit">Registrieren</button>
        </form>
    );
}

export default SignUp;