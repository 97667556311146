import React, { useState, useContext } from 'react';
//import { AuthContext } from '../auth/AuthContext';
import { AuthContext }from '../components/auth/AuthContext';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Card, Button } from 'react-bootstrap';
import { useNotification } from '../components/hooks/useNotifications';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useContext(AuthContext);
    const navigate = useNavigate();
    const { showNotification } = useNotification();

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/login`, { username, password });
    
            localStorage.setItem('token', response.data.accessToken);
            const decodedToken = jwtDecode(response.data.accessToken);
            const expiryTime = decodedToken.exp;
            login({ username: username, userid : decodedToken.userId, userrole: decodedToken.role}, expiryTime); // Aktualisieren des AuthContext-Zustands
            
            //console.log('Erfolgreich eingeloggt');
            navigate('/'); // Zur Startseite umleiten
    
            // Weitere Aktionen nach dem Login...
        } catch (error) {
            showNotification('Einloggen nicht möglich.', 'danger');
            setPassword('');
            setUsername('');
            console.error('Fehler beim Login:', error);
        }
    };

    return (
        <div className='main-container'>
        <form onSubmit={handleSubmit}>
            <h2>Login</h2>
            <input
            className="mt1"
                name="username"
                autoComplete="given-name"
                type="text"
                placeholder="Benutzername"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            /> <br/>
            <input
            className="mt1"
                name="password"
                type="password"
                placeholder="Passwort"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            /><br/>
            <Button className="mt1" type="submit">Einloggen</Button>
        </form>
        </div>
    );
}

export default Login;